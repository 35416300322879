<template>
    <div class="table-responsive" style="height: 650px; resize: both;" ref="scrollDiv" @scroll="handleTableScroll">
        <sortable-table2 ref="sortable"
            v-model="items"
            :default-sort-key="sort.key"
            :default-sort-asc="sort.asc"
            :session-name="sessionName"
        >
            <template #default="{setIcon, sort}">
                <table class="table table-borderless table-sm text-nowrap" style="table-layout: fixed">
                    <thead class="sticky-top bg-body">
                        <tr>
                            <th class="text-center" style="width: 20px">{{ dg.SequentialNumber }}</th>
                            <th class="text-center" style="width: 85px; cursor:pointer" @click="sort('EST_NO')">{{ dg.SalTEstimate.EST_NO }}/{{ dg.ENO }}<font-awesome-icon :icon="setIcon('EST_NO')" /></th>
                            <th class="text-center" style="width: 70px; cursor:pointer" @click="sort('EST_DATE')">{{ dg.SalTEstimate.EST_DATE }}<font-awesome-icon :icon="setIcon('EST_DATE')" /></th>
                            <th class="text-center" style="width: 70px">{{ dg.SalTEstimate.STATUS }}</th>
                            <th class="text-center" style="width: 50px; cursor:pointer" @click="sort('KOKYK_ID')">{{ dg.KOKYK_ID }}<font-awesome-icon :icon="setIcon('KOKYK_ID')" /></th>
                            <th class="text-center" style="width: 100px">{{ dg.SalTEstimate.CLNIC_NM }}</th>
                            <th class="text-center" style="width: 150px">{{ dg.SalTEstimate.E_NAME }}</th>
                            <th class="text-center" style="width: 30px">{{ dg.count }}</th>
                            <th class="text-center" style="width: 60px">{{ dg.SalTEstimate.PRICE }}</th>
                            <th class="text-center" style="width: 50px">{{ dg.SalTEstimate.TAX }}</th>
                            <th class="text-center" style="width: 75px">{{ dg.SalTEstimate.TPRICE }}</th>
                            <th class="text-center" style="width: 140px">{{ dg.SalTEstimate.EST_NOTE1 }}</th>
                            <th class="text-center" style="width: 140px">{{ dg.SalTEstimate.EST_NOTE2 }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="(item, index) in items" :key="index">
                            <tr style="border-top: 1px solid var(--bs-border-color)">
                                <td>{{ index + 1 }}</td>
                                <td><a href="#" @click.prevent.stop="edit(item.ID)">{{ item.EST_NO }}-{{ item.ENO }}</a></td>
                                <td v-if="item.PDF"><a :href="`${documentRoot}/storage/estimate/${item.PDF}`" :download="`${item.PDF}`">{{ item.EST_DATE?.replaceAll('-', '/') }}</a></td>
                                <td v-else>{{ item.EST_DATE?.replaceAll('-', '/') }}</td>
                                <td>{{ estimateStatusList[item.STATUS] }}</td>
                                <td>{{ item.KOKYK_ID }}</td>
                                <td>{{ strLimit(item.CLNIC_NM, 20) }}</td>
                                <td>{{ strLimit(item.E_NAME, 20) }}</td>
                                <td class="text-end">{{ item.salTEstimateDtls?.length }}</td>
                                <td class="text-end">{{ parseInt(item.PRICE).toLocaleString() }}</td>
                                <td class="text-end">{{ parseInt(item.TAX).toLocaleString() }}</td>
                                <td class="text-end">{{ parseInt(item.TPRICE).toLocaleString() }}</td>
                                <td>{{ strLimit(item.EST_NOTE1, 20) }}</td>
                                <td>{{ strLimit(item.EST_NOTE2, 20) }}</td>
                            </tr>
                            <template v-if="showDetail">
                                <tr v-for="(dtl, index2) in item.salTEstimateDtls" :key="index2" style="background: #e2e3e5">
                                    <td colspan="5" style="background: var(--bs-body-bg)"></td>
                                    <td class="text-end" style="width: 30px">#{{ dtl.RNO }}</td>
                                    <td colspan="2" style="width: 260px">{{ dtl.PRODUCT_CODE }}/{{ strLimit(productList[dtl?.PRODUCT_CODE], 20) }}</td>
                                    <td class="text-end" style="width: 70px">{{ parseInt(dtl.UPRICE).toLocaleString() }}</td>
                                    <td class="text-end" style="width: 68px">{{ parseInt(dtl.CNT).toLocaleString() }}</td>
                                    <td class="text-end" style="width: 103px">{{ parseInt(dtl.PRICE).toLocaleString() }}</td>
                                    <td style="width: 190px">{{ strLimit(dtl.EST_NOTE1, 15) }}</td>
                                    <td style="width: 190px">{{ strLimit(dtl.EST_NOTE1, 15) }}</td>
                                </tr>
                            </template>
                        </template>
                    </tbody>
                </table>
            </template>
        </sortable-table2>
    </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import Mixin from '../mixins/mixin'
import SortableTable2 from '../tables/SortableTable2.vue';

export default {
    name: 'EstimateIndexTable',
    mixins: [Mixin],
    emits: ['handleTableScroll', 'update:model-value'],
    components: {
        FontAwesomeIcon,
        SortableTable2,
    },
    props: {
        modelValue: Array,
        dg: Object,
        documentRoot: String,
        estimateStatusList: Object,
        productList: Object,
        sort: Object,
        sessionName: String,
        showDetail: Boolean,
    },
    computed: {
        items: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:model-value', value)
            }
        },       
    },
    methods: {
        init(){
            this.$refs.sortable.initialSort();
        },
        edit(primaryKey) {
            location.href = `${this.documentRoot}/estimate/${primaryKey}/edit`;
        },
        scroll(height) {
            this.$refs.scrollDiv.scrollTop = height;
        },
        handleTableScroll(e) {
            this.$emit('handleTableScroll', e);
        }
    },
}
</script>
