<template>
    <form ref="form">
        <div class="card mb-1">
            <div class="card-body py-1">
                <div class="row mb-2">
                    <!-- 代理店請求番号 -->
                    <div class="col-2">
                        <label
                            for="AGENT_NO"
                            class="form-label form-label-sm"
                            >{{ dg.SalTAgent.AGENT_NO }}</label
                        >
                        <input
                            readonly
                            name="AGENT_NO"
                            type="text"
                            class="form-control form-control-sm"
                            id="AGENT_NO"
                            :value="form.AGENT_NO"
                        />
                        <error-message :error="errors.AGENT_NO"></error-message>
                    </div>
                    <!-- 代理店名 -->
                    <div class="col-2">
                        <label
                            for="RECE_CODE"
                            class="form-label form-label-sm"
                            >{{ dg.SalTAgent.agent }}</label
                        >
                        <input
                            readonly
                            name="RECE_CODE"
                            type="text"
                            class="form-control form-control-sm"
                            id="RECE_CODE"
                            :value="agentList[form.RECE_CODE] ?? ''"
                        />
                        <error-message
                            :error="errors.RECE_CODE"
                        ></error-message>
                    </div>
                    <!-- 請求日 -->
                    <div class="col-2">
                        <label for="INV_DATE" class="form-label">{{
                            dg.SalTAgent.INV_DATE
                        }}</label>
                        <input
                            type="date"
                            name="INV_DATE"
                            class="form-control form-control-sm"
                            id="INV_DATE"
                            v-model="form.INV_DATE"
                        />
                        <error-message :error="errors.INV_DATE"></error-message>
                    </div>
                    <!-- 引落日 -->
                    <div class="col-2">
                        <label for="LMT_DATE" class="form-label">{{
                            dg.SalTAgent.LMT_DATE
                        }}</label>
                        <input
                            type="date"
                            name="LMT_DATE"
                            class="form-control form-control-sm"
                            id="LMT_DATE"
                            v-model="form.LMT_DATE"
                        />
                        <error-message :error="errors.LMT_DATE"></error-message>
                    </div>
                    <!-- 入金日 -->
                    <div class="col-2">
                        <label for="PAY_DATE" class="form-label">{{
                            dg.SalTAgent.PAY_DATE
                        }}</label>
                        <input
                            type="date"
                            name="PAY_DATE"
                            class="form-control form-control-sm"
                            id="PAY_DATE"
                            v-model="form.PAY_DATE"
                        />
                        <error-message :error="errors.PAY_DATE"></error-message>
                    </div>
                    <!-- 状態 -->
                    <div class="col-2">
                        <label for="STATUS" class="form-label">{{
                            dg.SalTAgent.STATUS
                        }}</label>
                        <select
                            class="form-select form-select-sm"
                            id="STATUS"
                            v-model="form.STATUS"
                            aria-label=".form-select-sm example"
                        >
                            <option
                                v-for="(
                                    invoiceStatus, key
                                ) in invoiceStatusList"
                                :key="key"
                                :value="key"
                            >
                                {{ invoiceStatus }}
                            </option>
                        </select>
                        <error-message :error="errors.STATUS"></error-message>
                    </div>
                </div>
                <div class="row mb-2">
                    <!-- 備考 -->
                    <div class="col-6">
                        <label for="NOTE" class="form-label">{{
                            dg.SalTAgent.NOTE
                        }}</label>
                        <textarea
                            name="NOTE"
                            class="form-control form-control-sm"
                            id="NOTE"
                            v-model="form.NOTE"
                            rows="5"
                        ></textarea>
                    </div>
                    <div class="col-6">
                        <!-- PDF -->
                        <div class="row">
                            <label for="pdf" class="form-label">{{
                                dg.SalTAgent.PDF
                            }}</label>
                            <ul v-if="form.PDF">
                                <li>
                                    <a
                                        :href="`${documentRoot}/storage/agent/${form.PDF}`"
                                        :download="`${form.PDF}`"
                                        >{{ form.PDF }}</a
                                    >
                                </li>
                            </ul>
                        </div>
                        <!-- ファイル -->
                        <div v-if="edit" class="row">
                            <file-upload
                                v-model="form"
                                v-model:filesModelValue="files"
                                :dg="dg"
                                :max-file-count="5"
                                :slice-num="31"
                                :document-root="documentRoot"
                            ></file-upload>
                        </div>
                        <div v-if="!edit" class="row">
                            <div class="col-12 mt-4">
                                {{ dg.SalTAgent.addFileAfterRegistered }}
                            </div>
                        </div>
                    </div>
                </div>
                <agent-input-table
                    v-model="form.salTInvoices"
                    :dg="dg"
                    :document-root="documentRoot"
                    :edit="edit"
                    :invoice-status-list="invoiceStatusList"
                    :digit-sales-dtls="digitSalesDtls"
                    :agent-inv-dtls="agentInvDtls"
                    @print="print"
                ></agent-input-table>
            </div>
        </div>

        <div class="gap-2 d-md-flex justify-content-md-end">
            <button
                type="button"
                class="btn btn-primary"
                v-if="edit"
                @click="csv"
            >
                {{ dg.csv }}
            </button>
            <button
                type="button"
                class="btn btn-danger"
                v-if="edit"
                @click="destroy"
            >
                {{ dg.Delete }}
            </button>
            <button
                type="button"
                class="btn btn-primary"
                v-if="edit"
                @click="handlePrintClick"
            >
                {{ dg.print }}
            </button>
            <!-- 中止ボタン -->
            <cancel-button
                :confirm-flag="changed"
                :confirm-message="dg.cancelMessage"
                :url="`${this.documentRoot}/agent`"
                >{{ dg.cancel }}</cancel-button
            >
            <button
                type="button"
                class="btn btn-primary"
                v-if="!edit"
                @click="create"
            >
                {{ dg.register }}
            </button>
            <button
                type="button"
                class="btn btn-primary"
                v-if="edit"
                @click="update"
            >
                {{ dg.update }}
            </button>
            <button
                type="button"
                class="btn btn-primary"
                v-if="edit"
                @click="license"
            >
                ライセンス発行
            </button>
        </div>
    </form>

    <yes-no-modal
        ref="yesNoModal"
        :message="dg.isStampRequired"
        @yes="print(true)"
        @no="print(false)"
    ></yes-no-modal>

    <yes-no-modal2 ref="yesNoModal2" :message="message" />
</template>

<script>
import moment from "moment";
import CancelButton from "../buttons/CancelButton.vue";
import FileUpload from "../inputs/FileUpload.vue";
import Mixin from "../mixins/mixin";
import YesNoModal from "../modals/YesNoModal.vue";
import YesNoModal2 from "../modals/YesNoModal2.vue";
import AgentInputTable from "./AgentInputTable.vue";

export default {
    name: "AgentInput",
    mixins: [Mixin],
    components: {
        AgentInputTable,
        CancelButton,
        YesNoModal,
        YesNoModal2,
        FileUpload,
    },
    props: {
        data: Object,
        dg: Object,
        documentRoot: String,
        invoiceStatusList: Object,
        agentList: Object,
        agents: Object,
        digitSalesDtls: Object,
        agentInvDtls: Object,
    },
    data() {
        return {
            form: {},
            files: [],
            id: "",
            errors: [],
            edit: false,
            changed: false,
            message: "",
        };
    },
    mounted() {
        const unwatch = this.$watch(
            "form",
            () => {
                this.changed = true;
                unwatch();
            },
            { deep: true }
        );
    },
    created() {
        this.form = this.data;
        this.edit = this.form.AGENT_NO ? true : false;
        this.form.INV_DATE = this.edit
            ? this.form.INV_DATE
            : moment(this.form.INV_YM).format("YYYY-MM-01");
        this.form.LMT_DATE = this.edit
            ? this.form.LMT_DATE
            : this.calcPayDate();
        this.form.DRAW_DATE = this.edit
            ? this.form.DRAW_DATE
            : moment(this.form.INV_YM).format("YYYY-MM-20");
    },
    computed: {
        agent() {
            if (this.form.RECE_CODE) {
                return this.agentList[this.form.RECE_CODE];
            } else {
                ("");
            }
        },
    },
    methods: {
        async license() {
            return new Promise(async (resolve, reject) => {
                try {
                    const licenseMonth = this.agents.find(
                        ({ RECE_CODE }) => RECE_CODE === this.form.RECE_CODE
                    )?.LICENSE_MONTH;
                    this.message = `${licenseMonth}月でライセンスを発行しますか？\n`;

                    const result = await this.$refs.yesNoModal2.action();

                    if (result) {
                        this.data.salTInvoices.forEach(
                            async ({
                                KOKYK_ID,
                                salTInvoiceDtls,
                                mKokykLic,
                            }) => {
                                const RakkuinCodes = [
                                    "1010",
                                    "1011",
                                    "1012",
                                    "1019",
                                ];
                                const rakkuin = salTInvoiceDtls.find(
                                    ({ PRODUCT_CODE }) =>
                                        RakkuinCodes.includes(PRODUCT_CODE)
                                );

                                // らっく院対象コードがなければ、リターン
                                if (!rakkuin) {
                                    return;
                                }

                                const yukoStrYmd = moment()
                                    .month(licenseMonth - 1)
                                    .format("YYYY-MM-DD");
                                const yukoEndYmd = moment(yukoStrYmd)
                                    .add(11, "M")
                                    .endOf("month")
                                    .format("YYYY-MM-DD");

                                const syzkNo = mKokykLic?.SYZK_NO;

                                if (!syzkNo) {
                                    return;
                                }

                                let planId = "";
                                if (rakkuin.PRODUCT_CODE === "1010") {
                                    planId = "L";
                                } else if (rakkuin.PRODUCT_CODE === "1011") {
                                    planId = "S";
                                } else {
                                    planId = "P";
                                }

                                // TODO: 本番運用保留
                                return;
                                const result = await axios.post(
                                    `${apiBaseUrl}/manage/v1/license/create`,
                                    {
                                        kokykId: KOKYK_ID,
                                        yukoStrYmd: yukoStrYmd,
                                        yukoEndYmd: yukoEndYmd,
                                        syzkNo: syzkNo,
                                        planId: planId,
                                    },
                                    {
                                        headers: {
                                            Authorization: `Bearer ${apiOuthToken}`,
                                        },
                                    }
                                );
                            }
                        );
                    }
                    return resolve();
                } catch (error) {
                    return reject();
                }
            });
        },
        async handlePrintClick() {
            try {
                // 変更チェック
                if (this.changed) {
                    // 変更されている場合
                    this.message = this.dg.changedPrintMessage;
                    const shouldSave = await this.$refs.yesNoModal2.action();

                    if (shouldSave) {
                        // 変更を保存する場合、更新処理
                        const response = await axios.put(
                            `/agent/${this.data.AGENT_NO}`,
                            this.form
                        );
                    } else {
                        // 変更を保存しない場合、処理キャンセル
                        throw new Error("保存キャンセル");
                    }
                }
                // ライセンス発行
                const licenseMonth = this.agents.find(
                    ({ RECE_CODE }) => RECE_CODE === this.form.RECE_CODE
                )?.LICENSE_MONTH;
                this.message = `${licenseMonth}月でライセンスを発行しますか？`;
                const shouldIssueLicenses =
                    await this.$refs.yesNoModal2.action();

                if (shouldIssueLicenses) {
                    this.data.salTInvoices.forEach(
                        async ({ KOKYK_ID, salTInvoiceDtls, mKokykLic }) => {
                            const RakkuinCodes = [
                                "1010",
                                "1011",
                                "1012",
                                "1019",
                            ];
                            const rakkuin = salTInvoiceDtls.find(
                                ({ PRODUCT_CODE }) =>
                                    RakkuinCodes.includes(PRODUCT_CODE)
                            );

                            // らっく院対象コードがなければ、リターン
                            if (!rakkuin) {
                                return;
                            }

                            const yukoStrYmd = moment()
                                .month(licenseMonth - 1)
                                .format("YYYY-MM-DD");
                            const yukoEndYmd = moment(yukoStrYmd)
                                .add(11, "M")
                                .endOf("month")
                                .format("YYYY-MM-DD");

                            const syzkNo = mKokykLic?.SYZK_NO;

                            if (!syzkNo) {
                                return;
                            }

                            let planId = "";
                            if (rakkuin.PRODUCT_CODE === "1010") {
                                planId = "L";
                            } else if (rakkuin.PRODUCT_CODE === "1011") {
                                planId = "S";
                            } else {
                                planId = "P";
                            }

                            // TODO: 本番運用保留
                            return;
                            await axios.post(
                                `${apiBaseUrl}/manage/v1/license/create`,
                                {
                                    kokykId: KOKYK_ID,
                                    yukoStrYmd: yukoStrYmd,
                                    yukoEndYmd: yukoEndYmd,
                                    syzkNo: syzkNo,
                                    planId: planId,
                                },
                                {
                                    headers: {
                                        Authorization: `Bearer ${apiOuthToken}`,
                                    },
                                }
                            );
                        }
                    );
                }
                // 請求書の社印の有無ダイアログ
                this.message = this.dg.isStampRequired;
                const isStamp = await this.$refs.yesNoModal2.action();
                const response = await axios.post(
                    `/agent/${this.form.AGENT_NO}/pdf`,
                    { is_stamp: isStamp }
                );
                location.href = response.data;
            } catch (error) {
                console.log(error);
                return;
            }
        },
        calcPayDate() {
            let payDate = moment(this.form.INV_YM).add(this.form.PAY_DAYM, "M");

            return this.form.PAY_DAYD !== 99
                ? payDate.add(this.form.PAY_DAYD - 1, "d").format("YYYY-MM-DD")
                : payDate.endOf("month").format("YYYY-MM-DD");
        },
        destroy() {
            if (confirm(this.dg.deleteConfirmMessage)) {
                axios.delete(`/agent/${this.data.AGENT_NO}`).then((res) => {
                    location.href = res.data;
                });
            }
        },
        create() {
            axios
                .post(`/agent`, this.form)
                .then((res) => {
                    location.href = res.data;
                })
                .catch((error) => {
                    if (error.response.status === 422) {
                        this.errors = error.response.data.errors;
                    } else if (error.response.status === 500) {
                        const status = error.response.status;
                        window.alert(this.dg.internal_error);
                    }
                });
        },
        update() {
            this.updateAxios()
                .then((res) => {
                    location.href = res.data;
                })
                .catch((error) => {
                    if (error.response.status === 422) {
                        this.errors = error.response.data.errors;
                    } else if (error.response.status === 500) {
                        const status = error.response.status;
                        window.alert(this.dg.internal_error);
                    }
                });
        },
        csv() {
            if (this.changed) {
                alert(this.dg.changedMessage);
                return;
            } else {
                axios
                    .get(`/agent/${this.data.AGENT_NO}/csv`, {
                        responseType: "blob",
                    })
                    .then((response) => {
                        const url = URL.createObjectURL(
                            new Blob([response.data])
                        );
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute(
                            "download",
                            `${this.data.AGENT_NO}-${this.data.INV_YM}.csv`
                        );
                        document.body.appendChild(link);
                        link.click();
                    });
            }
        },
        async print(isStamp) {
            if (this.changed) {
                if (confirm(this.dg.changedPrintMessage)) {
                    // はいの場合
                    this.updateAxios()
                        .then(() => this.license())
                        .then((res) => {
                            console.log(res);
                            return this.printAxios(isStamp);
                        })
                        .then((res) => {
                            location.href = res.data;
                        })
                        .catch((error) => {
                            if (error.response.status === 422) {
                                this.errors = error.response.data.errors;
                            } else if (error.response.status === 500) {
                                const status = error.response.status;
                                window.alert(this.dg.internal_error);
                            }
                        });
                }
            } else {
                const licenseMonth = this.agents.find(
                    ({ RECE_CODE }) => RECE_CODE === this.form.RECE_CODE
                )?.LICENSE_MONTH;
                this.message = `${licenseMonth}月でライセンスを発行しますか？\n`;

                const result = await this.$refs.yesNoModal2.action();

                if (result) {
                    this.data.salTInvoices.forEach(
                        async ({ KOKYK_ID, salTInvoiceDtls, mKokykLic }) => {
                            const RakkuinCodes = [
                                "1010",
                                "1011",
                                "1012",
                                "1019",
                            ];
                            const rakkuin = salTInvoiceDtls.find(
                                ({ PRODUCT_CODE }) =>
                                    RakkuinCodes.includes(PRODUCT_CODE)
                            );

                            // らっく院対象コードがなければ、リターン
                            if (!rakkuin) {
                                return;
                            }

                            const yukoStrYmd = moment()
                                .month(licenseMonth - 1)
                                .format("YYYY-MM-DD");
                            const yukoEndYmd = moment(yukoStrYmd)
                                .add(11, "M")
                                .endOf("month")
                                .format("YYYY-MM-DD");
                            const syzkNo = mKokykLic?.SYZK_NO;

                            if (!syzkNo) {
                                return;
                            }

                            let planId = "";
                            if (rakkuin.PRODUCT_CODE === "1010") {
                                planId = "L";
                            } else if (rakkuin.PRODUCT_CODE === "1011") {
                                planId = "S";
                            } else {
                                planId = "P";
                            }

                            // TODO: 本番運用保留
                            return;
                            const result = await axios.post(
                                `${apiBaseUrl}/manage/v1/license/create`,
                                {
                                    kokykId: KOKYK_ID,
                                    yukoStrYmd: yukoStrYmd,
                                    yukoEndYmd: yukoEndYmd,
                                    syzkNo: syzkNo,
                                    planId: planId,
                                },
                                {
                                    headers: {
                                        Authorization: `Bearer ${apiOuthToken}`,
                                    },
                                }
                            );
                        }
                    );

                    console.log(result);
                    return;
                }
                this.printAxios(isStamp).then(
                    (res) => (location.href = res.data)
                );
            }
        },
        getFormData() {
            const formData = new FormData(this.$refs.form);

            Object.keys(this.form).forEach((key) => {
                if (this.form[key] == null) return;
                formData.set(key, this.form[key]);
            });

            formData.set("FILE1", "");
            formData.set("FILE2", "");
            formData.set("FILE3", "");
            formData.set("FILE4", "");
            formData.set("FILE5", "");

            for (var i = 0; i < this.files.length; i++) {
                if (this.files[i].file) {
                    formData.set("FILE" + (i + 1), this.files[i].file);
                } else {
                    formData.set("FILE" + (i + 1), this.files[i].name);
                }
            }

            return formData;
        },
        updateAxios() {
            return axios.post(
                `/agent/${this.data.AGENT_NO}`,
                this.getFormData(),
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        "X-HTTP-Method-Override": "PUT",
                    },
                }
            );
        },
        printAxios(isStamp) {
            return axios.post(`/agent/${this.form.AGENT_NO}/pdf`, {
                is_stamp: isStamp,
            });
        },
    },
};
</script>
