<template>
    <div class="table-responsive" style="height: 660px; resize: both;" ref="scrollDiv" @scroll="handleTableScroll">
        <sortable-table2 ref="sortable"
            v-model="items"
            :default-sort-key="sort.key"
            :default-sort-asc="sort.asc"
            :session-name="sessionName"
        >
            <template #default="{setIcon, sort}">
                <table class="table table-hover table-sm text-nowrap">
                    <thead class="sticky-top bg-body">
                        <tr>
                            <th style="min-width: 2rem">{{ dg.SequentialNumber }}</th>
                            <th style="min-width: 7rem; cursor:pointer" @click="sort('AGENT_NO')">{{ dg.SalTAgent.AGENT_NO }}<font-awesome-icon :icon="setIcon('AGENT_NO')" /></th>
                            <th style="min-width: 6rem">{{ dg.SalTAgent.STATUS }}</th>
                            <th style="min-width: 6rem">{{ dg.SalTRece.NAME }}</th>
                            <th style="min-width: 6rem; cursor:pointer" @click="sort('INV_YM')">{{ dg.SalTAgent.INV_YM }}<font-awesome-icon :icon="setIcon('INV_YM')" /></th>
                            <th style="min-width: 6rem">{{ dg.SalTAgent.INV_DATE }}</th>
                            <th style="min-width: 6rem">{{ dg.SalTAgent.LMT_DATE }}</th>
                            <th style="min-width: 6rem">{{ dg.SalTAgent.PAY_DATE }}</th>
                            <th class="text-end" style="min-width: 4rem">{{ dg.SalTAgent.count }}</th>
                            <th class="text-end" style="min-width: 5rem">{{ dg.SalTAgent.price }}</th>
                            <th class="text-end" style="min-width: 5rem">{{ dg.SalTAgent.commission }}</th>
                            <th class="text-end" style="min-width: 5rem">{{ dg.SalTAgent.netPrice }}</th>
                            <th style="min-width: 10rem">{{ dg.SalTAgent.NOTE }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(items, index) in items" :key="index">
                            <td>{{ index + 1 }}</td>
                            <td><a href="#" @click.prevent.stop="edit(items.AGENT_NO)">{{ items.AGENT_NO }}</a></td>
                            <td>{{ invoiceStatusList[items.STATUS] }}</td>
                            <td>{{ items.RECE_NAME }}</td>
                            <td>{{ items.INV_YM?.replaceAll('-', '/') }}</td>
                            <td>{{ items.INV_DATE?.replaceAll('-', '/') }}</td>
                            <td>{{ items.LMT_DATE?.replaceAll('-', '/') }}</td>
                            <td>{{ items.PAY_DATE?.replaceAll('-', '/') }}</td>
                            <td class="text-end">{{ items.count ? parseInt(items.count) : 0 }}</td>
                            <td class="text-end">{{ items.tPrice ? parseInt(items.tPrice).toLocaleString() : 0 }}</td>
                            <td class="text-end">{{ items.commission ? parseInt(items.commission).toLocaleString() : 0 }}</td>
                            <td class="text-end">{{ (items.tPrice - items.commission).toLocaleString() }}</td>
                            <td>{{ strLimit(items.NOTE, 20) }}</td>
                        </tr>
                    </tbody>
                </table>
            </template>
        </sortable-table2>
    </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import Mixin from '../mixins/mixin'
import SortableTable2 from '../tables/SortableTable2.vue';

export default {
    name: 'AgentIndexTable',
    mixins: [Mixin],
    emits: ['update:model-value'],
    components: {
        FontAwesomeIcon,
        SortableTable2,
    },
    props: {
        modelValue: Array,
        documentRoot: String,
        dg: Object,
        invoiceStatusList: Object,
        sort: Object,
        sessionName: String,
    },
    computed: {
        items: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:model-value', value)
            }
        },
    },
    methods: {
        init(){
            this.$refs.sortable.initialSort();
        },
        edit(primaryKey) {
            location.href = `${this.documentRoot}/agent/${primaryKey}/edit`;
        },
        scroll(height) {
            this.$refs.scrollDiv.scrollTop = height;
        },
        handleTableScroll(e) {
            this.$emit('handleTableScroll', e);
        }
    },
}
</script>