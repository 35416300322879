<template>
    <div v-if="active">
        <div class="modal" style="display: block;" @click.self="close">
            <div class="modal-dialog modal-xl modal-dialog-scrollable">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">{{ dg.customer.search }}</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="close"></button>
                    </div>
                    <div class="modal-body">
                        <form ref="form">
                            <div class="row g-3">
                                <div class="col-1">
                                    <label for="KOKYK_ID" class="form-label">{{ dg.KOKYK_ID }}</label>
                                    <input type="text" name="KOKYK_ID" class="form-control form-control-sm" id="KOKYK_ID" v-model="form.KOKYK_ID">
                                    <error-message :error="errors.KOKYK_ID"></error-message>
                                </div>
                                <div class="col-2">
                                    <label for="DEL_FLG" class="form-label">{{ dg.MKokykLic.DEL_FLG }}</label>
                                    <select name="DEL_FLG" class="form-select form-select-sm" id="DEL_FLG" aria-label="Default select example" v-model="form.DEL_FLG">
                                        <option value="">{{ dg.pleaseSelect }}</option>
                                        <option v-for="(delFlag, index) in delFlag" :key="index" :value="index">{{ delFlag }}</option>
                                    </select>
                                    <error-message :error="errors.DEL_FLG"></error-message>
                                </div>
                                <div class="col-2">
                                    <label for="CLNIC_NM" class="form-label">{{ dg.MKokykLic.CLNIC_NM }}</label>
                                    <input type="text" name="CLNIC_NM" class="form-control form-control-sm" id="CLNIC_NM" v-model="form.CLNIC_NM">
                                    <error-message :error="errors.CLNIC_NM"></error-message>
                                </div>
                                <div class="col-2">
                                    <label for="SYZK_NO" class="form-label">{{ dg.MsSyzk.SYZK_NM }}</label>
                                    <select name="SYZK_NO" class="form-select form-select-sm" id="SYZK_NO" aria-label="Default select example" v-model="form.SYZK_NO">
                                        <option value="">{{ dg.pleaseSelect }}</option>
                                        <option v-for="(syzkNm, syzkNo) in syzkList" :key="syzkNo" :value="syzkNo">{{ syzkNm }}</option>
                                    </select>
                                    <error-message :error="errors.SYZK_NO"></error-message>
                                </div>
                                <div class="col-2">
                                    <label for="TEL" class="form-label">{{ dg.MKokykLic.TEL }}</label>
                                    <input type="tel" name="TEL" class="form-control form-control-sm" id="TEL" v-model="form.TEL">
                                    <error-message :error="errors.TEL"></error-message>
                                </div>
                                <div class="col-1" style="margin-top: 39px">
                                    <button type="button" class="btn btn-primary btn-sm" @click="getCustomers">検索</button>
                                </div>
                            </div>
                        </form>
                        <div class="table-responsive" style="height: 600px; resize: both;">
                            <table class="table table-sm table-hover text-nowrap">
                                <thead class="sticky-top bg-white">
                                    <tr>
                                        <th>#</th>
                                        <th style="min-width: 4rem"></th>
                                        <th style="min-width: 5rem">{{ dg.KOKYK_ID }}</th>
                                        <th style="min-width: 3rem">{{ dg.MKokykLic.DEL_FLG }}</th>
                                        <th style="min-width: 8rem">{{ dg.MKokykLic.CLNIC_NM }}</th>
                                        <th style="min-width: 6rem">{{ dg.MKokykLic.INCHO_NM }}</th>
                                        <th style="min-width: 5rem">{{ dg.MsSyzk.SYZK_NM }}</th>
                                        <th style="min-width: 5rem">{{ dg.MKokykLic.TEL }}</th>
                                        <th style="min-width: 5rem">{{ dg.MKokykLic.ZIP_CD }}</th>
                                        <th style="min-width: 10rem">{{ dg.MKokykLic.ADR }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(customer, index) in customers.data" :key="index">
                                        <td >{{ index + 1 }}</td>
                                        <td ><button type="button" class="btn btn-primary btn-sm" @click="selectKokyk(customer)">選択</button></td>
                                        <td>{{ customer.KOKYK_ID }}</td>
                                        <td>{{ delFlag[customer.DEL_FLG] }}</td>
                                        <td>{{ strLimit(customer.CLNIC_NM, 20) }}</td>
                                        <td>{{ customer.INCHO_NM }}</td>
                                        <td>{{ customer.msSyzk.SYZK_NM }}</td>
                                        <td>{{ customer.TEL }}</td>
                                        <td>{{ customer.ZIP_CD }}</td>
                                        <td>{{ customer.ADR }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="close">閉じる</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-backdrop show" ></div>
    </div>
</template>

<script>
import Mixin from '../mixins/mixin'

export default {
    name: 'ModalCustomer',
    mixins: [Mixin],
    props: {
        dg: Object,
        delFlag: Object,
        syzkList: Object,
    },
    emits: ['set-customer'],
    data() {
        return {
            active: false,
            customers: [],
            form: {
                KOKYK_ID: '',
                DEL_FLG: '',
                CLNIC_NM: '',
                SYZK_NO: '',
                TEL: '',
                page: '',
            },
            errors: {},
        }
    },
    created() {
        this.getCustomers();
    },
    methods: {
        open() {
            this.active = true;
        },
        close() {
            this.active = false;
        },
        getCustomers() {          
            axios.get(`/customer/search`, { params: this.form })
                .then((res) => {
                    this.customers = res.data;
                })
                .catch((error) => {
                    if (error.response.status === 422) {
                        this.errors = error.response.data.errors;
                    } else if (error.response.status === 500) {
                        const status = error.response.status;
                        window.alert(this.dg.internal_error);
                    }
                });
        },
        selectKokyk(customer) {
            this.$emit('set-customer', customer);
            this.close();
        },
    },
}
</script>
