<template>
    <div class="table-responsive" style="height: 700px; resize: both;" ref="scrollDiv" @scroll="handleTableScroll">
        <sortable-table2 ref="sortable"
            v-model="items"
            :default-sort-key="sort.key"
            :default-sort-asc="sort.asc"
            :session-name="sessionName"
        >
            <template #default="{setIcon, sort}">
                <table class="table table-hover table-sm text-nowrap">
                    <thead class="sticky-top bg-body">
                        <tr>
                            <th style="min-width: 2rem">{{ dg.SequentialNumber }}</th>
                            <th style="min-width: 7rem; cursor:pointer" @click="sort('DRAW_NO')">{{ dg.SalTDraw.DRAW_NO }}<font-awesome-icon :icon="setIcon('DRAW_NO')" /></th>
                            <th style="min-width: 6rem">{{ dg.SalTSales.STATUS }}</th>
                            <th style="min-width: 6rem; cursor:pointer" @click="sort('DRAW_YM')">{{ dg.SalTDraw.DRAW_YM }}<font-awesome-icon :icon="setIcon('DRAW_YM')" /></th>
                            <th style="min-width: 6rem; cursor:pointer" @click="sort('INV_DATE')">{{ dg.SalTDraw.INV_DATE }}<font-awesome-icon :icon="setIcon('INV_DATE')" /></th>
                            <th style="min-width: 6rem">{{ dg.SalTDraw.DRAW_DATE }}</th>
                            <th style="min-width: 6rem">{{ dg.SalTDraw.PAY_DATE }}</th>
                            <th class="text-end" style="min-width: 4rem">{{ dg.SalTDraw.count }}</th>
                            <th class="text-end" style="min-width: 5rem">{{ dg.SalTDraw.price }}</th>
                            <th style="min-width: 10rem">{{ dg.SalTDraw.NOTE }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in items" :key="index">
                            <td>{{ index + 1 }}</td>
                            <td><a href="#" @click.prevent.stop="edit(item.DRAW_NO)">{{ item.DRAW_NO }}</a></td>
                            <td>{{ invoiceStatusList[item.STATUS] }}</td>
                            <td>{{ item.DRAW_YM?.replaceAll('-', '/') }}</td>
                            <td>{{ item.INV_DATE?.replaceAll('-', '/') }}</td>
                            <td>{{ item.DRAW_DATE?.replaceAll('-', '/') }}</td>
                            <td>{{ item.PAY_DATE?.replaceAll('-', '/') }}</td>
                            <td class="text-end">{{ item.count ? parseInt(item.count) : 0 }}</td>
                            <td class="text-end">{{ item.tPrice ? parseInt(item.tPrice).toLocaleString() : 0 }}</td>
                            <td>{{ strLimit(item.NOTE, 10) }}</td>
                        </tr>
                    </tbody>
                </table>
            </template>
        </sortable-table2>
    </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import Mixin from '../mixins/mixin'
import SortableTable2 from '../tables/SortableTable2.vue';

export default {
    name: 'DrawIndexTable',
    emits: ['update:model-value'],
    mixins: [Mixin],
    components: {
        FontAwesomeIcon,
        SortableTable2,
    },
    props: {
        dg: Object,
        documentRoot: String,
        invoiceStatusList: Object,
        modelValue: Array,
        sort: Object,
        sessionName: String,
    },
    computed: {
        items: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:model-value', value)
            }
        },
    },
    methods: {
        init(){
            this.$refs.sortable.initialSort();
        },
        edit(primaryKey) {
            location.href = `${this.documentRoot}/draw/${primaryKey}/edit`;
        },
        scroll(height) {
            this.$refs.scrollDiv.scrollTop = height;
        },
        handleTableScroll(e) {
            this.$emit('handleTableScroll', e);
        }
    },
}
</script>