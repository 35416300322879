<template>
    <input type="text" :name="nameAttribute" class="form-control form-control-sm text-end" :id="nameAttribute" v-model="modelVal" @change="handleKokykIdInput(modelVal)">
</template>

<script>
export default {
    name: 'InputKokykId',
    emits: ['set-customer', 'update:model-value'],
    props: {
        modelValue: String,
        nameAttribute: { type: String, default: 'KOKYK_ID'},
    },
    created() {
    },  
    computed: {
        modelVal: {
            get() {
                return this.modelValue
            },
            set(value) {
                this.$emit('update:model-value', value)
            }
        }
    },
    methods: {
        async handleKokykIdInput(inputtedText) {
            try {
                // 半角に変換
                const hankakuText = await this._hankaku(inputtedText);

                // フィルター（数字以外の場合は処理中断）
                if ( ! hankakuText || isNaN(hankakuText)) {
                    return;
                }

                // 顧客検索し、ヒットした場合はセットする
                const {data: {data: [customer] = [] } } = await this._searchCustomer( { KOKYK_ID: hankakuText } );
                if (customer) {
                    this._setCustomer(customer);
                }
            } catch (error) {
                console.error(error);
            }
        },
        _hankaku(targetText) {
            return new Promise(resolve => {
                const hankakuText = targetText.replace(/[！-～]/g, input => String.fromCharCode(input.charCodeAt(0)-0xFEE0));
                resolve(hankakuText);
            });
        },
        _searchCustomer(params) {
            return axios.get(`/customer/search`, { params: params } );
        },
        _setCustomer(customer) {
            this.$emit('set-customer', customer);
        },
        
    },
}
</script>
