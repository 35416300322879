<template>
    <form ref="form">
        <div class="card mb-1">
            <div class="card-body py-1">
                <div class="row mb-2">
                    <!-- 仕入番号 -->
                    <div class="col-1">
                        <label
                            for="STOCK_NO"
                            class="form-label form-label-sm"
                            >{{ dg.SalTStock.STOCK_NO }}</label
                        >
                        <input
                            readonly
                            name="STOCK_NO"
                            type="text"
                            class="form-control form-control-sm"
                            id="STOCK_NO"
                            :value="form.STOCK_NO"
                        />
                        <error-message :error="errors.STOCK_NO"></error-message>
                    </div>
                    <!-- 状態 -->
                    <div class="col-1">
                        <label for="STATUS" class="form-label">{{
                            dg.SalTStock.STATUS
                        }}</label>
                        <select
                            name="STATUS"
                            class="form-select form-select-sm"
                            id="STATUS"
                            v-model="form.STATUS"
                        >
                            <option
                                v-for="(stockStatus, index) in stockStatusList"
                                :key="index"
                                :value="index"
                            >
                                {{ stockStatus }}
                            </option>
                        </select>
                        <error-message :error="errors.STATUS"></error-message>
                    </div>
                    <!-- 仕入日 -->
                    <div class="col-2">
                        <label for="STOCK_DATE" class="form-label">{{
                            dg.SalTStock.STOCK_DATE
                        }}</label>
                        <input
                            type="date"
                            name="STOCK_DATE"
                            class="form-control form-control-sm"
                            id="STOCK_DATE"
                            @blur="focusOut"
                            v-model="form.STOCK_DATE"
                        />
                        <error-message
                            :error="errors.STOCK_DATE"
                        ></error-message>
                    </div>
                    <!-- 入荷日 -->
                    <div class="col-2">
                        <label for="IN_DATE" class="form-label">{{
                            dg.SalTStock.IN_DATE
                        }}</label>
                        <input
                            type="date"
                            name="IN_DATE"
                            class="form-control form-control-sm"
                            id="IN_DATE"
                            @blur="focusOut"
                            v-model="form.IN_DATE"
                        />
                        <error-message :error="errors.IN_DATE"></error-message>
                    </div>
                    <!-- 納品日 -->
                    <div class="col-2">
                        <label for="DELI_DATE" class="form-label">{{
                            dg.SalTStock.DELI_DATE
                        }}</label>
                        <input
                            type="date"
                            name="DELI_DATE"
                            class="form-control form-control-sm"
                            id="DELI_DATE"
                            @blur="focusOut"
                            v-model="form.DELI_DATE"
                        />
                        <error-message
                            :error="errors.DELI_DATE"
                        ></error-message>
                        <toast
                            ref="toastForDeliDate"
                            :title="dg.SalTStock.toastForDeliDate.title"
                            :body="dg.SalTStock.toastForDeliDate.body"
                        ></toast>
                    </div>
                    <!-- 購入店 -->
                    <div class="col-2">
                        <label for="PAYER" class="form-label">{{
                            dg.SalTStock.PAYER
                        }}</label>
                        <input
                            type="text"
                            name="PAYER"
                            class="form-control form-control-sm"
                            id="PAYER"
                            v-model="form.PAYER"
                        />
                        <error-message :error="errors.PAYER"></error-message>
                    </div>
                </div>
                <div class="row mb-2">
                    <!-- 販売番号 -->
                    <div class="col-1">
                        <label
                            for="SALES_NO"
                            class="form-label form-label-sm"
                            >{{ dg.SalTStock.SALES_NO }}</label
                        >
                        <input
                            name="SALES_NO"
                            type="text"
                            class="form-control form-control-sm"
                            id="SALES_NO"
                            v-model="form.SALES_NO"
                        />
                        <error-message :error="errors.SALES_NO"></error-message>
                    </div>
                    <!-- 商品番号 -->
                    <div class="col-3">
                        <label for="PRODUCT_CODE" class="form-label">{{
                            dg.PRODUCT_NAME
                        }}</label>
                        <select
                            name="PRODUCT_CODE"
                            class="form-select form-select-sm"
                            id="PRODUCT_CODE"
                            aria-label="Default select example"
                            v-model="form.PRODUCT_CODE"
                        >
                            <option
                                v-for="(
                                    productName, productCode
                                ) in productList"
                                :key="productCode"
                                :value="productCode"
                            >
                                {{ productCode + "/" + productName }}
                            </option>
                        </select>
                        <error-message
                            :error="errors.PRODUCT_CODE"
                        ></error-message>
                    </div>
                    <input
                        type="hidden"
                        name="PRODUCT_NAME"
                        :value="form.PRODUCT_NAME"
                    />
                    <!-- 顧客番号 -->
                    <div v-if="form.salTSales?.KOKYK_ID" class="col-1">
                        <label for="KOKYK_ID" class="form-label">{{
                            dg.KOKYK_ID
                        }}</label>
                        <input
                            readonly
                            name="KOKYK_ID"
                            type="text"
                            class="form-control form-control-sm"
                            id="KOKYK_ID"
                            :value="form.salTSales?.KOKYK_ID"
                        />
                    </div>
                    <!-- 顧客名 -->
                    <div v-if="form.salTSales?.CLNIC_NM" class="col-2">
                        <label for="CLNIC_NM" class="form-label">{{
                            dg.CLNIC_NM
                        }}</label>
                        <input
                            readonly
                            type="text"
                            name="CLNIC_NM"
                            class="form-control form-control-sm"
                            id="CLNIC_NM"
                            :value="form.salTSales?.CLNIC_NM"
                        />
                    </div>
                </div>
                <div class="row mb-2">
                    <!-- 単価 -->
                    <div class="col-1">
                        <label for="UPRICE" class="form-label">{{
                            dg.SalTStock.UPRICE
                        }}</label>
                        <input-hankaku
                            v-model="form.UPRICE"
                            inputType="text"
                            inputName="UPRICE"
                            inputId="UPRICE"
                        ></input-hankaku>
                        <error-message :error="errors.UPRICE"></error-message>
                    </div>
                    <!-- メーカー -->
                    <div class="col-3">
                        <label for="MAKER" class="form-label">{{
                            dg.SalTStock.MAKER
                        }}</label>
                        <input
                            type="text"
                            name="MAKER"
                            class="form-control form-control-sm"
                            id="MAKER"
                            autocomplete="on"
                            list="maker"
                            v-model="form.MAKER"
                        />
                        <datalist id="maker">
                            <option v-for="list in datalist?.MAKER" :key="list">
                                {{ list }}
                            </option>
                        </datalist>
                        <error-message :error="errors.MAKER"></error-message>
                    </div>
                    <!-- 購入店 -->
                    <div class="col-2">
                        <label for="SHOP" class="form-label">{{
                            dg.SalTStock.SHOP
                        }}</label>
                        <input
                            type="text"
                            name="SHOP"
                            class="form-control form-control-sm"
                            id="SHOP"
                            autocomplete="on"
                            list="shop"
                            v-model="form.SHOP"
                        />
                        <datalist id="shop">
                            <option v-for="list in datalist?.SHOP" :key="list">
                                {{ list }}
                            </option>
                        </datalist>
                        <error-message :error="errors.SHOP"></error-message>
                    </div>
                    <!-- 注文番号 -->
                    <div class="col-2">
                        <label for="ORDERNO" class="form-label">{{
                            dg.SalTStock.ORDERNO
                        }}</label>
                        <input
                            type="text"
                            name="ORDERNO"
                            class="form-control form-control-sm"
                            id="ORDERNO"
                            v-model="form.ORDERNO"
                        />
                        <error-message :error="errors.ORDERNO"></error-message>
                    </div>
                    <!-- 仕入商品名 -->
                    <div class="col-3">
                        <label for="PRODNAME" class="form-label">{{
                            dg.SalTStock.PRODNAME
                        }}</label>
                        <input
                            type="text"
                            name="PRODNAME"
                            class="form-control form-control-sm"
                            autocomplete="on"
                            list="prodname"
                            id="PRODNAME"
                            v-model="form.PRODNAME"
                        />
                        <datalist id="prodname">
                            <option
                                v-for="list in datalist?.PRODNAME"
                                :key="list"
                            >
                                {{ list }}
                            </option>
                        </datalist>
                        <error-message :error="errors.PRODNAME"></error-message>
                    </div>
                </div>
                <pc-input-body
                    v-if="isPc"
                    v-model="form.salTPc"
                    :dg="dg"
                    :edit="edit"
                    :errors="errors"
                    :pc-cpu-list="pcCpuList"
                    :pc-disk-type-list="pcDiskTypeList"
                    :pc-purchase-list="pcPurchaseList"
                    :pc-os-list="pcOsList"
                ></pc-input-body>
                <div class="row mb-2">
                    <!-- 備考1 -->
                    <div class="col-6">
                        <label for="STOCK_NOTE1" class="form-label">{{
                            dg.SalTStock.STOCK_NOTE1
                        }}</label>
                        <textarea
                            name="STOCK_NOTE1"
                            class="form-control form-control-sm"
                            id="STOCK_NOTE1"
                            v-model="form.STOCK_NOTE1"
                            rows="5"
                        ></textarea>
                    </div>
                </div>
                <div class="mb-2 row">
                    <!-- ファイル -->
                    <file-upload
                        v-model="form"
                        v-model:filesModelValue="files"
                        :dg="dg"
                        :max-file-count="3"
                        :slice-num="25"
                        :document-root="documentRoot"
                    ></file-upload>
                </div>
            </div>
        </div>
        <div class="d-grid gap-2 d-md-flex justify-content-md-end">
            <!-- コピーボタン -->
            <copy-button
                v-if="edit"
                :alert-flag="changed"
                :alert-message="dg.changedMessage"
                :confirm-message="`仕入番号${
                    this.form.STOCK_NO + dg.copyConfirmMessage
                }`"
                :url="`${documentRoot}/stock/${form.STOCK_NO}/copy`"
            ></copy-button>
            <!-- 削除ボタン -->
            <button
                type="button"
                class="btn btn-danger"
                v-if="edit"
                @click="destroy"
            >
                削除
            </button>
            <!-- 中止ボタン -->
            <cancel-button
                :confirm-flag="changed"
                :confirm-message="dg.cancelMessage"
                :url="`${documentRoot}/stock`"
                >{{ dg.cancel }}</cancel-button
            >
            <button type="button" class="btn btn-primary" @click="register">
                登録
            </button>
        </div>

        <modal-dialog ref="dialog" :dg="dg" @update="update"></modal-dialog>
    </form>
</template>

<script>
import moment from "moment";
import CancelButton from "../buttons/CancelButton.vue";
import CopyButton from "../buttons/CopyButton.vue";
import FileUpload from "../inputs/FileUpload.vue";
import InputHankaku from "../inputs/InputHankaku.vue";
import PcInputBody from "../pc/PcInputBody.vue";
import Toast from "../utils/Toast.vue";
import ModalDialog from "./ModalDialog.vue";

export default {
    name: "StockIndex",
    components: {
        CancelButton,
        CopyButton,
        FileUpload,
        InputHankaku,
        ModalDialog,
        PcInputBody,
        Toast,
    },
    props: {
        dg: Object,
        data: Object,
        documentRoot: String,
        stockStatusList: Object,
        stockStatusStock: Object,
        stockStatusSold: Object,
        productList: Object,
        diskTypeList: Object,
        pcCpuList: Object,
        pcDiskTypeList: Object,
        pcPurchaseList: Object,
        pcOsList: Object,
    },
    data() {
        return {
            form: {},
            errors: {},
            edit: false,
            datalist: {},
            changed: false,
            files: [],
            isPc: false,
        };
    },
    created() {
        this.form = this.data;
        this.form.STATUS = this.form.STATUS ?? 0;
        this.edit = this.form.STOCK_NO ? true : false;
        this.form.STOCK_DATE = this.edit
            ? this.form.STOCK_DATE
            : moment().format("YYYY-MM-DD");
        this.setDataList("MAKER");
        this.setDataList("SHOP");
        this.setDataList("PRODNAME");
        this.setDataList("CPUTYPE1");
        this.setDataList("CPUTYPE2");
        this.setDataList("OS");
        this.setDataList("OFFICE");
    },
    mounted() {
        const unwatch = this.$watch(
            "form",
            (newVal) => {
                this.changed = true;
                unwatch();
            },
            { deep: true }
        );
    },
    methods: {
        register() {
            this.edit ? this.$refs.dialog.open() : this.create();
        },
        setDataList(columnName) {
            axios.get(`/api/stock/${columnName}`).then((res) => {
                this.datalist[columnName] = res.data;
            });
        },
        create() {
            axios
                .post(`/stock`, this.getFormData(), {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then((res) => {
                    location.href = res.data;
                })
                .catch((error) => {
                    if (error.response.status === 422) {
                        this.errors = error.response.data.errors;
                    } else if (error.response.status === 500) {
                        const status = error.response.status;
                        window.alert(this.dg.internal_error);
                    }
                });
        },
        update() {
            axios
                .post(`/stock/${this.form.STOCK_NO}`, this.getFormData(), {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        "X-HTTP-Method-Override": "PUT",
                    },
                })
                .then((res) => {
                    location.href = res.data;
                })
                .catch((error) => {
                    if (error.response.status === 422) {
                        this.errors = error.response.data.errors;
                    } else if (error.response.status === 500) {
                        const status = error.response.status;
                        window.alert(this.dg.internal_error);
                    }
                });
        },
        getFormData() {
            const formData = new FormData(this.$refs.form);

            formData.set("FILE1", "");
            formData.set("FILE2", "");
            formData.set("FILE3", "");
            for (let i = 0; i < this.files.length; i++) {
                if (this.files[i].file) {
                    formData.set("FILE" + (i + 1), this.files[i].file);
                } else {
                    formData.set("FILE" + (i + 1), this.files[i].name);
                }
            }

            return formData;
        },
        destroy() {
            if (confirm(this.dg.deleteConfirmMessage)) {
                axios.delete(`/stock/${this.form.STOCK_NO}`).then((res) => {
                    location.href = res.data;
                });
            }
        },
    },
    watch: {
        "form.PRODUCT_CODE": {
            handler(newVal) {
                this.form.PRODUCT_NAME = this.productList[newVal];
                const code = Number(newVal);
                this.isPc = code >= 8000 && code <= 8099;
            },
        },
        "form.DELI_DATE": {
            handler(newVal) {
                if (newVal && this.form.STATUS === this.stockStatusStock) {
                    this.form.STATUS = this.stockStatusSold;
                    this.$refs.toastForDeliDate.show();
                }
            },
        },
    },
};
</script>
