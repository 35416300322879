<template>
    <div v-if="active">
        <div class="modal" style="display: block;"  @click.self="modalClose()">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">確認</h5>
                    </div>
                        <div class="modal-body" style="white-space: pre-wrap;">
                            {{ message }}
                        </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary" data-bs-dismiss="modal" @click="setAnswer(1)">はい</button>
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="setAnswer(0)">いいえ</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-backdrop show" ></div>
    </div>
</template>

<script>
export default {
    name: 'YesNoModal2',
    props: {
        message: String
    },
    data() {
        return {
            active: false,
            answer: null
        }
    },
    methods: {
        action() {
            this.modalOpen();
            return new Promise((resolve, reject) => {
                const unwatch = this.$watch('answer', newVal => {
                    resolve(newVal);
                    unwatch();
                    this.initAnswer();
                    unwatch2();
                    this.modalClose();
                });
                const unwatch2 = this.$watch('active', newVal => {
                    unwatch();
                    unwatch2();
                    reject('modal: closed');
                })
            });
        },
        initAnswer() {
            this.answer = null;
        },
        modalOpen() {
            this.active = true;
        },
        modalClose() {
            this.active = false;
        },
        setAnswer(value) {
            this.answer = value;
        }
    },
}
</script>
