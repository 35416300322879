<template>
    <form ref="form">
        <div class="card mb-1">
            <div class="card-body py-1">
                <div class="row mb-2">

                    <!-- 顧客番号と検索ボタン -->
                    <input-kokyk-id-with-button
                        v-model="form.KOKYK_ID"
                        @set-customer="setCustomerInfo"
                        :dg="dg"
                        :del-flag="delFlag"
                        :errors="errors"
                        :syzk-list="syzkList"
                    ></input-kokyk-id-with-button>
                    
                    <!-- 顧客名 -->
                    <div class="col-2">
                        <label for="CLNIC_NM" class="form-label">{{ dg.MKokykLic.CLNIC_NM }}</label>
                        <input type="text" name="CLNIC_NM" class="form-control form-control-sm" id="CLNIC_NM" v-model="form.CLNIC_NM">
                        <error-message :error="errors.CLNIC_NM"></error-message>
                    </div>
                    
                    <!-- 購入元種別 -->
                    <div class="col-2">
                        <label for="KIND" class="form-label form-label-sm">{{ dg.SalTPc.KIND }}</label>
                        <select name="KIND" class="form-select form-select-sm" id="KIND" v-model="form.KIND">
                            <option v-for="(pcPurchase, index) in pcPurchaseList" :key="index" :value="index">{{ pcPurchase }}</option>
                        </select>
                        <error-message :error="errors.KIND"></error-message>
                    </div>
                    
                    <!-- 仕入番号 -->
                    <div class="col-1" >
                        <label for="STOCK_NO" class="form-label form-label-sm">{{ dg.SalTPc.STOCK_NO }}</label>
                        <input :readonly="form.KIND !== 1" type="text" name="STOCK_NO" class="form-control form-control-sm" id="STOCK_NO" v-model="form.STOCK_NO">
                        <error-message :error="errors.STOCK_NO"></error-message>
                    </div>
                </div>
                <div class="row mb-2">      
                    <!-- 商品コード -->
                    <div class="col-2">
                        <label for="PRODUCT_CODE" class="form-label form-label-sm">{{ dg.SalTPc.PRODUCT_CODE }}</label>
                        <select name="PRODUCT_CODE" class="form-select form-select-sm" id="PRODUCT_CODE" v-model="form.PRODUCT_CODE">
                            <option v-for="(PRODUCT_NAME, PRODUCT_CODE) in pcDeviceList" :key="PRODUCT_CODE" :value="PRODUCT_CODE">{{ PRODUCT_CODE + '/' + PRODUCT_NAME }}</option>
                        </select>
                        <error-message :error="errors.PRODUCT_CODE"></error-message>
                    </div>
                    <!-- メーカー -->
                    <div class="col-2">
                        <label for="MAKER" class="form-label">{{ dg.SalTPc.MAKER }}</label>
                        <input type="text" name="MAKER" class="form-control form-control-sm" id="MAKER" autocomplete="on" list="maker" v-model="form.MAKER">
                        <datalist id="maker" >
                            <option v-for="list in datalist?.MAKER" :key="list">{{list}}</option>
                        </datalist>
                        <error-message :error="errors.MAKER"></error-message>
                    </div>
                    <!-- 購入店 -->
                    <div class="col-2">
                        <label for="SHOP" class="form-label">{{ dg.SalTPc.SHOP }}</label>
                        <input type="text" name="SHOP" class="form-control form-control-sm" id="SHOP" autocomplete="on" list="shop" v-model="form.SHOP">
                        <datalist id="shop" >
                            <option v-for="list in datalist?.SHOP" :key="list">{{list}}</option>
                        </datalist>
                        <error-message :error="errors.SHOP"></error-message>
                    </div>
                    <!-- 購入日 -->
                    <div class="col-2">
                        <label for="BUY_DATE" class="form-label">{{ dg.SalTPc.BUY_DATE }}</label>
                        <input type="date" name="BUY_DATE" class="form-control form-control-sm" id="BUY_DATE" autocomplete="on" list="BUY_DATE" v-model="form.BUY_DATE">
                        <datalist id="BUY_DATE" >
                            <option v-for="list in datalist?.BUY_DATE" :key="list">{{list}}</option>
                        </datalist>
                        <error-message :error="errors.BUY_DATE"></error-message>
                    </div>
                </div>
                <pc-input-body
                    v-model="form"
                    :dg="dg"
                    :edit="edit"
                    :errors="errors"
                    :pc-cpu-list="pcCpuList"
                    :pc-device-list="pcDeviceList"
                    :pc-disk-type-list="pcDiskTypeList"
                    :pc-purchase-list="pcPurchaseList"
                    :pc-os-list="pcOsList"
                ></pc-input-body>
                <div class="row mb-2">
                    <!-- 備考1 -->
                    <div class="col-12">
                        <label for="NOTE1" class="form-label">{{ dg.SalTPc.NOTE1 }}</label>
                        <textarea name="NOTE1" class="form-control form-control-sm" id="NOTE1" v-model="form.NOTE1" rows="5"></textarea>
                    </div>
                </div>
                <div class="mb-2 row">
                    <!-- ファイル -->
                    <file-upload
                        v-model="form"
                        v-model:filesModelValue="files"
                        :dg="dg"
                        :max-file-count=3
                        :slice-num=25
                        :document-root="documentRoot"
                    ></file-upload>
                </div>
            </div>   
        </div>
        <div class="d-grid gap-2 d-md-flex justify-content-md-end">
            <!-- 削除ボタン -->
            <delete-button v-if="edit"
                :confirm-message="dg.deleteConfirmMessage"
                :dg="dg"
                :url="`/pc/${form.ID}`"
            >{{ dg.Delete }}</delete-button>
            <!-- 中止ボタン -->
            <cancel-button
                :confirm-flag="changed"
                :confirm-message="dg.cancelMessage"
                :url="`${documentRoot}/pc`"
            >{{ dg.cancel }}</cancel-button>
            <button type="button" class="btn btn-primary" @click="register">登録</button>
        </div>
        <modal-dialog ref="dialog"
            :dg = dg
            @update="update"
        ></modal-dialog>

    </form>
</template>

<script>
import CancelButton from '../buttons/CancelButton.vue';
import DeleteButton from '../buttons/DeleteButton.vue';
import FileUpload from '../inputs/FileUpload.vue';
import InputHankaku from '../inputs/InputHankaku.vue';
import InputKokykIdWithButton from '../inputs/InputKokykIdWithButton.vue';
import ModalDialog from '../modals/ModalDialog.vue';
import PcIndexTable from './PcIndexTable.vue';
import PcInputBody from './PcInputBody.vue';

export default {
    name: 'PcInput',
    components: {
        CancelButton,
        DeleteButton,
        FileUpload,
        InputHankaku,
        InputKokykIdWithButton,
        ModalDialog,
        PcIndexTable,
        PcInputBody,
    },
    props: {
        data: Object,
        delFlag: Object,
        dg: Object,
        documentRoot: String,
        pcCpuList: Object,
        pcDeviceList: Object,
        pcDiskTypeList: Object,
        pcPurchaseList: Object,
        pcOsList: Object,
        syzkList: Object,
    },
    data() {
        return {
            changed: false,
            datalist: {},
            edit: false,
            errors: {},
            files: [],
            form: {},
        }
    },
    created() {
        this.form = this.data;
        this.edit = this.form.ID ? true : false;
        // 初期値
        this.form.KIND = this.edit ? this.form.KIND : 0; // 顧客自身
        // 過去に入力した一覧をフェッチ
        this.setDataList('MAKER');
        this.setDataList('SHOP');
        this.setDataList('CPUTYPE2');
        this.setDataList('OFFICE');
    },
    mounted() {
        const unwatch = this.$watch('form', () => {
            this.changed = true;
            unwatch();
        },{ deep: true });
    },
    methods: {
        register() {
            this.edit ? this.$refs.dialog.open() : this.create();
        },
        setDataList(columnName) {
            axios.get(`/api/pc/${columnName}`)
                .then((res) => {
                    this.datalist[columnName] = res.data;
                });
        },
        setCustomerInfo(mKokykLic) {
            const keys = ['KOKYK_ID', 'CLNIC_NM'];

            for (const key of keys) {
                this.form[key] = mKokykLic[key];
            }
        },
        getFormData () {
            const formData = new FormData(this.$refs.form);

            formData.set('FILE1', '');
            formData.set('FILE2', '');
            formData.set('FILE3', '');
            for (let i = 0; i < this.files.length; i++) {
                if (this.files[i].file) {
                    formData.set('FILE' + (i + 1), this.files[i].file);
                } else {
                    formData.set('FILE' + (i + 1), this.files[i].name);
                }
            }

            return formData;
        },
        create() {
            axios.post(`/pc`, this.getFormData(), {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((res) => {
                location.href = res.data;
            })
            .catch((error) => {
                if (error.response.status === 422) {
                    this.errors = error.response.data.errors;
                } else if (error.response.status === 500) {
                    const status = error.response.status;
                    window.alert(this.dg.internal_error);
                }
            });
        },
        update() {
            axios.post(`/pc/${this.form.ID}`, this.getFormData(), {
                headers: {
                    "Content-Type": "multipart/form-data",
                    'X-HTTP-Method-Override' : 'PUT',
                },
            })
            .then((res) => {
                location.href = res.data;
            })
            .catch((error) => {
                if (error.response.status === 422) {
                    this.errors = error.response.data.errors;
                } else if (error.response.status === 500) {
                    const status = error.response.status;
                    window.alert(this.dg.internal_error);
                }
            });
        },
    },
    watch: {
    },
}
</script>