<template>
    <form ref="form" @submit.prevent="fetchItems()" class="mb-2">
        <div class="row">
            <div class="col-1">
                <div class="row mb-2">
                    <div class="d-grid gap-2 d-md-flex justify-content-md-start">
                        <link-button
                            :url='`${documentRoot}/letsin/create`'
                        >{{ dg.letsin.create }}</link-button>
                    </div>
                </div>
            </div>
            <div class="col-11">
                <div class="card">
                    <div class="card-body">
                        <div class="card-text row">
                            
                            <!-- 顧客番号と検索ボタン -->
                            <input-kokyk-id-with-button
                                v-model="form.KOKYK_ID"
                                @set-customer="setCustomerInfo"
                                :dg="dg"
                                :del-flag="delFlagList"
                                :errors="errors"
                                :syzk-list="syzkList"
                            ></input-kokyk-id-with-button> 

                            <!-- 顧客名 -->
                            <div class="col-2">
                                <label for="CLNIC_NM" class="form-label">{{ dg.MKokykLic.CLNIC_NM }}</label>
                                <input type="text" name="CLNIC_NM" class="form-control form-control-sm" id="CLNIC_NM" v-model="form.CLNIC_NM">
                                <error-message :error="errors.CLNIC_NM"></error-message>
                            </div>
                            
                            <!-- 状態 -->
                            <div class="col-2" style="width: 200px; z-index: 1029;">
                                <label for="STATUS" class="form-label">{{ dg.SalTLetsin.STATUS }}</label>
                                <VueMultiselect
                                    v-model="form.STATUS"
                                    :custom-label="setStatusLabel"
                                    :options="statusListArray"
                                    label="name"
                                    :close-on-select="false"
                                    :taggable="false"
                                    :multiple="true"
                                    :searchable="false"
                                    placeholder="未選択"
                                    selectLabel="選択"
                                    selectedLabel="選択済"
                                    deselectLabel="解除"
                                    :limitText="count => `+${count}`"
                                    :limit="1"
                                >
                                </VueMultiselect>
                                <error-message :error="errors.STATUS"></error-message>
                            </div>

                            <div class="col-2 d-flex gap-1 align-items-start" style="margin-top: 23px">
                                <button type="button" class="btn btn-secondary btn-sm" @click="reset()">{{ dg.Reset }}</button>
                                <button type="submit" class="btn btn-primary btn-sm">{{ dg.Search }}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>

    <letsin-index-table ref="table"
        v-model="items"
        :dg="dg"
        :document-root="documentRoot"
        :letsin-status-list="letsinStatusList"
        :session-name="sessionName"
        :sort="sort"
        @handleTableScroll="handleTableScroll"
    ></letsin-index-table>
    
</template>

<script>
import LinkButton from '../buttons/LinkButton.vue';
import LetsinIndexTable from './LetsinIndexTable.vue';
import InputKokykIdWithButton from '../inputs/InputKokykIdWithButton.vue';
import Mixin from '../mixins/mixin'
import VueMultiselect from 'vue-multiselect';

export default {
    name: 'LetsinIndex',
    mixins: [Mixin],
    components: {
        InputKokykIdWithButton,
        LetsinIndexTable,
        LinkButton,
        VueMultiselect,
    },
    props: {
        dg                  : Object,
        documentRoot        : String,
        delFlagList         : Object,
        letsinStatusList    : Object,
        syzkList            : Object,
    },
    data() {
        return {
            form        : {},
            items       : [],
            errors      : {},
            scroll      : 0,
            sessionName : 'letsinIndex',
            sort        : {},
        }
    },
    async created() {
        // プロパティ初期化
        this.init();
        // セッションの操作
        this.sessionHandler();
        // 一覧の取得
        await this.fetchItems();
        // 指定値までスクロール
        this.$refs.table.scroll(this.scroll);
    },
    methods: {
        setStatusLabel(code) {
            return `${this.letsinStatusList[code]}`;
        },
        sessionHandler() {
            const sessionData = this.getSession(this.sessionName);
            const sessionDataKeys = ['form', 'scroll', 'sort'];
                        
            // セッションのデータキーを順に確認し、
            sessionDataKeys.forEach(key => {
                // キーが存在する場合、セッションから代入
                if (key in sessionData) {
                    this[key] = sessionData[key]
                // キーが存在しない場合、セッションに値を保存
                } else {
                    this.setSession(this.sessionName, { [key]: this[key] })
                }
            });
        },
        init() {
            // 検索条件
            this.form = {
                KOKYK_ID : '',
                CLNIC_NM : '',
                STATUS   : [0, 1],
            },
            // スクロール量
            this.scroll = 0;
            // ソート
            this.sort = {
                key: 'KOKYK_ID',
                asc: true,
            }
        },
        handleTableScroll(e) {
            this.setSession(this.sessionName, { scroll: e.target.scrollTop } );
        },
        setCustomerInfo({ KOKYK_ID, CLNIC_NM }) {
            this.form = { ...this.form, KOKYK_ID, CLNIC_NM};
        },
        async fetchItems() {
            this.errors = {};

            try {
                const { data: { data } } = await axios.get(`/letsin/search`, { params: this.form })
                await new Promise(resolve => {
                    this.items = data;
                    resolve();
                });
                // 検索条件をセッション保存
                this.setSession(this.sessionName, { form: this.form } );
                this.$refs.table.init();
            } catch (error) {
                if (error.response.status == 422) {
                    this.errors = error.response.data.errors;
                } else {
                    console.error(error);
                }
            }
        },
        reset() {
            this.init();
            this.fetchItems();
        },
    },
    computed: {
        statusListArray() {
            return Object.keys(this.letsinStatusList).map(key => Number(key));
        },
    },
}
</script>