<template>
    <div class="text-danger" v-for="(errorMessage, index) in error" :key="index">{{ errorMessage }}</div>
</template>

<script>
export default {
    name: 'ErrorMessage',
    props: {
        error: {
            type: Array,
            required: false, 
            default: [],
        },
    },
}
</script>