<template>
    <product-select-slot
        v-model="modelVal"
        :dg="dg"
        :index="index"
        :product-obj-list="productObjList"
        :clear-column-list="[
            'PRODUCT_NAME',
            'UPRICE',
            'CNT',
            'PRICE',
            'SALES_NO',
            'INV_DNOTE1',
            'INV_DNOTE2',
        ]"
    >
        <template #default="{ setCnt, handleProductCodeChange }">
            <tr>
                <td>{{ modelVal.RNO }}</td>
                <td>
                    <select
                        class="form-select form-select-sm"
                        v-model="modelVal.PRODUCT_CODE"
                        @change="handleProductCodeChange"
                    >
                        <option :value="null"></option>
                        <option
                            v-for="(productObj, index) in productObjList"
                            :key="index"
                            :value="productObj.PRODUCT_CODE"
                        >
                            {{
                                productObj.PRODUCT_CODE +
                                "/" +
                                productObj.PRODUCT_NAME
                            }}
                        </option>
                    </select>
                    <error-message
                        :error="getErrors('PRODUCT_CODE')"
                    ></error-message>
                </td>
                <td>
                    <input
                        type="text"
                        name="PRODUCT_NAME"
                        class="form-control form-control-sm"
                        v-model="modelVal.PRODUCT_NAME"
                    />
                    <!-- <error-message :error="errors.PRODUCT_NAME"></error-message> -->
                </td>
                <td>
                    <input-hankaku
                        v-model="modelVal.UPRICE"
                        inputType="text"
                        inputName="UPRICE"
                        @blur="setCnt"
                    ></input-hankaku>
                    <error-message :error="getErrors('UPRICE')"></error-message>
                </td>
                <td>
                    <input-hankaku
                        v-model="modelVal.CNT"
                        inputType="text"
                        inputName="CNT"
                    ></input-hankaku>
                    <error-message :error="getErrors('CNT')"></error-message>
                </td>
                <td>
                    <input
                        readonly
                        type="text"
                        class="form-control form-control-sm text-end"
                        :value="modelVal.PRICE.toLocaleString()"
                    />
                </td>
                <td class="text-center" style="vertical-align: middle">
                    <a
                        :href="`${documentRoot}/sales/${modelVal.SALES_NO}/edit`"
                        target="_blank"
                        >{{ modelVal.SALES_NO }}</a
                    >
                </td>
                <td>
                    <!-- 契約開始日 -->
                    <input
                        v-if="modelVal.CONT_START"
                        type="date"
                        class="form-control form-control-sm"
                        v-model="modelVal.CONT_START"
                    />
                    <input
                        v-else
                        disabled
                        type="date"
                        class="form-control form-control-sm"
                        v-model="modelVal.CONT_START"
                    />
                </td>
                <td>
                    <!-- 契約期間 -->
                    <input
                        v-if="modelVal.CONT_PERIOD"
                        type="text"
                        class="form-control form-control-sm text-end"
                        v-model="modelVal.CONT_PERIOD"
                    />
                    <input
                        v-else
                        type="text"
                        disabled
                        class="form-control form-control-sm"
                        v-model="modelVal.CONT_PERIOD"
                    />
                </td>
                <td>
                    <input
                        class="form-control form-control-sm"
                        id="INV_DNOTE1"
                        v-model="modelVal.INV_DNOTE1"
                    />
                    <error-message
                        :error="getErrors('INV_DNOTE1')"
                    ></error-message>
                </td>
                <td>
                    <input
                        class="form-control form-control-sm"
                        id="INV_DNOTE2"
                        v-model="modelVal.INV_DNOTE2"
                    />
                    <error-message
                        :error="getErrors('INV_DNOTE2')"
                    ></error-message>
                </td>
            </tr>
        </template>
    </product-select-slot>
</template>

<script>
import InputHankaku from "../inputs/InputHankaku.vue";
import ProductSelectSlot from "../slots/ProductSelectSlot.vue";

export default {
    name: "InvoiceDetailInput",
    emits: ["update:model-value"],
    components: {
        InputHankaku,
        ProductSelectSlot,
    },
    props: {
        modelValue: Object,
        dg: Object,
        documentRoot: String,
        errors: { type: Array, required: false, default: [] },
        index: Number,
        productObjList: Array,
    },
    mounted() {
        this.modelVal.RNO = this.index + 1;
    },
    methods: {
        getErrors(name) {
            return (
                this.$parent.errors[`salTInvoiceDtls.${this.index}.${name}`] ??
                []
            );
        },
    },
    computed: {
        modelVal: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit("update:model-value", value);
            },
        },
    },
};
</script>
