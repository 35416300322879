<template>
    <button type="button" class="btn btn-danger" @click="destroy"><slot>{{ dg.Delete }}</slot></button>
</template>
<script>

export default {
    name: 'DeleteButton',
    props: {
        confirmMessage: String,
        dg: Object,
        url: String,
    },
    methods: {
        destroy() {
            if (confirm(this.confirmMessage)) {
                axios.delete(this.url)
                .then((res) => {
                    location.href = res.data;
                });
            }
        },
    },
}
</script>
