<template>
    <button type="button" class="btn btn-primary" @click="clicked">コピー</button>
</template>
<script>

export default {
    name: 'CopyButton',
    props: {
        alertFlag: Boolean,
        alertMessage: String,
        confirmMessage: String,
        url: String
    },
    methods: {
        clicked() {
            if (this.alertFlag) {
                alert(this.alertMessage);
                return;
            }
            if (confirm(this.confirmMessage)) {
                window.open(this.url, '_blank');
            }
        },
    },
}
</script>
