<template>
    <button type="button" class="btn btn-primary" @click="clicked"><slot></slot></button>
</template>
<script>

export default {
    name: 'CancelButton',
    props: {
        confirmFlag: Boolean,
        confirmMessage: String,
        url: String
    },
    methods: {
        clicked() {
            if (this.confirmFlag) {
                if (!confirm(this.confirmMessage)) {
                    return;
                }
            }

            if (history.length === 1) {
                window.close();
                return;
            }

            location.href = this.url;
        },
    },
}
</script>
