<template>
    <button type="button" class="btn btn-primary btn-sm" @click="clicked">{{ buttonName }}</button>
</template>
<script>

export default {
    name: 'CsvButton',
    props: {
        buttonName: String,
        ids: Array,
        url: String,
        fileName: {
            type: String,
            default: 'file.csv',
            required: false,
        },
    },
    methods: {
        clicked() {
            axios.post(this.url, { IDs: this.ids }, {
                responseType: 'blob',
                }).then((response) => {
                    const url = URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', this.fileName);
                    document.body.appendChild(link);
                    link.click();
                })
        },
    },
}
</script>
