<template>
    <div class="d-flex flex-column justify-content-between ms-2" style="cursor:pointer">
        <font-awesome-icon v-if="prev" icon="fas fa-caret-up" @click="moveTo(prev)" />
        <font-awesome-icon v-if="next" class="mt-auto" icon="fas fa-caret-down" @click="moveTo(next)" />
    </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default {
    name: 'EnoButtons',
    props: {
        enoCheckAxios: {
            type: Function,
            required: true,
        },
        moveTo: {
            type: Function,
            required: true,
        },
        documentRoot: String,
    },
    created() {
        this.enoCheck();
    },
    data() {
        return {
            prev: null,
            next: null,
        }
    },
    methods: {
        enoCheck() {
                this.enoCheckAxios()
                .then((res) => {
                    this.prev = res.data.prev;
                    this.next = res.data.next;
                })
        },
    },
}
</script>
