<template>
    <input :type="inputType" :name="inputName" class="form-control form-control-sm text-end" :id="inputId" @blur="hankaku" v-model="modelVal">
</template>

<script>
export default {
    name: 'InputHankaku',
    emits: ['update:model-value'],
    props: {
        modelValue: String|Number,
        inputType: String,
        inputName: String,
        inputId:String,
    },
    computed: {
        modelVal: {
            get() {
                return this.modelValue
            },
            set(value) {
                this.$emit('update:model-value', value)
            }
        }
    },
    methods: {
        hankaku() {
            if(!this.modelVal) {
                return;
            }
            this.modelVal = String(this.modelVal).replace(/[！-～]/g,
                function(input){
                    return String.fromCharCode(input.charCodeAt(0)-0xFEE0);
                }
            );
        }
    },
}
</script>
