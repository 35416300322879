<template>
    <form ref="form">
        <div class="card mb-1">
            <div class="card-body py-1">
                <div class="row mb-2">
                    <!-- 請求番号 -->
                    <div class="col-1">
                        <label for="INV_NO" class="form-label form-label-sm">{{
                            dg.SalTInvoice.INV_NO
                        }}</label>
                        <input
                            readonly
                            name="INV_NO"
                            type="text"
                            class="form-control form-control-sm"
                            id="INV_NO"
                            :value="form.INV_NO"
                        />
                        <error-message :error="errors.INV_NO"></error-message>
                    </div>
                    <!-- 枝番 -->
                    <div class="col-1">
                        <label for="ENO" class="form-label">{{ dg.ENO }}</label>
                        <input
                            readonly
                            type="text"
                            name="ENO"
                            class="form-control form-control-sm text-end"
                            :value="form.ENO"
                        />
                    </div>
                    <!-- 状態 -->
                    <div class="col-1">
                        <label for="STATUS" class="form-label">{{
                            dg.SalTInvoice.STATUS
                        }}</label>
                        <select
                            name="STATUS"
                            class="form-select form-select-sm"
                            id="STATUS"
                            v-model.number="form.STATUS"
                        >
                            <option
                                v-for="(
                                    invoiceStatus, index
                                ) in invoiceStatusList"
                                :key="index"
                                :value="index"
                            >
                                {{ invoiceStatus }}
                            </option>
                        </select>
                        <error-message :error="errors.STATUS"></error-message>
                    </div>
                    <!-- 請求日 -->
                    <div class="col-2">
                        <label for="INV_DATE" class="form-label">{{
                            dg.SalTInvoice.INV_DATE
                        }}</label>
                        <input
                            type="date"
                            name="INV_DATE"
                            class="form-control form-control-sm"
                            id="INV_DATE"
                            v-model="form.INV_DATE"
                        />
                        <error-message :error="errors.INV_DATE"></error-message>
                    </div>
                    <!-- 支払期限 -->
                    <div class="col-2">
                        <label for="LMT_DATE" class="form-label">{{
                            dg.SalTInvoice.LMT_DATE
                        }}</label>
                        <input
                            type="date"
                            name="LMT_DATE"
                            class="form-control form-control-sm"
                            id="LMT_DATE"
                            v-model="form.LMT_DATE"
                        />
                        <error-message :error="errors.LMT_DATE"></error-message>
                    </div>
                    <!-- 請求方法 -->
                    <div class="col-2">
                        <label for="RECE_CODE" class="form-label">{{
                            dg.SalTSales.RECE_CODE
                        }}</label>
                        <select
                            name="RECE_CODE"
                            class="form-select form-select-sm"
                            id="RECE_CODE"
                            v-model="form.RECE_CODE"
                        >
                            <option
                                v-for="(receCode, key) in Object.keys(
                                    receCodeList
                                ).sort()"
                                :key="key"
                                :value="receCode"
                            >
                                {{ receCodeList[receCode] }}
                            </option>
                        </select>
                        <error-message
                            :error="errors.RECE_CODE"
                        ></error-message>
                    </div>
                    <!-- 入金日 -->
                    <div class="col-2">
                        <label for="PAY_DATE" class="form-label">{{
                            dg.SalTInvoice.PAY_DATE
                        }}</label>
                        <input
                            type="date"
                            name="PAY_DATE"
                            class="form-control form-control-sm"
                            id="PAY_DATE"
                            v-model="form.PAY_DATE"
                        />
                        <error-message :error="errors.PAY_DATE"></error-message>
                    </div>
                    <!-- 入金方法 -->
                    <div class="col-1">
                        <label for="PAY_KIND" class="form-label">{{
                            dg.SalTInvoice.PAY_KIND
                        }}</label>
                        <select
                            name="PAY_KIND"
                            class="form-select form-select-sm"
                            id="PAY_KIND"
                            v-model="form.PAY_KIND"
                        >
                            <option :value="null"></option>
                            <option
                                v-for="(payKind, key) in Object.keys(
                                    payKindList
                                ).sort()"
                                :key="key"
                                :value="payKind"
                            >
                                {{ payKindList[payKind] }}
                            </option>
                        </select>
                        <error-message :error="errors.PAY_KIND"></error-message>
                    </div>
                </div>
                <div class="row mb-2">
                    <!-- 顧客番号と検索ボタン -->
                    <input-kokyk-id-with-button
                        v-model="form.KOKYK_ID"
                        @set-customer="setCustomerInfo"
                        :dg="dg"
                        :del-flag="delFlag"
                        :errors="errors"
                        :syzk-list="syzkList"
                    ></input-kokyk-id-with-button>
                    <!-- 顧客名 -->
                    <div class="col-2">
                        <label for="CLNIC_NM" class="form-label">{{
                            dg.MKokykLic.CLNIC_NM
                        }}</label>
                        <input
                            type="text"
                            name="CLNIC_NM"
                            class="form-control form-control-sm"
                            v-model="form.CLNIC_NM"
                        />
                        <error-message :error="errors.CLNIC_NM"></error-message>
                    </div>
                    <!-- 請求名称 -->
                    <div class="col-2">
                        <label for="INV_NM" class="form-label">{{
                            dg.SalTInvoice.INV_NM
                        }}</label>
                        <input
                            type="text"
                            name="INV_NM"
                            class="form-control form-control-sm"
                            id="INV_NM"
                            v-model="form.INV_NM"
                        />
                        <error-message :error="errors.INV_NM"></error-message>
                    </div>
                </div>
                <div class="row mb-2">
                    <!-- 備考1 -->
                    <div class="col-6">
                        <label for="INV_NOTE1" class="form-label">{{
                            dg.SalTInvoice.INV_NOTE1
                        }}</label>
                        <span class="form-text ms-1">{{
                            dg.SalTInvoice.INV_NOTE1_help
                        }}</span>
                        <textarea
                            name="INV_NOTE1"
                            class="form-control form-control-sm"
                            id="INV_NOTE1"
                            v-model="form.INV_NOTE1"
                            rows="5"
                        ></textarea>
                    </div>
                    <!-- 備考2 -->
                    <div class="col-6">
                        <label for="INV_NOTE2" class="form-label">{{
                            dg.SalTInvoice.INV_NOTE2
                        }}</label>
                        <span class="form-text ms-1">{{
                            dg.SalTInvoice.INV_NOTE2_help
                        }}</span>
                        <textarea
                            name="INV_NOTE2"
                            class="form-control form-control-sm"
                            id="INV_NOTE2"
                            v-model="form.INV_NOTE2"
                            rows="5"
                        ></textarea>
                    </div>
                </div>
                <div class="mb-2 row">
                    <!-- 請求書PDF -->
                    <div class="col-6">
                        <label for="pdf" class="form-label">{{
                            dg.SalTInvoice.PDF
                        }}</label>
                        <ul>
                            <li v-if="form.PDF">
                                <a
                                    :href="`${documentRoot}/storage/invoice/${form.PDF}`"
                                    :download="`${form.PDF_NAME}`"
                                    >{{ form.PDF_NAME }}</a
                                >
                            </li>
                        </ul>
                        <!-- 領収書PDFは本番リリース保留 -->
                        <!-- <label for="pdf" class="form-label">{{
                            dg.SalTInvoice.PDF_RECEIPT
                        }}</label>
                        <ul>
                            <li v-if="form.PDF_RECEIPT">
                                <a
                                    :href="`${documentRoot}/storage/invoice/receipt/${form.PDF_RECEIPT}`"
                                    :download="`${form.PDF_RECEIPT_NAME}`"
                                    >{{ form.PDF_RECEIPT_NAME }}</a
                                >
                            </li>
                        </ul> -->
                    </div>
                    <div class="col-6">
                        <!-- ファイル -->
                        <file-upload
                            v-model="form"
                            v-model:filesModelValue="files"
                            :dg="dg"
                            :max-file-count="5"
                            :slice-num="25"
                            :document-root="documentRoot"
                        ></file-upload>
                    </div>
                </div>
                <div class="table-responsive">
                    <table class="table table-sm mb-0">
                        <thead>
                            <tr>
                                <th class="text-center" style="width: 1rem">
                                    {{ dg.SequentialNumber }}
                                </th>
                                <th class="text-center" style="width: 10rem">
                                    {{ dg.SalTInvoiceDtl.PRODUCT_CODE }}
                                </th>
                                <th class="text-center" style="width: 10rem">
                                    {{ dg.SalTInvoiceDtl.PRODUCT_NAME }}
                                </th>
                                <th
                                    class="text-center"
                                    style="
                                        width: 5rem;
                                        letter-spacing: 0.5rem;
                                        text-indent: 0.5rem;
                                    "
                                >
                                    {{ dg.SalTInvoiceDtl.UPRICE }}
                                </th>
                                <th class="text-center" style="width: 3rem">
                                    {{ dg.SalTInvoiceDtl.CNT }}
                                </th>
                                <th
                                    class="text-center"
                                    style="
                                        width: 6rem;
                                        letter-spacing: 0.5rem;
                                        text-indent: 0.5rem;
                                    "
                                >
                                    {{ dg.SalTInvoiceDtl.PRICE }}
                                </th>
                                <th class="text-center" style="width: 6rem">
                                    {{ dg.SalTInvoiceDtl.SALES_NO }}
                                </th>
                                <th class="text-center" style="width: 4rem">
                                    {{ dg.SalTInvoiceDtl.CONT_START }}
                                </th>
                                <th class="text-center" style="width: 4rem">
                                    {{ dg.SalTInvoiceDtl.CONT_PERIOD }}
                                </th>
                                <th class="text-center" style="width: 15rem">
                                    {{ dg.SalTInvoiceDtl.INV_DNOTE1
                                    }}<span class="form-text fw-normal ms-1">{{
                                        dg.SalTInvoiceDtl.INV_DNOTE1_help
                                    }}</span>
                                </th>
                                <th class="text-center" style="width: 14rem">
                                    {{ dg.SalTInvoiceDtl.INV_DNOTE2
                                    }}<span class="form-text fw-normal ms-1">{{
                                        dg.SalTInvoiceDtl.INV_DNOTE2_help
                                    }}</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <invoice-detail-input
                                ref="detail"
                                v-for="(detail, index) in form.salTInvoiceDtls"
                                :key="index"
                                v-model="form.salTInvoiceDtls[index]"
                                :dg="dg"
                                :documentRoot="documentRoot"
                                :index="index"
                                :product-obj-list="productObjList"
                                @update:price="calcTotalPrice"
                                @splice-row="spliceRow"
                            >
                            </invoice-detail-input>
                            <tr>
                                <!-- 小計 -->
                                <th colspan="3" class="border-bottom-0"></th>
                                <th colspan="2" class="border-bottom-0">
                                    {{ dg.SUBTOTAL }}
                                </th>
                                <th class="text-end border-bottom-0">
                                    &yen;{{
                                        Number(form.PRICE).toLocaleString()
                                    }}
                                </th>
                                <th colspan="2" class="border-bottom-0"></th>
                            </tr>
                            <tr>
                                <!-- 消費税 -->
                                <th colspan="3" class="border-bottom-0"></th>
                                <th colspan="2" class="border-bottom-0">
                                    {{ dg.SalTInvoice.TAX }}
                                </th>
                                <th class="text-end border-bottom-0">
                                    &yen;{{ Number(form.TAX).toLocaleString() }}
                                </th>
                                <th colspan="2" class="border-bottom-0"></th>
                            </tr>
                            <tr>
                                <!-- 合計 -->
                                <th colspan="3" class="border-bottom-0"></th>
                                <th colspan="2" class="border-bottom-0">
                                    {{ dg.SalTInvoice.TPRICE }}
                                </th>
                                <th class="text-end border-bottom-0">
                                    &yen;{{
                                        Number(form.TPRICE).toLocaleString()
                                    }}
                                </th>
                                <th colspan="2" class="border-bottom-0"></th>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div class="d-grid gap-2 d-md-flex justify-content-md-end">
            <!-- コピーボタン -->
            <copy-button
                v-if="edit"
                :alert-flag="changed"
                :alert-message="dg.changedMessage"
                :confirm-message="`請求番号${
                    this.form.INV_NO +
                    '-' +
                    this.form.ENO +
                    dg.copyConfirmMessage
                }`"
                :url="`${documentRoot}/invoice/${form.ID}/copy`"
            ></copy-button>
            <button
                type="button"
                class="btn btn-danger"
                v-if="form.ID"
                @click="destroy"
            >
                {{ dg.Delete }}
            </button>
            <button
                type="button"
                class="btn btn-primary"
                v-if="form.ID"
                @click="print"
            >
                {{ dg.print }}
            </button>
            <!-- 領収書PDFは本番リリース保留 -->
            <!-- <button
                type="button"
                class="btn btn-primary"
                v-if="form.ID"
                @click="printReceipt"
            >
                {{ dg.printReceipt }}
            </button> -->
            <!-- 中止ボタン -->
            <cancel-button
                :confirm-flag="changed"
                :confirm-message="dg.cancelMessage"
                :url="`${this.documentRoot}/invoice`"
                >{{ dg.cancel }}</cancel-button
            >
            <button type="button" class="btn btn-primary" @click="register">
                {{ dg.register }}
            </button>
        </div>
    </form>

    <yes-no-modal2
        ref="yesNoModal"
        :message="dg.isStampRequired"
    ></yes-no-modal2>

    <yes-no-modal2
        ref="confirmSaveChangeModal"
        :message="dg.changedPrintMessage"
    ></yes-no-modal2>

    <yes-no-modal2 ref="yesNoModal2" :message="message"></yes-no-modal2>
    <yes-no-modal3
        ref="yesNoModal3"
        :title="dg.register"
        :yes-button="dg.SalTInvoice.update"
        :no-button="dg.SalTInvoice.reIssue"
    ></yes-no-modal3>
</template>

<script>
import moment from "moment";
import CancelButton from "../buttons/CancelButton.vue";
import CopyButton from "../buttons/CopyButton.vue";
import FileUpload from "../inputs/FileUpload.vue";
import InvoiceDetailInput from "./InvoiceDetailInput.vue";
import InputKokykIdWithButton from "../inputs/InputKokykIdWithButton.vue";
import ModalDialogUpdateEno from "../modals/ModalDialogUpdateEno.vue";
import ModalDialogDate from "../ModalDialogDate.vue";
import YesNoModal2 from "../modals/YesNoModal2.vue";
import YesNoModal3 from "../modals/YesNoModal3.vue";

export default {
    name: "InvoiceInput",
    components: {
        CancelButton,
        CopyButton,
        FileUpload,
        InputKokykIdWithButton,
        InvoiceDetailInput,
        ModalDialogUpdateEno,
        ModalDialogDate,
        YesNoModal2,
        YesNoModal3,
    },
    props: {
        data: Object,
        delFlag: Object,
        dg: Object,
        documentRoot: String,
        invoiceStatusList: Object,
        multiple: Boolean,
        payKindList: Object,
        productObjList: Object,
        receCodeList: Object,
        syzkList: Object,
        rakkuinCodeList: Object,
    },
    data() {
        return {
            form: {
                PRICE: 0,
                TAX: 0,
                TPRICE: 0,
                ISSUE_LICENSE: 0,
            },
            files: [],
            edit: false,
            changed: false,
            errors: {},
            oldStatus: "",
            message: "",
        };
    },
    created() {
        this.form = this.data;
        this.form.ENO = this.form.ENO ?? 1;
        this.form.INV_DATE =
            this.form.INV_DATE ?? moment().format("YYYY-MM-DD");
        this.form.LMT_DATE =
            this.form.LMT_DATE ?? this.calcLmtDate(this.form.INV_DATE);
        this.form.STATUS = this.form.STATUS ?? 0;
        // ファイル名の後ろに設定したランダムな文字列は表示しないよう設定（ 参照： invoice/PdfAction.php ）
        this.form.PDF_NAME = this.form.PDF
            ? this.form.PDF.replace(/-[^-]{20}\.pdf$/g, ".pdf")
            : "";
        // ファイル名の後ろに設定したランダムな文字列は表示しないよう設定（ 参照： invoice/PdfReceiptAction.php ）
        this.form.PDF_RECEIPT_NAME = this.form.PDF_RECEIPT
            ? this.form.PDF_RECEIPT.replace(/-[^-]{20}\.pdf$/g, ".pdf")
            : "";
        this.setSalTInvoiceDtls();
        this.calcTotalPrice();
        this.edit = this.data.ID ? true : false;
        this.oldStatus = this.form.STATUS;
    },
    mounted() {
        const unwatch = this.$watch(
            "form",
            () => {
                this.changed = true;
                unwatch();
            },
            { deep: true }
        );
    },
    methods: {
        async handlePrintClick() {
            // 社印ダイアログ
            try {
                const response = await this.$refs.yesNoModal.action();
                this.print(response);
            } catch (e) {
                this.print(e);
            }
        },
        spliceRow(index) {
            this.form.salTInvoiceDtls.splice(index, 1);

            const keys = [
                "RNO",
                "PRODUCT_CODE",
                "PRODUCT_NAME",
                "UPRICE",
                "CNT",
                "PRICE",
                "SALES_NO",
                "CONT_START",
                "CONT_PERIOD",
                "INV_DNOTE1",
                "INV_DNOTE2",
            ];
            const defaultDetail = keys.reduce(
                (obj, key) => ({ ...obj, [key]: "" }),
                {}
            );
            this.form.salTInvoiceDtls.push({ ...defaultDetail });
        },
        filterValidRow() {
            const checKeys = [
                "PRODUCT_CODE",
                "PRODUCT_NAME",
                "UPRICE",
                "CNT",
                "SALES_NO",
                "INV_DNOTE1",
                "INV_DNOTE2",
            ];
            let row = 0;

            while (row < this.form.salTInvoiceDtls.length) {
                const isAllBlank = checKeys.every(
                    (check) => !this.form.salTInvoiceDtls[row][check]
                );
                if (isAllBlank) {
                    break;
                }
                row++;
            }

            return row || 1;
        },
        setSalTInvoiceDtls() {
            let length = this.data.salTInvoiceDtls.length;

            const keys = [
                "RNO",
                "PRODUCT_CODE",
                "PRODUCT_NAME",
                "UPRICE",
                "CNT",
                "PRICE",
                "SALES_NO",
                "CONT_START",
                "CONT_PERIOD",
                "INV_DNOTE1",
                "INV_DNOTE2",
            ];
            const defaultDetail = keys.reduce(
                (obj, key) => ({ ...obj, [key]: "" }),
                {}
            );

            while (length < 10) {
                length = this.form.salTInvoiceDtls.push({ ...defaultDetail });
            }
        },
        getFormData() {
            const formData = new FormData(this.$refs.form);
            let data = this.makeData();

            Object.keys(data).forEach(function (key) {
                if (key == "salTInvoiceDtls") {
                    data[key].forEach((item, index) => {
                        Object.keys(item).forEach(function (i) {
                            if (item[i] == null) {
                                formData.set(
                                    `salTInvoiceDtls[${index}][${i}]`,
                                    ""
                                );
                            } else {
                                formData.set(
                                    `salTInvoiceDtls[${index}][${i}]`,
                                    item[i]
                                );
                            }
                        });
                    });
                } else if (data[key] == null) {
                    formData.set(key, "");
                } else {
                    formData.set(key, data[key]);
                }
            });

            formData.set("FILE1", "");
            formData.set("FILE2", "");
            formData.set("FILE3", "");
            formData.set("FILE4", "");
            formData.set("FILE5", "");

            for (var i = 0; i < this.files.length; i++) {
                if (this.files[i].file) {
                    formData.set("FILE" + (i + 1), this.files[i].file);
                } else {
                    formData.set("FILE" + (i + 1), this.files[i].name);
                }
            }

            return formData;
        },

        makeData() {
            let data = Object.assign({}, this.form);
            data.salTInvoiceDtls = data.salTInvoiceDtls.slice(
                0,
                this.filterValidRow()
            );

            return data;
        },
        async shouldIssueRakkuinLicense() {
            // 状態が「未入金・未請求」から「終了」　以外は、リターン
            if (this.oldStatus === 9 || this.form.STATUS !== 9) {
                return 0;
            }

            // らっく院対象コードがなければ、リターン
            const rakkuinCodes = Object.keys(this.rakkuinCodeList);
            const rakkuin = this.form.salTInvoiceDtls.find(({ PRODUCT_CODE }) =>
                rakkuinCodes.includes(PRODUCT_CODE)
            );
            if (!rakkuin) {
                return 0;
            }

            const licenseStartDate = moment(rakkuin.CONT_START).format(
                "YYYY/MM/DD"
            );
            const licenseEndDate = moment(rakkuin.CONT_START)
                .add(11, "M")
                .endOf("month")
                .format("YYYY/MM/DD");

            this.message = `${licenseStartDate}～${licenseEndDate}のライセンスを発行します。よろしいですか`;
            return await this.$refs.yesNoModal2.action();
        },
        /**
         * 登録ボタンクリック時
         */
        async register() {
            try {
                const shouldUpdate = this.edit
                    ? await this.$refs.yesNoModal3.action(undefined, "")
                    : 0;
                this.form.ISSUE_LICENSE =
                    await this.shouldIssueRakkuinLicense();
                const res = shouldUpdate
                    ? await this._updateAxios()
                    : await this._createAxios();

                location.href = res.data;
            } catch (error) {
                if (error?.response?.status === 422) {
                    this.errors = error.response.data.errors;
                } else if (error?.response?.status === 500) {
                    const status = error.response.status;
                    window.alert(this.dg.internal_error);
                } else {
                    console.log(error);
                }
            }
        },
        async print() {
            try {
                let saveChange = false;
                // 変更されている場合
                if (this.changed) {
                    //「変更を保存しますか？」のダイアログ表示
                    saveChange =
                        await this.$refs.confirmSaveChangeModal.action();
                    // 「いいえ」の場合、処理を中断
                    if (!saveChange) {
                        throw "saveChange: NO selected.";
                    }
                }
                // 「社印を押しますか？」のダイアログ表示
                const isStamp = await this.$refs.yesNoModal.action();

                if (saveChange) {
                    // 更新処理
                    await this._updateAxios();
                }
                // 印刷処理
                const res = await this._printAxios(isStamp);
                // 画面更新
                location.href = res.data;
            } catch (error) {
                if (error?.response?.status == 422) {
                    this.errors = error.response.data.errors;
                } else {
                    console.error(error);
                }
            }
        },
        async printReceipt() {
            try {
                let saveChange = false;
                // 変更されている場合
                if (this.changed) {
                    //「変更を保存しますか？」のダイアログ表示
                    saveChange =
                        await this.$refs.confirmSaveChangeModal.action();
                    // 「いいえ」の場合、処理を中断
                    if (!saveChange) {
                        throw "saveChange: NO selected.";
                    }
                }
                // 「社印を押しますか？」のダイアログ表示
                const isStamp = await this.$refs.yesNoModal.action();

                if (saveChange) {
                    // 更新処理
                    await this._updateAxios();
                }
                // 印刷処理
                const res = await this._printReceiptAxios(isStamp);
                // 画面更新
                location.href = res.data;
            } catch (error) {
                if (error?.response?.status == 422) {
                    this.errors = error.response.data.errors;
                } else {
                    console.error(error);
                }
            }
        },
        async destroy() {
            try {
                if (window.confirm(this.dg.deleteConfirmMessage)) {
                    const res = await axios.delete(`/invoice/${this.form.ID}`);
                    location.href = res.data;
                }
            } catch (error) {
                console.error(error);
            }
        },
        calcTotalPrice() {
            const price = this.form.salTInvoiceDtls.reduce((acc, cur) => {
                return acc + Number(cur.PRICE);
            }, 0);

            this.form.PRICE = price;
            this.form.TAX = Math.round(price * 0.1);
            this.form.TPRICE = Math.round(price * 1.1);
        },
        setCustomerInfo(mKokykLic) {
            const keys = [
                "KOKYK_ID",
                "CLNIC_NM",
                "INCHO_NM",
                "ZIP_CD",
                "ADR",
                "TEL",
            ];

            for (const key of keys) {
                this.form[key] = mKokykLic[key];
            }
        },
        calcLmtDate(invDate) {
            return moment(invDate).format("D") <= 15
                ? moment(invDate).endOf("M").format("YYYY-MM-DD")
                : moment(invDate).add(1, "M").format("YYYY-MM-15");
        },
        _printAxios(isStamp) {
            return axios.post(`/invoice/${this.data.ID}/pdf`, {
                is_stamp: isStamp,
            });
        },
        _printReceiptAxios(isStamp) {
            return axios.post(`/invoice/${this.data.ID}/pdfReceipt`, {
                is_stamp: isStamp,
            });
        },
        _createAxios() {
            return axios.post(`/invoice`, this.getFormData(), {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
        },
        _updateAxios() {
            return axios.post(`/invoice/${this.data.ID}`, this.getFormData(), {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "X-HTTP-Method-Override": "PUT",
                },
            });
        },
    },
};
</script>
