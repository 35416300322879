<template>
    <form ref="form" @submit.prevent="getItems()" class="mb-2">
        <div class="row">
            <div class="col-1">
                <div class="row mb-2">
                    <div class="d-grid gap-2 d-md-flex justify-content-md-start">
                        <a href="#" class="btn btn-primary btn-sm" @click.prevent.stop="create()">{{ dg.stock.create }}</a>
                    </div>
                </div>
            </div>
            <div class="col-11">
                <div class="card">
                    <div class="card-body">
                        <div class="card-text row">
                            <!-- 開始日 -->
                            <input-date
                                style="width: 150px;"
                                v-model="form.START_DAY"
                                :error="errors.START_DAY"
                                :label-attr="{ for: 'START_DAY', title: dg.START_DAY }"
                            ></input-date>
                            <!-- 終了日 -->
                            <input-date
                                style="width: 150px;"
                                v-model="form.END_DAY"
                                :error="errors.END_DAY"
                                :label-attr="{ for: 'END_DAY', title: dg.END_DAY }"
                            ></input-date>
                            <div class="col-1">
                                <label for="KOKYK_ID" class="form-label">{{ dg.KOKYK_ID }}</label>
                                <input-kokyk-id
                                    v-model="form.KOKYK_ID"
                                    @set-customer="setCustomerInfo"
                                ></input-kokyk-id>
                                <error-message :error="errors.KOKYK_ID"></error-message>
                            </div>
                            <div class="col-2">
                                <label for="CLNIC_NM" class="form-label">{{ dg.MKokykLic.CLNIC_NM }}</label>
                                <input type="text" class="form-control form-control-sm" v-model="form.CLNIC_NM">
                                <error-message :error="errors.CLNIC_NM"></error-message>
                            </div>
                            <!-- 状態 -->
                            <div class="col-2" style="z-index: 1029;">
                                <label for="STATUS" class="form-label">{{ dg.SalTSales.STATUS }}</label>
                                <VueMultiselect
                                    v-model="form.STATUS"
                                    :custom-label="setStatusLabel"
                                    :options="statusListArray"
                                    label="name"
                                    :close-on-select="false"
                                    :taggable="false"
                                    :multiple="true"
                                    :searchable="false"
                                    placeholder="未選択"
                                    selectLabel="選択"
                                    selectedLabel="選択済"
                                    deselectLabel="解除"
                                    :limitText="count => `+${count}`"
                                    :limit="1"
                                >
                                </VueMultiselect>
                                <error-message :error="errors.STATUS"></error-message>
                            </div>
                            <div class="col-2 d-flex gap-1 align-items-start" style="margin-top: 23px">
                                <button type="button" class="btn btn-secondary btn-sm" @click="reset()">{{ dg.Reset }}</button>
                                <button type="submit" class="btn btn-primary btn-sm">{{ dg.Search }}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <stock-index-table ref="table"
        v-model="items"
        :dg="dg"
        divHeight="700px"
        :stock-status-list="stockStatusList"
        :document-root="documentRoot"
        :session-name="sessionName"
        :sort="sort"
        @handleTableScroll="handleTableScroll"    
    ></stock-index-table>
    <div class="mt-2">
        <csv-button
            :button-name="dg.csv"
            file-name='仕入一覧.csv'
            :ids=ids
            url='/stock/csv'
        ></csv-button>
        <csv-button
            class="ms-2"
            :button-name="`経理csv`"
            file-name='仕入経理.txt'
            :ids=ids
            url='/stock/csv/account'
        ></csv-button>
    </div>
</template>

<script>
import moment from "moment";
import Mixin from '../mixins/mixin'
import CsvButton from '../CsvButton.vue';
import StockIndexTable from './StockIndexTable.vue';
import InputDate from '../inputs/InputDate.vue';
import InputKokykId from '../inputs/InputKokykId.vue';
import VueMultiselect from 'vue-multiselect';

export default {
    name: 'StockIndex',
    mixins: [Mixin],
    components: {
        CsvButton,
        InputDate,
        InputKokykId,
        StockIndexTable,
        VueMultiselect,
    },
    props: {
        dg: Object,
        documentRoot: String,
        stockStatusList: Object,
    },
    data() {
        return {
            form: {},
            items: [],
            errors: {},
            sort: {},
            sessionName: 'stockIndex',
        }
    },
    async created() {
        // プロパティ初期化
        this.init();
        // セッションの操作
        this.sessionHandler();
        // 一覧の取得
        await this.getItems();
        // 指定値までスクロール
        this.$refs.table.scroll(this.scroll);
    },
    methods: {
        sessionHandler() {
            const sessionData = this.getSession(this.sessionName);
            const sessionDataKeys = ['form', 'scroll', 'sort'];
            
            // セッションのデータキーを順に確認し、
            sessionDataKeys.forEach(key => {
                // キーが存在する場合、セッションから代入
                if (key in sessionData) {
                    this[key] = sessionData[key]
                // キーが存在しない場合、セッションに値を保存
                } else {
                    this.setSession(this.sessionName, { [key]: this[key] })
                }
            });
        },
        handleTableScroll (e) {
            this.setSession(this.sessionName, { scroll: e.target.scrollTop } );
        },
        init() {
            // 検索条件
            this.form = {
                START_DAY   : moment().subtract(3, 'M').format("YYYY-04-01"),
                END_DAY     : moment().subtract(3, 'M').add(1, 'y').format("YYYY-03-31"),
                KOKYK_ID    : '',
                CLNIC_NM    : '',
                STATUS      : '',
            }
            // スクロール量
            this.scroll = 0;
            // ソート
            this.sort = {
                key: 'STOCK_DATE',
                asc: false,
            }
        },
        handleScroll (e) {
            this.setSession(this.sessionName.scroll, e.target.scrollTop);
        },
        setCustomerInfo(mKokykLic) {
            const keys = ['KOKYK_ID', 'CLNIC_NM'];

            for (const key of keys) {
                this.form[key] = mKokykLic[key];
            }
        },
        getItems() {
            this.errors = {};
            let params = new URLSearchParams(location.search);
            
            if (params.get('SALES_NO')) {
                this.form.SALES_NO = params.get('SALES_NO');
                this.form.START_DAY = '';
                this.form.END_DAY = '';

                const url = new URL(window.location);
                url.search = '';
                history.pushState({}, '', url.href);
            }
            return axios.get(`/stock/search`, { params: this.form })
                .then(res => {
                    this.items = res.data.data;
                    // 検索条件をセッション保存
                    this.setSession(this.sessionName, { form: this.form } );

                    if(this.form.SALES_NO) {
                        this.form.SALES_NO = '';
                    }
                })
                .then(() => {
                    this.$refs.table.init();
                })
                .catch((error) => {
                    if (error.response.status === 422) {
                        this.errors = error.response.data.errors;
                    } else if (error.response.status === 500) {
                        const status = error.response.status;
                        window.alert(this.dg.internal_error);
                    }
                });
        },
        create() {
            location.href = (`${this.documentRoot}/stock/create`);
        },
        reset() {
            this.init();
            this.$refs.table.init();
            this.getItems();
        },
        setProductLabel(code) {
            return `${code}/${this.productList[code]}`;
        },
        setStatusLabel(code) {
            return `${this.stockStatusList[code]}`;
        }
    },
    computed: {
        ids() {
            return this.items.map(item => item.STOCK_NO);
        },
        statusListArray() {
            return Object.keys(this.stockStatusList);
        },
    }
}
</script>