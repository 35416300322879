<template>
    <slot
        name="default"
        :setCnt="setCnt"
        :handleProductCodeChange="handleProductCodeChange"
    ></slot>
</template>
<script>
export default {
    name: "ProductSelectSlot",
    props: {
        modelValue: Object,
        dg: Object,
        index: Number,
        productObjList: Array,
        clearColumnList: Array,
    },
    emits: ["update:price", "update:model-value", "spliceRow"],
    mounted() {
        this.modelVal.RNO = this.index + 1;
    },
    methods: {
        calcPrice() {
            if (this.modelVal.UPRICE === "" || this.modelVal.CNT === "") {
                this.modelVal.PRICE = "";
                return;
            }
            this.modelVal.PRICE =
                this.modelVal.UPRICE * this.modelVal.CNT ?? "";
        },
        setCnt() {
            // 数量が未入力か単価が入力済なら1を入れる
            this.modelVal.CNT =
                !this.modelVal.CNT && String(this.modelVal.UPRICE)
                    ? 1
                    : this.modelVal.CNT;
        },
        rowHandler(newVal) {
            if (!newVal) {
                this.$emit("spliceRow", this.index);
            }
        },
        // 商品コードが変更された場合、単価と商品名をマスタデータから取得し初期化する。
        handleProductCodeChange() {
            // 選択->空白（商品が削除された場合）
            if (this.modelVal.PRODUCT_CODE === null) {
                this.$emit("spliceRow", this.index);
                return;
            }

            // 商品が変更された場合
            const targetObj = this.productObjList.find(
                (elm) => elm.PRODUCT_CODE === this.modelVal.PRODUCT_CODE
            );

            this.modelVal.PRODUCT_NAME = targetObj?.DISP_NAME || "";
            this.modelVal.UPRICE = targetObj?.UPRICE ?? "";
        },
    },
    computed: {
        modelVal: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit("update:model-value", value);
            },
        },
    },
    watch: {
        // 商品が変更された場合、商品の単価と商品名をマスタデータから取得し初期化しているが、
        // 商品の変更の検知は handleProductCodeChange メソッドを PRODUCT_CODE の @change イベントに設定することで対応している。
        // ※ 商品の変更を @change イベントで検知している理由：
        // 商品を削除して spliceRow メソッドが動いたあとに商品が1行分上にずれると、
        // ずれた行も変更対象となってしまい、マスタデータから再取得されて単価と商品名が初期化されしまう。
        // 例えば、5行目の商品コードに「8190/その他周辺機器」を選択し、金額を「8,000」、商品名を「バーコードリーダー」に設定しているとき、
        // 5行目よりも上の行を削除すると、5行目は1行上にずれ、金額「0」、商品名「その他周辺機器」に初期化される。
        // この問題を防ぐため、商品の変更の検知は handleProductCodeChange メソッドを PRODUCT_CODE の @change イベントに設定することで対応している。
        // "modelVal.PRODUCT_CODE"(newVal, oldVal) {
        // if (newVal === null) {
        //     // 選択->空白
        //     this.$emit("spliceRow", this.index);
        // } else {
        //     const targetObj = this.productObjList.find(
        //         (elm) => elm.PRODUCT_CODE === newVal
        //     );
        //     this.modelVal.PRODUCT_NAME = targetObj?.DISP_NAME || "";
        //     this.modelVal.UPRICE = targetObj?.UPRICE ?? "";
        // }
        // },
        "modelVal.UPRICE"() {
            this.calcPrice();
        },
        "modelVal.CNT"() {
            this.calcPrice();
        },
        "modelVal.PRICE"() {
            this.$emit("update:price");
        },
        "modelVal.RNO"() {
            this.modelVal.RNO = this.index + 1;
        },
    },
};
</script>
