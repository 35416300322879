<template>
    <div>
        <label for="FILE" class="form-label">{{ dg.file }}</label>
        <ul v-for="(file, index) in filesModelVal" :key=index>
            <li>
                <a :href="file.url" :download="(file.displayName)">{{ file.displayName }}</a>
                <button type="button" @click="deleteFile(index)">{{ dg.Delete }}</button>
            </li>
        </ul>
        <input v-if="filesModelVal.length < maxFileCount" ref="fileupload" type="file" class="form-control form-control-sm" id="FILE" @change="fileSelected">
    </div>
</template>


<script>
export default {
    name: 'FileUpload',
    emits: ['update:model-value'],
    props: {
        dg: Object,
        documentRoot: String,
        filesModelValue: Array,
        maxFileCount: Number,
        modelValue: Object,
        sliceNum: Number,
    },
    data() {
        return {
        }
    },
    created() {
        this.setFiles();
    },
    methods: {
        setFiles() {
            let object = {};
            for (let i = 1; i <= this.maxFileCount; i++) {
                const fileName = eval(`this.modelVal.FILE${i}`);
                if(fileName) {
                    object = {
                        name: fileName,
                        displayName: fileName.slice(this.sliceNum),
                        url: this.documentRoot + '/storage/' + fileName
                    };
                    this.filesModelVal.push(object);
                }
            }
        },
        deleteFile(index) {
            this.filesModelVal.splice(index, 1);
        },
        fileSelected(e) {
            const file = e.target.files[0];
            const object = {
                url: URL.createObjectURL(file),
                name: file.name,
                displayName: file.name,
                file: file,
            };
            this.filesModelVal.push(object);

            this.$refs.fileupload.value = null;
        },
    },
    computed: {
        modelVal: {
            get() {
                return this.modelValue
            },
            set(value) {
                this.$emit('update:model-value', value)
            }
        },
        filesModelVal: {
            get() {
                return this.filesModelValue;
            },
            set(value) {
                this.$emit('update:filesModelValue', value)
            }
        },
    },
}
</script>