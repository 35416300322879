<template>
    <input type="text" :class="attr.class" :id="attr.id" v-model="modelVal">
</template>

<script>
export default {
    name: 'InputText',
    emits: ['update:model-value'],
    props: {
        modelValue: String,
        attr: {
            type    : Object,
            required: false,
            default : {
                class: 'form-control form-control-sm',
            }
        }
    },
    computed: {
        modelVal: {
            get() {
                return this.modelValue
            },
            set(value) {
                this.$emit('update:model-value', value)
            }
        }
    },
}
</script>
