<template>
    <slot name="default"
        :setIcon="setIcon"
        :sort="sort"
    ></slot>
</template>
<script>
import Mixin from '../mixins/mixin'

export default {
    name: 'SortTable',
    mixins: [Mixin],
    emits: ['update:form-model-value', 'update:model-value', 'fetch'],
    props: {
        modelValue: Array,
        formModelValue: Object,
    },
    created() {
    },
    methods: {
        setIcon(key) {
            return (this.form.sort_key === key) 
                ?   (this.form.sort_asc) ? 'fa-solid fa-sort-up' :'fa-solid fa-sort-down'
                :   'fa-solid fa-sort';
        },
        sort(sortKey) {
            this.form.sort_key = sortKey;
            this.form.sort_asc = (this.form.sort_key === sortKey) ? !this.form.sort_asc : true;
            this.$emit('fetch');
        },
    },
    computed: {
        items: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:model-value', value)
            }
        },
        form: {
            get() {
                return this.formModelValue;
            },
            set(value) {
                this.$emit('update:form-model-value', value)
            }
        }
    },
}
</script>