<template>
    <slot name="default"
        :sumPrice="sumPrice"
        :sumTax="sumTax" 
        :sumTPrice="sumTPrice"
    ></slot>
</template>

<script>
import Mixin from '../mixins/mixin'

export default {
    name: 'SumTableFooter',
    mixins: [Mixin],
    props: {
        items: Array,
    },
    computed: {
        sumPrice () {
            return this.items.reduce((acc, cur) => acc + Number(cur.PRICE), 0);
        },
        sumTax () {
            return this.items.reduce((acc, cur) => acc + Number(cur.TAX), 0);
        },
        sumTPrice () {
            return this.items.reduce((acc, cur) => acc + Number(cur.TPRICE), 0);
        },
    },
}
</script>