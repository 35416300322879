<template>
    <div class="position-fixed end-0 p-3" style="top: 3rem; z-index: 1000">
        <div class="toast bg-white" role="alert">
            <div class="toast-header">
                <strong class="me-auto">{{ title }}</strong>
                <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="toast"
                ></button>
            </div>
            <div class="toast-body">{{ body }}</div>
        </div>
    </div>
</template>

<script>
import { Toast } from "bootstrap";

export default {
    name: "Toast",
    props: {
        title: String,
        body: String,
    },
    mounted() {
        new Toast(this.$el.querySelector(".toast"));
    },
    methods: {
        show() {
            const toast = Toast.getInstance(this.$el.querySelector(".toast"));
            toast.show();
        },
    },
};
</script>
