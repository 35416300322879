<template>
    <button type="button" class="btn btn-primary btn-sm" @click="clicked"><slot></slot></button>

    <modal-customer ref="modal"
        :dg="dg"
        :del-flag="delFlag"
        :syzk-list="syzkList"
        @set-customer="setCustomer"
    ></modal-customer>

</template>

<script>
import ModalCustomer from '../modals/ModalCustomer.vue';
export default {
    name: 'CustomerSearchButton',
    components: {
        ModalCustomer,
    },
    props: {
        dg: Object,
        delFlag: Object,
        flag: Boolean,
        syzkList: Object,
    },
    emits: ['set-customer'],
    methods: {
        clicked() {
            this.$refs.modal.open();
        },
        setCustomer(customer) {
            this.$emit('set-customer', customer);
        }
    },
}
</script>
