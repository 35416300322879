<template>
    <div class="table-responsive" style="height: 420px; resize: vertical;">
        <sortable-table
            v-model="items"
            default-sort-key="KOKYK_ID"
            :default-sort-asc="true"
            :initialize="true"
        >
            <template #default="{setIcon, sort}">
                <table class="table table-hover table-sm mb-0 text-nowrap mb-0" style="table-layout: fixed">
                    <thead class="sticky-top bg-white">
                        <tr>
                            <th class="text-center" style="width: 40px">{{ dg.SequentialNumber }}</th>
                            <th class="text-center" style="width: 120px; cursor:pointer" @click="sort('INV_NO')">{{ dg.SalTInvoice.INV_NO + '/' + dg.ENO }}<font-awesome-icon :icon="setIcon('INV_NO')" /></th>
                            <th class="text-center" style="width: 60px">{{ dg.SalTInvoice.STATUS }}</th>
                            <th class="text-center" style="width: 80px; cursor:pointer" @click="sort('KOKYK_ID')">{{ dg.KOKYK_ID }}<font-awesome-icon :icon="setIcon('KOKYK_ID')" /></th>
                            <th class="text-center" style="width: 180px">{{ dg.SalTInvoice.CLNIC_NM }}</th>
                            <th class="text-center" style="width: 180px">{{ dg.SalTInvoice.INV_NM }}</th>
                            <th class="text-center" style="width: 40px">{{ dg.numRecord }}</th>
                            <th class="text-center" style="width: 80px">{{ dg.SalTInvoice.PRICE }}</th>
                            <th class="text-center" style="width: 70px">{{ dg.SalTInvoice.TAX }}</th>
                            <th class="text-center" style="width: 90px">{{ dg.SalTInvoice.TPRICE }}</th>
                            <th class="text-center" style="width: auto">{{ dg.SalTInvoice.INV_NOTE1 }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in items" :key="index">
                            <td>{{ index + 1 }}</td>
                            <td v-if="edit"><a
                                data-bs-toggle="tooltip"
                                data-bs-placement="bottom"
                                data-bs-html="true"
                                :data-bs-original-title="tooltip(index)" 
                                href="#" @click.prevent.stop="openInvoice(item.ID)">{{ item.INV_NO + '/' + item.ENO}}</a></td>
                            <td v-else><span
                                data-bs-toggle="tooltip"
                                data-bs-placement="bottom"
                                data-bs-html="true"
                                :data-bs-original-title="tooltip(index)" 
                                >{{ item.INV_NO + '/' + item.ENO }}</span></td>
                            <td>{{ invoiceStatusList[item.STATUS] }}</td>
                            <td>{{ item.KOKYK_ID }}</td>
                            <td>{{ strLimit(item.CLNIC_NM, 20) }}</td>
                            <td>{{ strLimit(item.INV_NM, 20) }}</td>
                            <td class="text-end">{{ item?.salTInvoiceDtls?.length }}</td>
                            <td class="text-end">{{ parseInt(item.PRICE)?.toLocaleString() ?? 0 }}</td>
                            <td class="text-end">{{ parseInt(item.TAX)?.toLocaleString() ?? 0 }}</td>
                            <td class="text-end">{{ parseInt(item.TPRICE)?.toLocaleString() ?? 0 }}</td>
                            <td>{{ strLimit(item.INV_NOTE1, 20) }}</td>
                        </tr>
                    </tbody>
                </table>
            </template>
        </sortable-table>
    </div>
    <table class="table table-borderless table-sm text-nowrap mb-0" style="table-layout: fixed">
        <thead>
            <tr>
                <th style="width: 40px"></th>
                <th style="width: 120px"></th>
                <th style="width: 60px"></th>
                <th style="width: 80px"></th>
                <th style="width: 180px"></th>
                <th style="width: 180px"></th>
                <th style="width: 40px"></th>
                <th style="width: 80px"></th>
                <th style="width: 70px"></th>
                <th style="width: 90px"></th>
                <th style="width: 120px"></th>
                <th style="width: 90px"></th>
                <th style="width: 150px"></th>
                <th style="width: auto"></th>
                <th style="width: 16px"> </th>
            </tr>
        </thead>
        <sum-table-footer :items="items">
            <template #default="{sumPrice, sumTax, sumTPrice}">
                <tfoot class="sticky-bottom bg-white fw-bold">
                    <tr>
                        <td colspan="5"></td>
                        <!-- 件数 --><!-- 小計 -->
                        <td colspan="2">{{ dg.numRecord }}<span class="ms-5">{{ items?.length?.toLocaleString() ?? 0 }}</span></td>
                        <td colspan="2" class="ps-3" style="widtd: 4rem; letter-spacing: 1.5rem;">{{ dg.price }}</td>
                        <td class="text-end">&yen;{{ sumPrice?.toLocaleString() ?? 0 }}</td>
                        <template v-if="edit">
                            <td class="text-end">初期費用 金額</td>
                            <td class="text-end">&yen;{{ digitSalesDtls.price?.toLocaleString() ?? 0 }}</td>
                            <td class="text-end"></td>
                            <td class="text-end"></td>
                        </template>
                    </tr>
                    <tr>
                        <!-- 消費税 -->
                        <td colspan="7"></td>
                        <td colspan="2" class="ps-3">{{ dg.tax }}</td>
                        <td class="text-end">&yen;{{ sumTax?.toLocaleString() ?? 0 }}</td>
                        <template v-if="edit">
                            <td class="text-end">消費税</td>
                            <td class="text-end">&yen;{{ digitSalesDtls.taxes?.toLocaleString() ?? 0 }}</td>
                            <td class="text-end">{{ $parent.data.RECE_NAME }}集金額</td>
                            <td class="text-end">&yen;{{ agentInvDtls.tPrice?.toLocaleString() ?? 0 }}</td>
                        </template>
                    </tr>
                    <tr>
                        <!-- 合計金額 -->
                        <td colspan="7"></td>
                        <td colspan="2" class="ps-3">{{ dg.tPrice }}</td>
                        <td class="text-end">&yen;{{ sumTPrice?.toLocaleString() ?? 0 }}</td>
                        <template v-if="edit">
                            <td class="text-end">合計金額</td>
                            <td class="text-end">&yen;{{ digitSalesDtls.tPrice?.toLocaleString() ?? 0 }}</td>
                            <td class="text-end">手数料合計</td>
                            <td class="text-end">&yen;{{ (agentInvDtls.commission + digitSalesDtls.commission)?.toLocaleString() ?? 0 }}</td>
                        </template>
                    </tr>
                    <tr v-if="edit">
                        <!-- 合計金額 -->
                        <td colspan="7"></td>
                        <td colspan="2" class="ps-3">手数料</td>
                        <td class="text-end">&yen;{{ agentInvDtls.commission?.toLocaleString() ?? 0 }}</td>
                        <td class="text-end">手数料</td>
                        <td class="text-end">&yen;{{ digitSalesDtls.commission?.toLocaleString() ?? 0 }}</td>
                        <td class="text-end">請求額</td>
                        <td class="text-end">&yen;{{ ((agentInvDtls.tPrice ?? 0) - (agentInvDtls.commission ?? 0) - (digitSalesDtls.commission ?? 0))?.toLocaleString() ?? 0 }}</td>
                    </tr>
                </tfoot>
            </template>
        </sum-table-footer>
    </table>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { Tooltip } from "bootstrap";
import Mixin from '../mixins/mixin'
import SumTableFooter from '../tables/SumTableFooter.vue';
import SortableTable from '../tables/SortableTable.vue';

export default {
    name: 'DrawInputTable',
    mixins: [Mixin],
    emits: ['print', 'update:model-value'],
    components: {
        FontAwesomeIcon,
        SumTableFooter,
        SortableTable,
    },
    props: {
        modelValue: Array,
        dg: Object,
        documentRoot: String,
        edit: Boolean,
        invoiceStatusList: Object,
        digitSalesDtls: Object,
        agentInvDtls: Object,
    },
    data() {
        return {
            toggle: false,
            tooltips: [],
        }
    },
    mounted() {
        new Tooltip(document.body, {
            selector: "[data-bs-toggle='tooltip']",
            trigger : 'hover'
        });
    },
    methods: {
        tooltip(i) {
            return this.items[i].salTInvoiceDtls.reduce(( acc, cur, index) => {
                if (index !== 0) {
                    acc += `<br>---<br>`;
                }

                acc += `商品名:${cur.PRODUCT_NAME}<br>単価:&yen;${cur?.UPRICE?.toLocaleString() ?? 0}<br>数量:${cur.CNT}<br>金額:&yen;${cur?.PRICE?.toLocaleString() ?? 0}<br>関連販売番号:${cur.SALES_NO}<br>備考1:${cur.INV_DNOTE1 ?? ''}<br>備考2:${cur.INV_DNOTE2 ?? ''}`;

                if (index === (this.items[i].salTInvoiceDtls.length - 1) ) {
                    acc += '</div>';
                }
                return acc;

            }, '<div class="text-start">');

        },
        openInvoice(id){
            window.open(`${this.documentRoot}/invoice/${id}/edit`, '_blank');
        },
        print() {
            this.$emit('print')
        },
    },
    computed: {
        items: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:model-value', value)
            }
        },
    },
}
</script>