<template>
    <div class="table-responsive" style="height: 700px; resize: both;" ref="scrollDiv" @scroll="handleTableScroll">
        <sortable-table2 ref="sortable"
            v-model="items"
            :default-sort-key="sort.key"
            :default-sort-asc="sort.asc"
            :session-name="sessionName"
        >
            <template #default="{setIcon, sort}">
                <table class="table table-hover table-sm text-nowrap" style="table-layout: fixed">
                    <thead class="sticky-top bg-body">
                        <tr>
                            <th style="width: 40px">{{ dg.SequentialNumber }}</th>
                            <th class="text-center" style="width: 70px; cursor:pointer"  @click="sort('KOKYK_ID')">{{ dg.KOKYK_ID }}<font-awesome-icon :icon="setIcon('KOKYK_ID')" /></th>
                            <th class="text-center" style="width: 190px">{{ dg.CLNIC_NM }}</th>
                            <th class="text-center" style="width: 60px">{{ dg.SalTInsite.STATUS }}</th>
                            <th class="text-center" style="width: 100px; cursor:pointer" @click="sort('START_DATE')">{{ dg.SalTInsite.START_DATE }}<font-awesome-icon :icon="setIcon('START_DATE')" /></th>
                            <th class="text-center" style="width: 100px">{{ dg.SalTInsite.END_DATE }}</th>
                            <th class="text-center" style="width: 150px; cursor:pointer" @click="sort('DOMAIN_NAME')">{{ dg.SalTInsite.DOMAIN_NAME }}<font-awesome-icon :icon="setIcon('DOMAIN_NAME')" /></th>
                            <th class="text-center" style="width: 150px; cursor:pointer" @click="sort('REGSTRA')">{{ dg.SalTInsite.REGSTRA }}<font-awesome-icon :icon="setIcon('REGSTRA')" /></th>
                            <th class="text-center" style="width: 150px">{{ dg.SalTInsite.URL }}</th>
                            <th class="text-center" style="width: 150px">{{ dg.SalTInsite.MURL }}</th>
                            <th class="text-center" style="width: 296px">{{ dg.SalTInsite.NOTE }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in items" :key="index">
                            <td>{{ index + 1 }}</td>
                            <td><a href="#" @click.prevent.stop="edit(item.ID)">{{ item.KOKYK_ID }}</a></td>
                            <td>{{ strLimit(item.CLNIC_NM, 20) }}</td>
                            <td>{{ insiteStatusList[item.STATUS] }}</td>
                            <td>{{ item.START_DATE?.replace(/-/g, '/') }}</td>
                            <td>{{ item.END_DATE?.replace(/-/g, '/') }}</td>
                            <td>{{ strLimit(item.DOMAIN_NAME, 20) }}</td>
                            <td>{{ strLimit(item.REGSTRA, 20) }}</td>
                            <td><a href="#" @click.prevent.stop="handleUrlClick(item.URL)">{{ strLimit(item.URL, 20) }}</a></td>
                            <td><a href="#" @click.prevent.stop="handleUrlClick(item.MURL)">{{ strLimit(item.MURL, 20) }}</a></td>
                            <td>{{ strLimit(item.NOTE, 20) }}</td>
                        </tr>
                    </tbody>
                </table>
            </template>
        </sortable-table2>
    </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import Mixin from '../mixins/mixin'
import SortableTable2 from '../tables/SortableTable2.vue';

export default {
    name: 'InsiteIndexTable',
    mixins: [Mixin],
    emits: ['handleTableScroll', 'update:model-value'],
    components: {
        FontAwesomeIcon,
        SortableTable2,
    },
    props: {
        modelValue: Array,
        dg: Object,
        documentRoot: String,
        insiteStatusList: Object,
        sort: Object,
        sessionName: String,
    },
    computed: {
        items: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:model-value', value)
            }
        },       
    },
    methods: {
        init(){
            this.$refs.sortable.initialSort();
        },
        edit(primaryKey) {
            location.href = `${this.documentRoot}/insite/${primaryKey}/edit`;
        },
        scroll(height) {
            this.$refs.scrollDiv.scrollTop = height;
        },
        handleTableScroll(e) {
            this.$emit('handleTableScroll', e);
        },
        handleUrlClick(url) {
            window.open(url, '_blank');
        }
    },
}
</script>
