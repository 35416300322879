<template>
    <div class="row">
        <div class="col-3">
            <div class="card">
                <div class="card-body">
                    <div class="card-text">
                        <div class="row">

                            <!-- 請求年月 -->
                            <input-date
                                style="width: 160px;"
                                v-model="createForm.INV_YM"
                                :error="createErrors.INV_YM"
                                :label-attr="{ for: 'INV_YM', title: dg.SalTAgent.INV_YM }"
                                :input-attr="{ type: 'month' }"
                            ></input-date>

                            <!-- 代理店名 -->
                            <div class="col-6">
                                <label for="RECE_CODE" class="form-label">{{ dg.SalTAgent.agent }}</label>
                                <select class="form-select form-select-sm" id="RECE_CODE" v-model="createForm.RECE_CODE">
                                    <option value="">{{ dg.pleaseSelect }}</option>
                                    <option v-for="(agent, key) in agentList" :key="key" :value="key">{{ agent }}</option>
                                </select>
                                <error-message :error="createErrors.RECE_CODE"></error-message>
                            </div>

                        </div>
                        <div class="row">
                            <div class="col-6 d-flex gap-1 align-items-start mt-3">
                                <a href="#" class="btn btn-primary btn-sm" @click.prevent.stop="create()">{{ dg.agent.create }}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-8">
            <form ref="form" @submit.prevent="getAgents()" class="mb-2">
                <div class="card">
                    <div class="card-body">
                        <div class="card-text row">
                            <div class="col-3" style="z-index: 1029;">
                                <label for="RECE_CODE" class="form-label">{{ dg.SalTAgent.agent }}</label>
                                <VueMultiselect
                                    v-model="form.RECE_CODE"
                                    :custom-label="setAgentLabel"
                                    :options="agentListArray"
                                    label="name"
                                    :close-on-select="false"
                                    :taggable="false"
                                    :multiple="true"
                                    :searchable="false"
                                    placeholder='未選択'
                                    selectLabel="選択"
                                    selectedLabel="選択済"
                                    deselectLabel="解除"
                                    :limitText="count => `+${count}`"
                                    :limit="1"
                                    >
                                </VueMultiselect>
                                <error-message :error="errors.RECE_CODE"></error-message>
                            </div>

                            <!-- 開始日 -->
                            <input-date
                                style="width: 160px;"
                                v-model="form.START_DAY"
                                :error="errors.START_DAY"
                                :label-attr="{ for: 'START_DAY', title: dg.START_DAY }"
                            ></input-date>
                            
                            <!-- 終了日 -->
                            <input-date
                                style="width: 160px;"
                                v-model="form.END_DAY"
                                :error="errors.END_DAY"
                                :label-attr="{ for: 'END_DAY', title: dg.END_DAY }"
                            ></input-date>

                            <div class="col-2" style="z-index: 1029;">
                                <label for="STATUS" class="form-label">{{ dg.SalTAgent.STATUS }}</label>
                                <VueMultiselect
                                    v-model="form.STATUS"
                                    :custom-label="setStatusLabel"
                                    :options="statusListArray"
                                    label="name"
                                    :close-on-select="false"
                                    :taggable="false"
                                    :multiple="true"
                                    :searchable="false"
                                    placeholder="未選択"
                                    selectLabel="選択"
                                    selectedLabel="選択済"
                                    deselectLabel="解除"
                                    :limitText="count => `+${count}`"
                                    :limit="1"
                                >
                                </VueMultiselect>
                                <error-message :error="errors.STATUS"></error-message>
                            </div>
                            <div class="col-auto d-flex gap-1 align-items-start" style="margin-top: 23px">
                                <button type="button" class="btn btn-secondary btn-sm" @click="reset()">リセット</button>
                                <button type="submit" class="btn btn-primary btn-sm">検索</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <agent-index-table ref="table"
        v-model="agents"
        :document-root="documentRoot"
        :dg="dg"
        :invoice-status-list="invoiceStatusList"
        :session-name="sessionName"
        :sort="sort"
    ></agent-index-table>
</template>

<script>
import moment from "moment";
import Mixin from '../mixins/mixin'
import AgentIndexTable from './AgentIndexTable.vue';
import InputDate from '../inputs/InputDate.vue';
import VueMultiselect from 'vue-multiselect';

export default {
    name: 'AgentIndex',
    mixins: [Mixin],
    components: {
        AgentIndexTable,
        InputDate,
        VueMultiselect,
    },
    props: {
        dg: Object,
        documentRoot: String,
        invoiceStatusList: Object,
        agentList: Object,
    },
    data() {
        return {
            agents       : [],
            createErrors : {},
            createForm   : {
                INV_YM    : moment().format('YYYY-MM'),
                RECE_CODE : '',
            },
            errors       : {},
            form         : {},
            sessionName  : 'agentIndex',
            sort         : {},
        }
    },
    async created() {
        // プロパティ初期化
        this.init();
        // セッションの操作
        this.sessionHandler();
        // 一覧の取得
        await this.getAgents();
        // 指定値までスクロール
        this.$refs.table.scroll(this.scroll);
        
    },
    methods: {
        sessionHandler() {
            const sessionData = this.getSession(this.sessionName);
            const sessionDataKeys = ['form', 'scroll', 'sort', 'createForm'];
            
            // セッションのデータキーを順に確認し、
            sessionDataKeys.forEach(key => {
                // キーが存在する場合、セッションから代入
                if (key in sessionData) {
                    this[key] = sessionData[key]
                // キーが存在しない場合、セッションに値を保存
                } else {
                    this.setSession(this.sessionName, { [key]: this[key] })
                }
            });
        },
        init() {
            // 検索条件
            this.form = {
                RECE_CODE   : [],
                START_DAY   : '',
                END_DAY     : '',
                STATUS     : [],
            }
            // スクロール量
            this.scroll = 0;
            // ソート
            this.sort = {
                key: 'INV_YM',
                asc: false,
            }
        },
        getAgents() {
            this.errors = {};
            return axios.get(`/agent/search`, { params: this.form })
                .then(res => {
                    this.agents = res.data.data;
                    // 検索条件をセッション保存
                    this.setSession(this.sessionName, { form : this.form });
                })
                .then(() => this.$refs.table.init())
                .catch((error) => {
                    if (error.response?.status == 422) {
                        this.errors = error.response.data.errors;
                    }
                });
        },
        async create() {
            this.createErrors = {};

            const regex = /^[0-9]{4}\-(0[1-9]|1[0-2])$/;
            if (!regex.test(this.createForm.INV_YM)) {
                this.createErrors = { INV_YM : [this.dg.pleaseInputCorrectYearMonth] };
                return;
            }
            try {
                const result = await axios.get(`/agent/check`, { params: this.createForm });
                if(result.data) {
                    alert('代理店請求情報は作成済みです')
                    return;
                };
                const isSalesExist = await axios.get(`/agent/is_sales_exist`, { params: this.createForm })
                if(! isSalesExist.data) {
                    alert('該当する販売データがありません')
                    return;
                };
                // 引落年月をセッション保存
                this.setSession(this.sessionName, { createForm : this.createForm });
            } catch (error) {
                if (error.response.status === 422) {
                    this.createErrors = error.response.data.errors;
                } else if (error.response.status === 500) {
                    const status = error.response.status;
                    window.alert(this.dg.internal_error);
                }
                return;
            }

            location.href = (`${this.documentRoot}/agent/create?INV_YM=${this.createForm.INV_YM}&RECE_CODE=${this.createForm.RECE_CODE}`)
        },
        edit(agentNo) {
            location.href = (`${this.documentRoot}/agent/${agentNo}/edit`)
        },
        reset() {
            this.init();
            this.$refs.table.init();
            this.getAgents();
        },
        setAgentLabel(code) {
            return `${this.agentList[code]}`;
        },
        setStatusLabel(code) {
            return `${this.invoiceStatusList[code]}`;
        },
    },
    computed: {
        agentListArray() {
            return Object.keys(this.agentList).sort();
        },
        statusListArray() {
            return Object.keys(this.invoiceStatusList);
        },
    }
}
</script>