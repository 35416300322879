<template>
    <input type="hidden" :name="getFormName('ID')" v-model="form.ID" />
    <input type="hidden" :name="getFormName('ENO')" v-model="form.ENO" />
    <div>
        <div class="card mb-2">
            <div class="card-body">
                <div class="card-title row">
                    <h5 class="col-2">
                        {{ dg.MKokykAccount.Account }} {{ index + 1 }}
                    </h5>
                    <div class="col-10 row">
                        <div class="col-2">
                            <!-- ゆうちょトグル -->
                            <toggle-switch
                                v-model="isPost"
                                @handle-toggle-change="handleToggleChange"
                                >{{ dg.MKokykAccount.YuchoBank }}</toggle-switch
                            >
                        </div>
                        <div class="col-10">
                            <input
                                type="text"
                                :name="getFormName('NOTE')"
                                class="form-control form-control-sm"
                                :placeholder="dg.MKokykAccount.NOTE"
                                v-model="form.NOTE"
                            />
                        </div>
                    </div>
                </div>
                <div class="card-text">
                    <div class="row">
                        <!-- 銀行名 -->
                        <div class="col-2">
                            <label
                                :for="getFormName('BANK_NM')"
                                class="form-label"
                                >{{ dg.MKokykAccount.BANK_NM }}</label
                            >
                            <input
                                :readonly="isPost"
                                :name="getFormName('BANK_NM')"
                                :id="getFormName('BANK_NM')"
                                type="text"
                                class="form-control form-control-sm"
                                v-model="form.BANK_NM"
                            />
                            <error-message
                                :error="errors.BANK_NM"
                            ></error-message>
                        </div>
                        <!-- 銀行コード -->
                        <div class="col-1">
                            <label
                                :for="getFormName('BANK_CODE')"
                                class="form-label"
                                >{{ dg.MKokykAccount.BANK_CODE }}</label
                            >
                            <input-hankaku
                                v-model="form.BANK_CODE"
                                inputType="text"
                                :inputName="getFormName('BANK_CODE')"
                                :inputId="getFormName('BANK_CODE')"
                                :readonly="isPost"
                            ></input-hankaku>
                            <error-message
                                :error="errors.BANK_CODE"
                            ></error-message>
                        </div>
                        <!-- 支店名 -->
                        <div class="col-2" v-if="!isPost">
                            <label
                                :for="getFormName('BRANCH_NM')"
                                class="form-label"
                                >{{ dg.MKokykAccount.BRANCH_NM }}</label
                            >
                            <input
                                :name="getFormName('BRANCH_NM')"
                                :id="getFormName('BRANCH_NM')"
                                type="text"
                                class="form-control form-control-sm"
                                v-model="form.BRANCH_NM"
                            />
                            <error-message
                                :error="errors.BRANCH_NM"
                            ></error-message>
                        </div>
                        <!-- 支店コード（郵貯の場合、記号） -->
                        <div class="col-1">
                            <label
                                v-if="isPost"
                                :for="getFormName('BRANCH_CODE')"
                                class="form-label"
                                >記号</label
                            >
                            <label
                                v-else
                                :for="getFormName('BRANCH_CODE')"
                                class="form-label"
                                >{{ dg.MKokykAccount.BRANCH_CODE }}</label
                            >
                            <input-hankaku
                                v-model="form.BRANCH_CODE"
                                inputType="text"
                                :inputName="getFormName('BRANCH_CODE')"
                                :inputId="getFormName('BRANCH_CODE')"
                            ></input-hankaku>
                            <error-message
                                :error="errors.BRANCH_CODE"
                            ></error-message>
                        </div>
                        <!-- 口座種別 -->
                        <div class="col-1" v-if="!isPost">
                            <label
                                :for="getFormName('ACCOUNT_TYPE')"
                                class="form-label"
                                >{{ dg.MKokykAccount.ACCOUNT_TYPE }}</label
                            >
                            <select
                                class="form-select form-select-sm"
                                :name="getFormName('ACCOUNT_TYPE')"
                                :id="getFormName('ACCOUNT_TYPE')"
                                v-model="form.ACCOUNT_TYPE"
                            >
                                <option
                                    v-for="(value, key) in bankAccountType"
                                    :key="key"
                                    :value="key"
                                >
                                    {{ value }}
                                </option>
                            </select>
                            <error-message
                                :error="errors.ACCOUNT_TYPE"
                            ></error-message>
                        </div>
                        <!-- 口座番号 -->
                        <div class="col-1">
                            <label
                                v-if="isPost"
                                for="ACCOUNT_NUMBER"
                                class="form-label"
                                >番号</label
                            >
                            <label
                                v-else
                                :for="getFormName('ACCOUNT_NUMBER')"
                                class="form-label"
                                >{{ dg.MKokykAccount.ACCOUNT_NUMBER }}</label
                            >
                            <input-hankaku
                                v-model="form.ACCOUNT_NUMBER"
                                inputType="text"
                                :inputName="getFormName('ACCOUNT_NUMBER')"
                                :inputId="getFormName('ACCOUNT_NUMBER')"
                            ></input-hankaku>
                            <error-message
                                :error="errors.ACCOUNT_NUMBER"
                            ></error-message>
                        </div>
                        <!-- 支店名 -->
                        <div class="col-2">
                            <label
                                :for="getFormName('ACCOUNT_NM')"
                                class="form-label"
                                >{{ dg.MKokykAccount.ACCOUNT_NM }}</label
                            >
                            <input
                                type="text"
                                :name="getFormName('ACCOUNT_NM')"
                                :id="getFormName('ACCOUNT_NM')"
                                class="form-control form-control-sm"
                                v-model="form.ACCOUNT_NM"
                            />
                            <error-message
                                :error="errors.ACCOUNT_NM"
                            ></error-message>
                        </div>
                        <!-- ボタン -->
                        <div
                            :class="isPost ? 'col-5' : 'col-2'"
                            style="
                                display: flex;
                                align-items: flex-end;
                                justify-content: flex-end;
                            "
                        >
                            <!-- 削除ボタン -->
                            <button
                                type="button"
                                class="btn btn-danger"
                                @click="deleteAccount"
                            >
                                {{ dg.Delete }}
                            </button>
                        </div>
                    </div>
                    <div
                        v-if="index === 0"
                        class="alert alert-secondary mt-4 mb-0"
                        role="alert"
                    >
                        {{ dg.MKokykAccount.AboutFirstAccount }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import InputHankaku from "../inputs/InputHankaku.vue";
import ModalDialog from "../modals/ModalDialog.vue";
import ToggleSwitch from "../inputs/ToggleSwitch.vue";

export default {
    name: "CustomerAccountInput",
    components: {
        InputHankaku,
        ModalDialog,
        ToggleSwitch,
    },
    props: {
        bankAccountType: Object,
        dg: Object,
        modelValue: Object,
        index: Number,
        documentRoot: String,
        deleteAccount: Function,
    },
    data() {
        return {
            errors: [],
            form: {},
            isPost: false,
        };
    },
    created() {
        this.form = this.modelValue;
        this.isPost = this.form.BANK_CODE === "9900" ? true : false;
    },
    mounted() {},
    methods: {
        getFormName(field) {
            return `mKokykAccounts[${this.index}][${field}]`;
        },
        handleToggleChange(toggleVal) {
            this.errors = [];
            this.form = {
                ...this.form,
                BANK_NM: toggleVal ? this.dg.MKokykAccount.YuchoBank : "",
                BANK_CODE: toggleVal ? this.dg.MKokykAccount.YuchoBankCode : "",
                ACCOUNT_NM: "",
                ACCOUNT_NUMBER: "",
                ACCOUNT_TYPE: toggleVal ? "" : 0,
                BRANCH_CODE: "",
                BRANCH_NM: "",
            };
        },
    },
};
</script>
