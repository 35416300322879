<template>
    <div class="col-auto form-check form-switch">
        <div style="margin-left: 15px">    
            <input class="form-check-input" type="checkbox" id="show-detail" v-model="modelVal" @change="handleToggleChange(modelVal)">
            <label class="form-check-label" for="show-detail"><slot></slot></label>
        </div>
    </div>
</template>
<script>

export default {
    name: 'ToggleSwitch',
    emits: ['handle-toggle-change', 'update:model-value'],
    props: {
        modelValue: Boolean,
    },
    methods: {
        handleToggleChange(toggleVal) {
            this.$emit('handle-toggle-change', toggleVal);
        },
    },
    computed: {
        modelVal: {
            get() {
                return this.modelValue
            },
            set(value) {
                this.$emit('update:model-value', value)
            }
        }
    },
}
</script>
