    <template>
        <div v-if="active">
            <div class="modal" style="display: block;" @click.self="close">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">{{ dg.register }}</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="d-grid gap-2 d-md-flex justify-content-md-center">
                                <button type="button" class="btn btn-primary" data-bs-dismiss="modal" @click="update">{{ modalButtonUpdate }}</button>
                                <button type="button" class="btn btn-primary" data-bs-dismiss="modal" @click="create">{{ modalButtonCreate }}</button>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="close">{{ dg.close }}</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-backdrop show" ></div>
        </div>
    </template>

    <script>
    export default {
        name: 'ModalDialog',
        props: {
            dg: Object,
            modalButtonUpdate: String,
            modalButtonCreate: String,
        },
        data() {
            return {
                active: false,
            }
        },
        methods: {
            open() {
                this.active = true;
            },
            close() {
                this.active = false;
            },
            update() {
                this.$emit('update');
                this.active = false;
            },
            create() {
                this.$emit('create');
                this.active = false;
            }
        },
    }
    </script>
