<template>
    <div v-if="active">
        <div class="modal" style="display: block;"  @click.self="modalClose()">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">{{ _title }}</h5>
                    </div>
                    <div v-if="_message" class="modal-body" style="white-space: pre-wrap;">
                        {{ _message }}
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary" data-bs-dismiss="modal" @click="setAnswer(1)">{{ _yesButton }}</button>
                        <button type="button" class="btn btn-primary" data-bs-dismiss="modal" @click="setAnswer(0)">{{ _noButton }}</button>
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="modalClose()">{{ cancel }}</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-backdrop show" ></div>
    </div>
</template>

<script>
export default {
    name: 'YesNoModal3',
    props: {
        title: {
            type: String,
            required: false,
            default: '確認',
        },
        message: {
            type: String,
            required: false,
            default: '',
        },
        yesButton: {
            type: String,
            required: false,
            default: 'はい',
        },
        noButton: {
            type: String,
            required: false,
            default: 'いいえ',
        },
        cancel: {
            type: String,
            required: false,
            default: 'キャンセル',
        }
    },
    data() {
        return {
            _title: '',
            _message: '',
            _yesButton: '',
            _noButton: '', 
            _cancel: '',
            active: false,
            answer: null
        }
    },
    methods: {
        action(title = null, message = null, yesButton = null, noButton = null) {

            this._title = title ? title : this.title;
            this._message = message ? message : this.message;
            this._yesButton = yesButton ? yesButton : this.yesButton;
            this._noButton = noButton ? noButton : this.noButton;

            this.modalOpen();
            return new Promise((resolve, reject) => {
                const unwatchAnswer = this.$watch('answer', newVal => {
                    const value = newVal;
                    unwatchAnswer();
                    unwatchActive();
                    this.answer = null;

                    this.modalClose();
                    resolve(value);
                });
                
                const unwatchActive = this.$watch('active', () => {
                    unwatchAnswer();
                    unwatchActive();
                    
                    reject('modal: closed');
                });
            });
        },
        modalOpen() {
            this.active = true;
        },
        modalClose() {
            this.active = false;
        },
        setAnswer(value) {
            this.answer = value;
        }
    },
}
</script>
