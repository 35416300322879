<template>
    <div class="table-responsive" style="height: 700px; resize: both;  overflow: auto;"  ref="scrollDiv"  @scroll="handleTableScroll">
        <sort-table ref="sortable"
            v-model="items"
            v-model:formModelValue="form"
            @fetch="fetch"
        >
            <template #default="{setIcon, sort}">
                <table class="table table-bordered table-sm" style="table-layout: fixed">
                    <col style="width: 30px"/>
                    <col style="width: 80px"/>
                    <col style="width: 200px"/>
                    <col style="width: 150px"/>
                    <col style="width: 70px"/>
                    <col style="width: 90px"/>
                    <col style="width: 90px"/>
                    <template v-for="(year, index) in years" :key="index">
                        <col style="width: 30px;"/>
                        <col style="width: 100px;"/>
                    </template>
                    <col style="min-width:200px "/>

                    <thead class="sticky-top bg-body"  style="z-index: 1">
                        <tr>
                            <th class="text-center" rowspan="2">{{ dg.SequentialNumber }}</th>
                            <th class="text-center" rowspan="2" style="cursor:pointer" @click="sort('KOKYK_ID')">{{ dg.KOKYK_ID }}<font-awesome-icon :icon="setIcon('KOKYK_ID')" /></th>
                            <th class="text-center" rowspan="2">{{ dg.SalTInvoice.CLNIC_NM }}</th>
                            <th class="text-center" rowspan="2">{{ dg.SalTEstimateDtl.PRODUCT_NAME }}</th>
                            <th class="text-center" rowspan="2" style="cursor:pointer" @click="sort('STATUS')">{{ dg.SalTInvoice.STATUS }}<font-awesome-icon :icon="setIcon('STATUS')" /></th>
                            <th class="text-center" colspan="2">{{ dg.Contract }}</th>
                            <th class="text-center" colspan="2" v-for="(year, index) in years" :key="index">{{ year }}</th>
                            <th class="text-center" rowspan="2">{{ dg.NOTE }}</th>
                        </tr>
                        <tr>
                            <th class="text-center" style="cursor:pointer" @click="sort('CONT_START')">{{ dg.SalTSales.CONT_START }}<font-awesome-icon :icon="setIcon('CONT_START')" /></th>
                            <th class="text-center">{{ dg.SalTSales.CONT_END }}</th>
                            <template v-for="(year, index) in years" :key="index">
                                <th class="text-center">{{ dg.numberOfTimes }}</th>
                                <th class="text-center">{{ dg.price }}</th>
                            </template>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="(item, itemKey) in items" :key="itemKey">
                            <tr>
                                <td>{{ itemKey + 1 }}</td>
                                <td v-if="item?.count === 0" :rowspan="item.rowspan">{{ item.KOKYK_ID }}</td>
                                <td v-if="item?.count === 0" :rowspan="item.rowspan">{{ strLimit(item.CLNIC_NM, 20) }}</td>
                                <td>{{ productList[item.PRODUCT_NAME] }}</td>
                                <td>{{ salesStatusList[item.STATUS] }}</td>
                                <td>{{ item.CONT_START?.replaceAll('-', '/') }}</td>
                                <td>{{ item.CONT_END?.replaceAll('-', '/') }}</td>
                                <template v-for="(year, index) in years" :key="index">
                                    <td
                                        :class="item?.[year]?.background"
                                        class="text-end"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="bottom"
                                        data-bs-html="true"
                                        :title="tooltips(item?.[year]?.tooltip)"
                                        ref="info">{{ item?.[year]?.count }}</td>
                                    <td
                                        :class="item?.[year]?.background"
                                        class="text-end"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="bottom"
                                        data-bs-html="true"
                                        :title="tooltips(item?.[year]?.tooltip)"
                                        ref="info">{{ item?.[year]?.priceSum }}</td>
                                </template>
                                <td>{{ strLimit(item.SALES_NOTE1, 20) }}</td>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </template>
        </sort-table>
    </div>
    <table class="table table-borderless table-sm" style="table-layout: fixed">
        <col style="width: 30px"/>
        <col style="width: 80px"/>
        <col style="width: 200px"/>
        <col style="width: 150px"/>
        <col style="width: 70px"/>
        <col style="width: 90px"/>
        <col style="width: 90px"/>
        <col style="width: 30px;"/>
        <col style="width: 100px;"/>
        <col style="width: "/>

        <tfoot class="sticky-bottom bg-body fw-bold">
            <tr>
                <td colspan="3">対象件数:  {{ items?.length }}件</td>
            </tr>
        </tfoot>
    </table>
</template>
<script>
import { Tooltip } from "bootstrap";
import Mixin from '../mixins/mixin'
import SortTable from '../tables/SortTable.vue';

export default {
    name: 'InvoiceCheckTable',
    mixins: [Mixin],
    emits: ['handleTableScroll', 'update:model-value', 'fetch'],
    components: {
        SortTable,
    },
    mounted() {
        new Tooltip(document.body, {
            selector: "[data-bs-toggle='tooltip']",
        });
    },
    props: {
        dg: {
            type: Object,
            required: true,
        },
        formModelValue: {
            type: Object,
            required: true,
        },
        modelValue: {
            type: Array,
            required: true,
        },
        productList: {
            type: Object,
            required: true,
        },
        salesStatusList: {
            type: Object,
            required: true,
        },
        years: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            count: 0,
        }
    },
    computed: {
        items: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:model-value', value)
            }
        },
        form: {
            get() {
                return this.formModelValue
            },
            set(value) {
                this.$emit('update:formModelValue', value)
            }
        },
    },
    methods: {
        tooltips(content) {
            return content ?? '';
        },
        fetch() {
            this.$emit('fetch');
        },
        scroll(height) {
            this.$refs.scrollDiv.scrollTop = height;
        },
        handleTableScroll(e) {
            this.$emit('handleTableScroll', e);
        },
    },
}
</script>