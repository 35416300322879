<template>
    <div class="col-auto">
        <label :for="labelAttr.for" class="form-label">{{ labelAttr.title }}</label>
        <input :type="inputAttr.type" :name="labelAttr.for" class="form-control form-control-sm" :id="labelAttr.for" v-model="modelVal">
        <error-message :error="error"></error-message>
    </div>
</template>

<script>
import ErrorMessage from "../error/ErrorMessage.vue"

export default {
    name: 'InputDate',
    emits: ['update:model-value'],
    components: {
        ErrorMessage,
    },
    props: {
        modelValue: String,
        error: { type: Array, required: false, default: [] },
        labelAttr: {type: Object, required: true },
        inputAttr: { type: Object, required: false, default: { type: 'date'} },
    },
    computed: {
        modelVal: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:model-value', value)
            }
        }
    },
}
</script>
