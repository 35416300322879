// https://docs.amplify.aws/lib/auth/start/q/platform/js/#re-use-existing-authentication-resource
const config = {
    Auth: {
        region: process.env.MIX_AWS_COGNITO_REGION,
        userPoolId: process.env.MIX_AWS_COGNITO_USER_POOL_ID,
        userPoolWebClientId : process.env.MIX_AWS_COGNITO_CLIENT_ID,
        oauth: {
            domain: process.env.MIX_AWS_COGNITO_AUTH_DOMAIN,
            scope: ['openid'],
            redirectSignIn: process.env.MIX_AWS_COGNITO_REDIRECT_URI,
            redirectSignOut: process.env.MIX_AWS_COGNITO_REDIRECT_SIGN_OUT_URI,
            responseType: 'code'
        },
        cookieStorage: {
            domain: process.env.MIX_AWS_COGNITO_COOKIE_DOMAIN,
            path: '/',
            expires: 365,
            secure: process.env.MIX_AWS_COGNITO_COOKIE_DOMAIN === 'localhost' ? false : true,
        },
    }
};

export default config;
