<template>
    <span>
        <span data-bs-toggle="tooltip" data-bs-placement="top" :title="tooltip">
            <font-awesome-icon icon="fa-regular fa-circle-question" />
        </span>
    </span>
</template>

<script>
import { Tooltip } from "bootstrap";

export default {
    name: "Help",
    props: {
        tooltip: String,
    },
    mounted() {
        new Tooltip(this.$el, {
            selector: '[data-bs-toggle="tooltip"]',
        });
    },
};
</script>
