require("./bootstrap");

import { createApp } from "vue";
/* import the fontawesome core */
import { library } from "@fortawesome/fontawesome-svg-core";
/* import font awesome icon component */
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
/* import specific icons */
import { fab } from "@fortawesome/free-brands-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
/* add icons to the library */
library.add(fas, fab, far);

// バリデーションエラーメッセージ
import ErrorMessage from "./components/error/ErrorMessage.vue";

import InputText from "./components/inputs/InputText.vue";

import AgentIndex from "./components/agent/AgentIndex.vue";
import AgentInput from "./components/agent/AgentInput.vue";
import CustomerIndex from "./components/customer/CustomerIndex.vue";
import CustomerInput from "./components/customer/CustomerInput.vue";
import DrawIndex from "./components/draw/DrawIndex.vue";
import DrawInput from "./components/draw/DrawInput.vue";
import EstimateIndex from "./components/estimate/EstimateIndex.vue";
import EstimateInput from "./components/estimate/EstimateInput.vue";
import InsiteIndex from "./components/insite/InsiteIndex.vue";
import InsiteInput from "./components/insite/InsiteInput.vue";
import InvoiceCheck from "./components/invoice/InvoiceCheck.vue";
import InvoiceIndex from "./components/invoice/InvoiceIndex.vue";
import InvoiceInput from "./components/invoice/InvoiceInput.vue";
import LetsinIndex from "./components/letsin/LetsinIndex.vue";
import LetsinInput from "./components/letsin/LetsinInput.vue";
import PcIndex from "./components/pc/PcIndex.vue";
import PcInput from "./components/pc/PcInput.vue";
import SalesIndex from "./components/sales/SalesIndex.vue";
import SalesInput from "./components/sales/SalesInput.vue";
import StockIndex from "./components/stock/StockIndex.vue";
import StockInput from "./components/stock/StockInput.vue";

import { Amplify, Auth } from "aws-amplify";
import awsAmplifyConfig from "./aws-amplify-config";
Amplify.configure(awsAmplifyConfig);

// auth/index.blade.php、auth/logout.blade.php で使用するため、グローバルに設定
window.Auth = Auth;

window.addEventListener("load", () => {
    // ログイン無効化設定（デバッグ用）
    // return;
    // call Auth.currentSession every 3 seconds.
    setInterval(() => {
        Auth.currentSession()
            .then((user) => {
                if (!user) {
                    window.location.href = "auth";
                }
            })
            .catch((err) => {
                window.location.href = "auth";
            });
    }, 3000);
});

createApp({
    components: {
        AgentIndex,
        AgentInput,
        CustomerIndex,
        CustomerInput,
        DrawIndex,
        DrawInput,
        EstimateIndex,
        EstimateInput,
        SalesIndex,
        SalesInput,
        StockIndex,
        StockInput,
        InvoiceIndex,
        InvoiceInput,
        InvoiceCheck,
        InsiteIndex,
        InsiteInput,
        LetsinIndex,
        LetsinInput,
        PcIndex,
        PcInput,
    },
})
    .component("font-awesome-icon", FontAwesomeIcon)
    .component("error-message", ErrorMessage)
    .component("input-text", InputText)
    .mount("#app");
