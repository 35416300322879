<template>
    <div class="table-responsive" style="height: 550px; resize: both;" ref="scrollDiv"  @scroll="handleTableScroll">
        <sort-table ref="sortable"
            v-model="items"
            v-model:formModelValue="form"
            @fetch="fetch"
        >
            <template #default="{setIcon, sort}">
                <table class="table table-hover table-sm text-nowrap mb-0" style="table-layout: fixed">
                    <thead class="sticky-top bg-body">
                        <tr>
                            <th style="width: 30px">{{ dg.SequentialNumber }}</th>
                            <th style="width: 140px; cursor:pointer" @click="sort('INV_NO')">{{ dg.SalTInvoice.INV_NO }}/{{ dg.ENO }}/{{ dg.SalTInvoiceDtl.RNO }}<font-awesome-icon :icon="setIcon('INV_NO')" /></th>
                            <th style="width: 100px; cursor:pointer" @click="sort('INV_DATE')">{{ dg.SalTInvoice.INV_DATE }}<font-awesome-icon :icon="setIcon('INV_DATE')" /></th>
                            <th style="width: 60px">{{ dg.SalTInvoice.STATUS }}</th>
                            <th style="width: 80px">{{ dg.SalTInvoice.RECE_CODE }}</th>
                            <th style="width: 65px; cursor:pointer" @click="sort('KOKYK_ID')">{{ dg.KOKYK_ID }}<font-awesome-icon :icon="setIcon('KOKYK_ID')" /></th>
                            <th style="width: 130px">{{ dg.SalTInvoice.CLNIC_NM }}</th>
                            <th style="width: 200px">{{ dg.SalTInvoiceDtl.PRODUCT_NAME }}</th>
                            <th class="text-end" style="width: 60px">{{ dg.SalTInvoiceDtl.UPRICE }}</th>
                            <th class="text-end" style="width: 50px">{{ dg.SalTInvoiceDtl.CNT }}</th>
                            <th class="text-end" style="width: 145px">{{ dg.SalTInvoiceDtl.PRICE }}</th>
                            <th style="width: 140px">{{ dg.SalTInvoiceDtl.INV_DNOTE1 }}</th>
                            <th style="width: 239px">{{ dg.SalTInvoiceDtl.INV_DNOTE2 }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="(item, index) in items" :key="index"> 
                            <tr style="border-top: 1px solid var(--bs-border-color)">
                                <td>{{ index + 1 }}</td>
                                <td><a href="#" @click.prevent.stop="edit(item.salTInvoice.ID)">{{ item.salTInvoice.INV_NO }}-{{ item.salTInvoice.ENO }}-{{ item.RNO }}</a></td>
                                <td>{{ item.salTInvoice?.INV_DATE?.replaceAll('-', '/') }}</td>
                                <td>{{ invoiceStatusList[item.salTInvoice?.STATUS] }}</td>
                                <td>{{ receCodeList[item.salTInvoice?.RECE_CODE] }}</td>
                                <td>{{ item.salTInvoice?.KOKYK_ID }}</td>
                                <td>{{ strLimit(item.salTInvoice?.CLNIC_NM, 20) }}</td>
                                <td>{{ item?.PRODUCT_CODE + '/' + strLimit(productList[item?.PRODUCT_CODE], 20) }}</td>
                                <td class="text-end">{{ item.UPRICE?.toLocaleString() }}</td>
                                <td class="text-end">{{ item.CNT?.toLocaleString() }}</td>
                                <td class="text-end">{{ item.PRICE?.toLocaleString() }}</td>
                                <td>{{ strLimit(item.INV_DNOTE1, 20) }}</td>
                                <td>{{ strLimit(item.INV_DNOTE2, 20) }}</td>  
                            </tr>
                        </template>
                    </tbody>
                </table>
            </template>
        </sort-table>
    </div>
    <table class="table table-borderless table-sm text-nowrap mb-0" style="table-layout: fixed">
        <thead class="sticky-top bg-body">
            <tr>
                <th style="width: 30px"></th> 
                <th style="width: 140px"></th>
                <th style="width: 100px"></th>
                <th style="width: 60px"></th>
                <th style="width: 80px"></th>
                <th style="width: 65px"></th>
                <th style="width: 130px"></th>
                <th style="width: 200px"></th>
                <th style="width: 60px"></th>
                <th style="width: 50px"></th>
                <th style="width: 145px"></th>
                <th style="width: 140px"></th>
                <th style="width: 239px"></th>
                <th style="width: 13px"></th>
            </tr>
        </thead>
        <tfoot class="sticky-bottom bg-body fw-bold">
            <tr>
                <!-- 件数 --><!-- 小計 -->
                <td colspan="6">
                    <!-- csvボタン -->
                    <csv-button
                        :button-name="dg.csvDetail"
                        file-name='請求明細一覧.csv'
                        :ids="ids"
                        url='/invoice/csv/detail'
                    ></csv-button>
                    <!-- csv経理ボタン -->
                    <csv-button
                        class="ms-2"
                        :button-name="`経理csv`"
                        file-name='請求経理.txt'
                        :ids="ids"
                        url='/invoice/csv/account'
                    ></csv-button>
                </td>
                <td colspan="2" >{{ dg.numRecord }}<span class="ms-5">{{ items?.length.toLocaleString() }}</span></td>
                <td colspan="2" class="ps-3" style="width: 4rem; letter-spacing: 1.5rem;">{{ dg.price }}</td>
                <td class="text-end ">&yen;{{ sumPrice.toLocaleString() }}</td>
                <td colspan="3"></td>
            </tr>
            <tr>
                <!-- 消費税 -->
                <td colspan="8"></td>
                <td colspan="2" class="ps-3">{{ dg.tax }}</td>
                <td class="text-end ">&yen;{{ sumTax.toLocaleString() }}</td>
                <td colspan="3"></td>
            </tr>
            <tr>
                <!-- 合計金額 -->
                <td colspan="8"></td>
                <td colspan="2" class="ps-3">{{ dg.tPrice }}</td>
                <td class="text-end ">&yen;{{ sumTPrice.toLocaleString() }}</td>
                <td colspan="3"></td>
            </tr>
        </tfoot>
    </table>
</template>
<script>
import CsvButton from '../CsvButton.vue';
import Mixin from '../mixins/mixin'
import SumTableFooter from '../tables/SumTableFooter.vue'
import SortTable from '../tables/SortTable.vue';

export default {
    name: 'InvoiceDetailIndexTable',
    mixins: [Mixin],
    emits: ['handleTableScroll', 'update:model-value', 'fetch'],
    components: {
        CsvButton,
        SumTableFooter,
        SortTable,
    },
    props: {
        documentRoot: {
            type: String,
            required: true,
        },
        dg: {
            type: Object,
            required: true,
        },  
        formModelValue: {
            type: Object,
            required: true,
        },
        ids: {
            type: Array,
            required: true,
        },
        invoiceStatusList: {
            type: Object,
            required: true,
        },
        modelValue: {
            type: Array,
            required: true,
        },
        productList: {
            type: Object,
            required: true,
        },
        receCodeList: {
            type: Object,
            required: true,
        },
        sessionName: {
            type: String,
            required: true,
        },
    },
    computed: {
        items: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:model-value', value)
            }
        },
        form: {
            get() {
                return this.formModelValue
            },
            set(value) {
                this.$emit('update:formModelValue', value)
            }
        },
        sumPrice() {
            return this.items.reduce((acc, cur) => acc + Number(cur.PRICE), 0);
        },
        sumTax() {
            return Math.round(this.sumPrice * 0.1, 0);
        },
        sumTPrice() {
            return Math.round(this.sumPrice + this.sumTax, 0);
        },
    },
    methods: {
        fetch() {
            this.$emit('fetch');
        },
        edit(primaryKey) {
            location.href = `${this.documentRoot}/invoice/${primaryKey}/edit`;
        },
        scroll(height) {
            this.$refs.scrollDiv.scrollTop = height;
        },
        handleTableScroll(e) {
            this.$emit('handleTableScroll', e);
        },
    },
}
</script>