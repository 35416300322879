<template>
    <form ref="form" @submit.prevent="fetchItems()" class="mb-2">
        <div class="card">
            <div class="card-body">
                <div class="card-text row">
                    <!-- 顧客番号と検索ボタン -->
                    <input-kokyk-id-with-button
                        v-model="form.KOKYK_ID"
                        @set-customer="setCustomerInfo"
                        :dg="dg"
                        :del-flag="delFlagList"
                        :errors="errors"
                        :syzk-list="syzkList"
                    ></input-kokyk-id-with-button>
                    <div class="col-2">
                        <label for="CLNIC_NM" class="form-label">{{
                            dg.MKokykLic.CLNIC_NM
                        }}</label>
                        <input
                            type="text"
                            name="CLNIC_NM"
                            class="form-control form-control-sm"
                            id="CLNIC_NM"
                            v-model="form.CLNIC_NM"
                        />
                        <error-message :error="errors.CLNIC_NM"></error-message>
                    </div>
                    <div class="col-1">
                        <label for="DEL_FLG" class="form-label">{{
                            dg.MKokykLic.DEL_FLG
                        }}</label>
                        <select
                            name="DEL_FLG"
                            class="form-select form-select-sm"
                            id="DEL_FLG"
                            v-model="form.DEL_FLG"
                        >
                            <option value="">{{ dg.pleaseSelect }}</option>
                            <option
                                v-for="(delFlag, key) in delFlagList"
                                :key="key"
                                :value="key"
                            >
                                {{ delFlag }}
                            </option>
                        </select>
                        <error-message :error="errors.DEL_FLG"></error-message>
                    </div>
                    <div class="col-2">
                        <label for="SYZK_NO" class="form-label">{{
                            dg.MsSyzk.SYZK_NM
                        }}</label>
                        <select
                            name="SYZK_NO"
                            class="form-select form-select-sm"
                            id="SYZK_NO"
                            v-model="form.SYZK_NO"
                        >
                            <option value="">{{ dg.pleaseSelect }}</option>
                            <option
                                v-for="(syzkNm, key) in syzkList"
                                :key="key"
                                :value="key"
                            >
                                {{ syzkNm }}
                            </option>
                        </select>
                        <error-message :error="errors.SYZK_NO"></error-message>
                    </div>
                    <div class="col-auto" style="width: 150px">
                        <label for="TEL" class="form-label">{{
                            dg.MKokykLic.TEL
                        }}</label>
                        <input
                            type="tel"
                            name="TEL"
                            class="form-control form-control-sm"
                            id="TEL"
                            v-model="form.TEL"
                        />
                        <error-message :error="errors.TEL"></error-message>
                    </div>
                    <div class="col-2">
                        <label for="ACCOUNT_NM" class="form-label">{{
                            dg.MKokykAccount.ACCOUNT_NM
                        }}</label>
                        <input
                            type="text"
                            name="ACCOUNT_NM"
                            class="form-control form-control-sm"
                            id="ACCOUNT_NM"
                            v-model="form.ACCOUNT_NM"
                        />
                        <error-message
                            :error="errors.ACCOUNT_NM"
                        ></error-message>
                    </div>
                    <div
                        class="col-2 d-flex gap-1 align-items-start"
                        style="margin-top: 23px"
                    >
                        <button
                            type="button"
                            class="btn btn-secondary btn-sm"
                            @click="reset()"
                        >
                            {{ dg.Reset }}
                        </button>
                        <button type="submit" class="btn btn-primary btn-sm">
                            {{ dg.Search }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </form>

    <customer-index-table
        ref="table"
        v-model="customers"
        :dg="dg"
        :document-root="documentRoot"
        :del-flag-list="delFlagList"
        @handleTableScroll="handleTableScroll"
    ></customer-index-table>
</template>

<script>
import CustomerIndexTable from "./CustomerIndexTable.vue";
import InputKokykIdWithButton from "../inputs/InputKokykIdWithButton.vue";
import Mixin from "../mixins/mixin";

export default {
    name: "CustomerIndex",
    mixins: [Mixin],
    components: {
        InputKokykIdWithButton,
        CustomerIndexTable,
    },
    props: {
        dg: Object,
        documentRoot: String,
        delFlagList: Object,
        syzkList: Object,
    },
    data() {
        return {
            form: {},
            customers: [],
            errors: {},
            sessionName: "customerIndex",
        };
    },
    async created() {
        // axios.get('${apiBaseUrl}/manage/v1/license/list?kokykId=000003', {
        //     headers: {
        //         Authorization: `Bearer ${apiOuthToken}`,
        //     }
        // })
        // .then(function (response) {
        //     console.log(response);
        // })
        // return;
        // プロパティ初期化
        this.init();
        // セッションの操作
        this.sessionHandler();
        // 一覧の取得
        await this.fetchItems();
        // 指定値までスクロール
        this.$refs.table.scroll(this.scroll);
    },
    methods: {
        sessionHandler() {
            const sessionData = this.getSession(this.sessionName);
            const sessionDataKeys = ["form", "scroll"];

            // セッションのデータキーを順に確認し、
            sessionDataKeys.forEach((key) => {
                // キーが存在する場合、セッションから代入
                if (key in sessionData) {
                    this[key] = sessionData[key];
                    // キーが存在しない場合、セッションに値を保存
                } else {
                    this.setSession(this.sessionName, { [key]: this[key] });
                }
            });
        },
        init() {
            // 検索条件
            (this.form = {
                KOKYK_ID: "",
                DEL_FLG: "",
                CLNIC_NM: "",
                SYZK_NO: "",
                TEL: "",
                ACCOUNT_NM: "",
            }),
                // スクロール量
                (this.scroll = 0);
        },
        handleTableScroll(e) {
            this.setSession(this.sessionName, { scroll: e.target.scrollTop });
        },
        setCustomerInfo({ KOKYK_ID, CLNIC_NM }) {
            this.form = { ...this.form, KOKYK_ID, CLNIC_NM };
        },
        async fetchItems() {
            this.errors = {};

            try {
                const {
                    data: { data },
                } = await axios.get(`/customer/search`, { params: this.form });
                this.customers = data;
                // 検索条件をセッション保存
                this.setSession(this.sessionName, { form: this.form });
            } catch (error) {
                if (error.response.status == 422) {
                    this.errors = error.response.data.errors;
                } else {
                    console.error(error);
                }
            }
        },
        reset() {
            this.init();
            this.fetchItems();
        },
    },
};
</script>
