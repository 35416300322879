<template>
    <div :class="divClass" :style="divStyle">
        <label :for="nameAttribute" class="form-label">{{ dg[nameAttribute] }}</label>
        <div class="input-group input-group-sm">
            <input-kokyk-id
                v-model="modelVal" 
                :name-attribute="nameAttribute"
                @set-customer="setCustomer"
            ></input-kokyk-id>
            <customer-search-button
                :dg="dg"
                :del-flag="delFlag"
                :syzk-list="syzkList"
                @set-customer="setCustomer"
            >{{ dg.Search }}</customer-search-button>
        </div>
        <error-message :error="errors[nameAttribute]"></error-message>
    </div>
</template>
<script>
import CustomerSearchButton from '../buttons/CustomerSearchButton.vue';
import InputKokykId from './InputKokykId.vue';

export default {
    name: 'InputKokykIdWithButton',
    emits: ['set-customer', 'update:model-value'],
    components: {
        CustomerSearchButton,
        InputKokykId,
    },
    props: {
        modelValue: String,
        contKind: { type: Number, default: 0 },
        delFlag: Object,
        dg: Object,
        divClass: { type: String, default: 'col-2' },
        divStyle: { type: Object, default: { width: '140px' } },
        errors: Object,
        nameAttribute: { type: String, default: 'KOKYK_ID'},
        syzkList: Object,
    },
    computed: {
        modelVal: {
            get() {
                return this.modelValue
            },
            set(value) {
                this.$emit('update:model-value', value)
            }
        }
    },
    methods: {
        setCustomer(customer) {
            this.$emit('set-customer', customer);
        },
    },
}
</script>
