<template>
    <button type="button" class="btn btn-primary" @click="clicked"><slot></slot></button>
</template>
<script>

export default {
    name: 'NewTabButton',
    props: {
        alertFlag: Boolean,
        alertMessage: String,
        url: String
    },
    methods: {
        clicked() {
            if (this.alertFlag) {
                alert(this.alertMessage);
                return;
            } else {
                window.open(this.url, '_blank');
            }
        },
    },
}
</script>
