<template>
    <form ref="form">
        <div class="card mb-1">
            <div class="card-body py-1">
                <div class="row mb-2">

                    <!-- 顧客番号と検索ボタン -->
                    <input-kokyk-id-with-button
                        v-model="form.KOKYK_ID"
                        @set-customer="setCustomerInfo"
                        :dg="dg"
                        :del-flag="delFlagList"
                        :errors="errors"
                        :syzk-list="syzkList"
                    ></input-kokyk-id-with-button>

                    <!-- 顧客名 -->
                    <div class="col-2">
                        <label for="CLNIC_NM" class="form-label">{{ dg.MKokykLic.CLNIC_NM }}</label>
                        <input type="text" name="CLNIC_NM" class="form-control form-control-sm" id="CLNIC_NM" v-model="form.CLNIC_NM">
                        <error-message :error="errors.CLNIC_NM"></error-message>
                    </div>

                    <!-- 状態 -->
                    <div class="col-1">
                        <label for="STATUS" class="form-label form-label-sm">{{ dg.SalTInsite.STATUS }}</label>
                        <select name="STATUS" class="form-select form-select-sm" id="STATUS" v-model="form.STATUS">
                            <option v-for="(status, index) in insiteStatusList" :key="index" :value="index">{{ status }}</option>
                        </select>
                        <error-message :error="errors.STATUS"></error-message>
                    </div>

                    <!-- 開始日 -->
                    <div class="col-2" style="width: 130px">
                        <label for="START_DATE" class="form-label">{{ dg.SalTInsite.START_DATE }}</label>
                        <input type="date" name="START_DATE" class="form-control form-control-sm" id="START_DATE" @blur="focusOut" v-model="form.START_DATE">
                        <error-message :error="errors.START_DATE"></error-message>
                    </div>

                    <!-- 終了日 -->
                    <div class="col-2" style="width: 130px">
                        <label for="END_DATE" class="form-label">{{ dg.SalTInsite.END_DATE }}</label>
                        <input type="date" name="END_DATE" class="form-control form-control-sm" id="END_DATE" @blur="focusOut" v-model="form.END_DATE">
                        <error-message :error="errors.END_DATE"></error-message>
                    </div>
                    
                    <!-- 連絡用メールアドレス -->
                    <div class="col-3">
                        <label for="MAIL" class="form-label">{{ dg.SalTInsite.MAIL }}</label>
                        <input type="text" name="MAIL" class="form-control form-control-sm" id="MAIL" @blur="focusOut" v-model="form.MAIL">
                        <error-message :error="errors.MAIL"></error-message>
                    </div>

                    <!-- 連絡先氏名 -->
                    <div class="col-2">
                        <label for="CONTACT_NAME" class="form-label">{{ dg.SalTInsite.CONTACT_NAME }}</label>
                        <input type="text" name="CONTACT_NAME" class="form-control form-control-sm" id="CONTACT_NAME" @blur="focusOut" v-model="form.CONTACT_NAME">
                        <error-message :error="errors.CONTACT_NAME"></error-message>
                    </div>
                </div>
                <div class="row mb-2">  
                    <!-- ドメイン名 -->
                    <div class="col-2">
                        <label for="DOMAIN_NAME" class="form-label">{{ dg.SalTInsite.DOMAIN_NAME }}</label>
                        <input type="text" name="DOMAIN_NAME" class="form-control form-control-sm" id="DOMAIN_NAME" v-model="form.DOMAIN_NAME">
                        <error-message :error="errors.DOMAIN_NAME"></error-message>
                    </div>
                    <!-- レジストラ -->
                    <div class="col-2">
                        <label for="REGSTRA" class="form-label">{{ dg.SalTInsite.REGSTRA }}</label>
                        <input type="text" name="REGSTRA" class="form-control form-control-sm" id="REGSTRA" v-model="form.REGSTRA">
                        <error-message :error="errors.REGSTRA"></error-message>
                    </div>

                    <!-- ドメイン開始日 -->
                    <div class="col-1" style="width: 130px">
                        <label for="DOMAIN_START" class="form-label">{{ dg.SalTInsite.DOMAIN_START }}</label>
                        <input type="date" name="DOMAIN_START" class="form-control form-control-sm" id="DOMAIN_START" @blur="focusOut" v-model="form.DOMAIN_START">
                        <error-message :error="errors.DOMAIN_START"></error-message>
                    </div>

                    <!-- ドメイン終了日 -->
                    <div class="col-1" style="width: 130px">
                        <label for="DOMAIN_END" class="form-label">{{ dg.SalTInsite.DOMAIN_END }}</label>
                        <input type="date" name="DOMAIN_END" class="form-control form-control-sm" id="DOMAIN_END" @blur="focusOut" v-model="form.DOMAIN_END">
                        <error-message :error="errors.DOMAIN_END"></error-message>
                    </div>
                    
                    <!-- ドメイン料金 -->
                    <div class="col-1">
                        <label for="DOMAIN_PRICE" class="form-label">{{ dg.SalTInsite.DOMAIN_PRICE }}</label>
                        <input-hankaku
                            v-model="form.DOMAIN_PRICE"
                            inputType="text"
                            inputName="DOMAIN_PRICE"
                            inputId="DOMAIN_PRICE"
                        ></input-hankaku>
                        <error-message :error="errors.DOMAIN_PRICE"></error-message>
                    </div>

                    <!-- サーバー名 -->
                    <div class="col-2">
                        <label for="SERVER" class="form-label">{{ dg.SalTInsite.SERVER }}</label>
                        <input type="text" name="SERVER" class="form-control form-control-sm" id="SERVER" v-model="form.SERVER">
                        <error-message :error="errors.SERVER"></error-message>
                    </div>

                    <!-- 雛形名 -->
                    <div class="col-2">
                        <label for="SAMPLE_NAME" class="form-label">{{ dg.SalTInsite.SAMPLE_NAME }}</label>
                        <input type="text" name="SAMPLE_NAME" class="form-control form-control-sm" id="SAMPLE_NAME" v-model="form.SAMPLE_NAME">
                        <error-message :error="errors.SAMPLE_NAME"></error-message>
                    </div>
                </div>
                <div class="row mb-2">  
                    <!-- URL -->
                    <div class="col-3">
                        <label for="URL" class="form-label">{{ dg.SalTInsite.URL }}</label>
                        <input type="text" name="URL" class="form-control form-control-sm" id="URL" v-model="form.URL">
                        <error-message :error="errors.URL"></error-message>
                    </div>

                    <!-- MURL -->
                    <div class="col-3">
                        <label for="MURL" class="form-label">{{ dg.SalTInsite.MURL }}</label>
                        <input type="text" name="MURL" class="form-control form-control-sm" id="MURL" v-model="form.MURL">
                        <error-message :error="errors.MURL"></error-message>
                    </div>

                    <!-- M_ID -->
                    <div class="col-1">
                        <label for="M_ID" class="form-label">{{ dg.SalTInsite.M_ID }}</label>
                        <input type="text" name="M_ID" class="form-control form-control-sm" id="M_ID" v-model="form.M_ID">
                        <error-message :error="errors.M_ID"></error-message>
                    </div>

                    <!-- M_PASS -->
                    <div class="col-2">
                        <label for="M_PASS" class="form-label">{{ dg.SalTInsite.M_PASS }}</label>
                        <input type="text" name="M_PASS" class="form-control form-control-sm" id="M_PASS" v-model="form.M_PASS">
                        <error-message :error="errors.M_PASS"></error-message>
                    </div>
                    <!-- U_ID -->
                    <div class="col-1">
                        <label for="U_ID" class="form-label">{{ dg.SalTInsite.U_ID }}</label>
                        <input type="text" name="U_ID" class="form-control form-control-sm" id="U_ID" v-model="form.U_ID">
                        <error-message :error="errors.U_ID"></error-message>
                    </div>

                    <!-- U_PASS -->
                    <div class="col-2">
                        <label for="U_PASS" class="form-label">{{ dg.SalTInsite.U_PASS }}</label>
                        <input type="text" name="U_PASS" class="form-control form-control-sm" id="U_PASS" v-model="form.U_PASS">
                        <error-message :error="errors.U_PASS"></error-message>
                    </div>
                </div>
                <div class="row mb-2">  


                    <!-- Q_MAILADDR -->
                    <div class="col-3">
                        <label for="Q_MAILADDR" class="form-label">{{ dg.SalTInsite.Q_MAILADDR }}</label>
                        <input type="text" name="Q_MAILADDR" class="form-control form-control-sm" id="Q_MAILADDR" v-model="form.Q_MAILADDR">
                        <error-message :error="errors.Q_MAILADDR"></error-message>
                    </div>
                    
                    <!-- S_MAILADDR -->
                    <div class="col-3">
                        <label for="S_MAILADDR" class="form-label">{{ dg.SalTInsite.S_MAILADDR }}</label>
                        <input type="text" name="S_MAILADDR" class="form-control form-control-sm" id="S_MAILADDR" v-model="form.S_MAILADDR">
                        <error-message :error="errors.S_MAILADDR"></error-message>
                    </div>
                    
                    <!-- G_ANA_ID -->
                    <div class="col-1" style="width: 130px">
                        <label for="G_ANA_ID" class="form-label">{{ dg.SalTInsite.G_ANA_ID }}</label>
                        <input type="text" name="G_ANA_ID" class="form-control form-control-sm" id="G_ANA_ID" v-model="form.G_ANA_ID">
                        <error-message :error="errors.G_ANA_ID"></error-message>
                    </div>
                </div>
                <div class="mb-2 row">
                    <!-- ファイル -->
                    <file-upload
                        v-model="form"
                        v-model:filesModelValue="files"
                        :dg="dg"
                        :max-file-count=3
                        :slice-num=29
                        :document-root="documentRoot"
                    ></file-upload>
                </div>
                <div class="row mb-2">
                    <!-- 備考 -->
                    <div class="col-12">
                        <label for="NOTE" class="form-label">{{ dg.SalTInsite.NOTE }}</label>
                        <textarea name="NOTE" class="form-control form-control-sm" id="NOTE" v-model="form.NOTE" rows="22"></textarea>
                    </div>
                </div>
            </div>   
        </div>
        <div class="d-grid gap-2 d-md-flex justify-content-md-end">
            <!-- 削除ボタン -->
            <delete-button v-if="edit"
                :confirm-message="dg.deleteConfirmMessage"
                :dg="dg"
                :url="`/insite/${form.ID}`"
            >{{ dg.Delete }}</delete-button>
            <!-- 中止ボタン -->
            <cancel-button
                :confirm-flag="changed"
                :confirm-message="dg.cancelMessage"
                :url="`${documentRoot}/insite`"
            >{{ dg.cancel }}</cancel-button>
            <button type="button" class="btn btn-primary" @click="register">登録</button>
        </div>
        <modal-dialog ref="dialog"
            :dg = dg
            @update="update"
        ></modal-dialog>

    </form>
</template>

<script>
import CancelButton from '../buttons/CancelButton.vue';
import DeleteButton from '../buttons/DeleteButton.vue';
import InputKokykIdWithButton from '../inputs/InputKokykIdWithButton.vue';
import InputHankaku from '../inputs/InputHankaku.vue';
import ModalDialog from '../modals/ModalDialog.vue';
import FileUpload from '../inputs/FileUpload.vue';

export default {
    name: 'InsiteInput',
    components: {
        CancelButton,
        DeleteButton,
        FileUpload,
        InputKokykIdWithButton,
        InputHankaku,
        ModalDialog,
    },
    props: {
        data                : Object,
        dg                  : Object,
        documentRoot        : String,
        delFlagList         : Object,
        insiteStatusList    : Object,
        syzkList            : Object,
    },
    data() {
        return {
            changed: false,
            edit: false,
            errors: {},
            files: [],
            form: {},
        }
    },
    created() {
        this.form = this.data;
        this.edit = this.form.ID ? true : false;
    },
    mounted() {
        const unwatch = this.$watch('form', () => {
            this.changed = true;
            unwatch();
        },{ deep: true });
    },
    methods: {
        register() {
            this.edit ? this.$refs.dialog.open() : this.create();
        },
        setCustomerInfo(SalTInsite) {
            const keys = ['KOKYK_ID', 'CLNIC_NM'];

            for (const key of keys) {
                this.form[key] = SalTInsite[key];
            }
        },
        getFormData () {
            const formData = new FormData(this.$refs.form);

            formData.set('FILE1', '');
            formData.set('FILE2', '');
            formData.set('FILE3', '');
            for (let i = 0; i < this.files.length; i++) {
                if (this.files[i].file) {
                    formData.set('FILE' + (i + 1), this.files[i].file);
                } else {
                    formData.set('FILE' + (i + 1), this.files[i].name);
                }
            }

            return formData;
        },
        create() {
            axios.post(`/insite`, this.getFormData(), {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((res) => {
                location.href = res.data;
            })
            .catch((error) => {
                if (error.response.status === 422) {
                    this.errors = error.response.data.errors;
                } else if (error.response.status === 500) {
                    const status = error.response.status;
                    window.alert(this.dg.internal_error);
                }
            });
        },
        update() {
            axios.post(`/insite/${this.form.ID}`, this.getFormData(), {
                headers: {
                    "Content-Type": "multipart/form-data",
                    'X-HTTP-Method-Override' : 'PUT',
                },
            })
            .then((res) => {
                location.href = res.data;
            })
            .catch((error) => {
                if (error.response.status === 422) {
                    this.errors = error.response.data.errors;
                } else if (error.response.status === 500) {
                    const status = error.response.status;
                    window.alert(this.dg.internal_error);
                }
            });
        },
    },
    watch: {
    },
}
</script>