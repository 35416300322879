<template>       
    <div class="row mb-2">
        <!-- ID -->
        <input type="hidden" name="ID" :value="modelVal.ID">
        <!-- CPU -->
        <div class="col-2">
            <label for="CPUTYPE1" class="form-label form-label-sm">{{ dg.SalTPc.CPUTYPE1 }}</label>
            <select name="CPUTYPE1" class="form-select form-select-sm" id="CPUTYPE1" v-model="modelVal.CPUTYPE1">
                <option v-for="(value, key) in pcCpuList" :key="key" :value="key">{{ value }}</option>
            </select>
            <error-message :error="errors.CPUTYPE1"></error-message>
        </div>
        <!-- CPU2 -->
        <div class="col-1">
            <label for="CPUTYPE2" class="form-label">{{ dg.SalTPc.CPUTYPE2 }}</label>
            <input type="text" name="CPUTYPE2" class="form-control form-control-sm" id="CPUTYPE2" autocomplete="on" list="cputype2" v-model="modelVal.CPUTYPE2">
                <datalist id="cputype2" >
                <option v-for="list in datalist?.CPUTYPE2" :key="list">{{list}}</option>
            </datalist>
            <error-message :error="errors.CPUTYPE2"></error-message>
        </div>
        <!-- メモリ容量 -->
        <div class="col-1">
            <label for="MEMORY" class="form-label">{{ dg.SalTPc.MEMORY }}(GB)</label>
            <input-hankaku
                v-model="modelVal.MEMORY"
                inputType="text"
                inputName="MEMORY"
                inputId="MEMORY"
            ></input-hankaku>
            <error-message :error="errors.MEMORY"></error-message>
        </div>
        <!-- DISKタイプ -->
        <div class="col-1">
            <label for="DISK_TYPE" class="form-label">{{ dg.SalTPc.DISK_TYPE }}</label>
            <select name="DISK_TYPE" class="form-select form-select-sm" id="DISK_TYPE" aria-label="Default select example" v-model="modelVal.DISK_TYPE">
                <option v-for="(diskType, key) in pcDiskTypeList" :key="key" :value="key">{{ diskType }}</option>
            </select>
            <error-message :error="errors.DISK_TYPE"></error-message>
        </div>
        <!-- DISK容量 -->
        <div class="col-1">
            <label for="DISK" class="form-label">{{ dg.SalTPc.DISK }}(GB)</label>
            <input-hankaku
                v-model="modelVal.DISK"
                inputType="text"
                inputName="DISK"
                inputId="DISK"
            ></input-hankaku>
            <error-message :error="errors.DISK"></error-message>
        </div>
        <!-- OS -->
        <div class="col-2">
            <label for="OS" class="form-label form-label-sm">{{ dg.SalTPc.OS }}</label>
            <select name="OS" class="form-select form-select-sm" id="OS" v-model="modelVal.OS">
                <option v-for="(value, key) in pcOsList" :key="key" :value="key">{{ value }}</option>
            </select>
            <error-message :error="errors.OS"></error-message>
        </div>
        <!-- OFFICE -->
        <div class="col-2">
            <label for="OFFICE" class="form-label">{{ dg.SalTPc.OFFICE }}</label>
            <input type="text" name="OFFICE" class="form-control form-control-sm" id="OFFICE" autocomplete="on" list="office" v-model="modelVal.OFFICE">
            <datalist id="office" >
                <option v-for="list in datalist?.OFFICE" :key="list">{{list}}</option>
            </datalist>
            <error-message :error="errors.OFFICE"></error-message>
        </div>
        <!-- 保証期間 -->
        <div class="col-1">
            <label for="WARRANTY" class="form-label">{{ dg.SalTPc.WARRANTY }}(年)</label>
            <input-hankaku
                v-model="modelVal.WARRANTY"
                inputType="text"
                inputName="WARRANTY"
                inputId="WARRANTY"
            ></input-hankaku>
            <error-message :error="errors.WARRANTY"></error-message>
        </div>
    </div>
</template>

<script>
import InputHankaku from '../inputs/InputHankaku.vue';

export default {
    name: 'PcInputBody',
    emits: ['update:model-value'],
    components: {
        InputHankaku,
    },
    props: {
        modelValue: Object,
        dg: Object,
        edit: {
            type: Boolean,
            default: true,
            required: false
        },
        errors: Object,
        pcCpuList: Object,
        pcDiskTypeList: Object,
        pcPurchaseList: Object,
        pcOsList: Object,
    },
    data() {
        return {
            datalist: {},
        }
    },
    created() {
        // 初期値
        this.modelVal.PRODUCT_CODE = this.edit ? this.modelVal.PRODUCT_CODE : '8001'; // ノート
        this.modelVal.DISK_TYPE = this.edit ? this.modelVal.DISK_TYPE : 1; // SSD
        // 過去に入力した一覧をフェッチ
        this.setDataList('CPUTYPE2');
        this.setDataList('OFFICE');
    },
    methods: {
        setDataList(columnName) {
            axios.get(`/api/pc/${columnName}`)
                .then((res) => {
                    this.datalist[columnName] = res.data;
                });
        },
    },
    computed: {
        modelVal: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:model-value', value)
            }
        },
    },
}
</script>