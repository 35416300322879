<template>
    <div class="table-responsive" style="height: 700px; resize: both;" ref="scrollDiv" @scroll="handleTableScroll">
        <sortable-table2 ref="sortable"
            v-model="items"
            :default-sort-key="sort.key"
            :default-sort-asc="sort.asc"
            :session-name="sessionName"
        >
            <template #default="{setIcon, sort}">
                <table class="table table-hover table-sm text-nowrap" style="table-layout: fixed">
                    <thead class="sticky-top bg-body">
                        <tr>
                            <th class="text-center" style="width: 30px">{{ dg.SequentialNumber }}</th>
                            <th class="text-center" style="width: 35px; cursor:pointer" @click="sort('KOKYK_ID')">{{ dg.KOKYK_ID }}<font-awesome-icon :icon="setIcon('KOKYK_ID')" /></th>
                            <th class="text-center" style="width: 80px">{{ dg.CLNIC_NM }}</th>
                            <th class="text-center" style="width: 30px">{{ dg.SalTPc.KIND }}</th>
                            <th class="text-center" style="width: 85px">{{ dg.SalTPc.PRODUCT_CODE }}</th>
                            <th class="text-center" style="width: 25px">{{ dg.SalTPc.CPUTYPE1 }}</th>
                            <th class="text-center" style="width: 25px">{{ dg.SalTPc.CPUTYPE2 }}</th>
                            <th class="text-center" style="width: 30px">{{ dg.SalTPc.MEMORY }}(GB)</th>
                            <th class="text-center" style="width: 40px">{{ dg.SalTPc.DISK }}(GB)</th>
                            <th class="text-center" style="width: 60px">{{ dg.SalTPc.OS }}</th>
                            <th class="text-center" style="width: 30px">{{ dg.SalTPc.WARRANTY }}(年)</th>
                            <th class="text-center" style="width: 30px; cursor:pointer" @click="sort('STOCK_DATE')">{{ dg.SalTStock.STOCK_DATE }}<font-awesome-icon :icon="setIcon('STOCK_DATE')" /></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in items" :key="index">
                            <td><a href="#" @click.prevent.stop="edit(item.ID)">{{ (index + 1).toString().padStart( 4, '0') }}</a></td>
                            <td>{{ item.KOKYK_ID }}</td>
                            <td>{{ strLimit(item.CLNIC_NM, 20) }}</td>
                            <td>{{ pcPurchaseList[item.KIND] }}</td>
                            <td>{{ item.PRODUCT_CODE + '/' + item.PRODUCT_NAME }}</td>
                            <td>{{ pcCpuList[item.CPUTYPE1] }}</td>
                            <td>{{ item.CPUTYPE2 }}</td>
                            <td class="text-end">{{ item.MEMORY }}</td>
                            <td class="text-end">{{ item.DISK }}</td>
                            <td>{{ pcOsList[item.OS] }}</td>
                            <td class="text-end">{{ item.WARRANTY }}</td>
                            <td>{{ item.STOCK_DATE ? new Date(item.STOCK_DATE).toLocaleDateString() : '' }}</td>
                        </tr>
                    </tbody>
                </table>
            </template>
        </sortable-table2>
    </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import Mixin from '../mixins/mixin'
import SortableTable2 from '../tables/SortableTable2.vue';

export default {
    name: 'PcIndexTable',
    mixins: [Mixin],
    emits: ['handleTableScroll', 'update:model-value'],
    components: {
        FontAwesomeIcon,
        SortableTable2,
    },
    props: {
        dg: Object,
        modelValue: Array,
        documentRoot: String,
        pcCpuList: Object,
        pcOsList: Object,
        pcPurchaseList: Object,
        sort: Object,
        sessionName: String,
    },
    methods: {
        init(){
            this.$refs.sortable.initialSort();
        },
        edit(primaryKey) {
            location.href = `${this.documentRoot}/pc/${primaryKey}/edit`;
        },
        scroll(height) {
            this.$refs.scrollDiv.scrollTop = height;
        },
        handleTableScroll(e) {
            this.$emit('handleTableScroll', e);
        },
    },
    computed: {
        items: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:model-value', value)
            }
        },
    },
}
</script>