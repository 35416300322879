<template>
    <div v-if="active">
        <div class="modal" style="display: block;" >
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">確認</h5>
                    </div>
                    <div class="modal-body">
                        {{ message }}
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary" data-bs-dismiss="modal" @click="yes()">はい</button>
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="no()">いいえ</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-backdrop show" ></div>
    </div>
</template>

<script>
export default {
    name: 'YesNoModal',
    props: {
        message: String
    },
    data() {
        return {
            active: false,
        }
    },
    methods: {
        open() {
            this.active = true;
        },
        close() {
            this.active = false;
        },
        yes() {
            this.$emit('yes');
            this.close();
        },
        no() {
            this.$emit('no');
            this.close();
        }
    },
}
</script>
