<template>
    <product-select-slot
        v-model="modelVal"
        :dg="dg"
        :index="index"
        :product-obj-list="productObjList"
        :clear-column-list="[
            'PRODUCT_NAME',
            'UPRICE',
            'CNT',
            'PRICE',
            'NOTE1',
            'NOTE2',
        ]"
    >
        <template #default="{ setCnt, handleProductCodeChange }">
            <tr>
                <td>{{ modelVal.RNO }}</td>
                <td>
                    <select
                        name="PRODUCT_CODE"
                        class="form-select form-select-sm"
                        v-model="modelVal.PRODUCT_CODE"
                        @change="handleProductCodeChange"
                    >
                        <option :value="null"></option>
                        <option
                            v-for="(productObj, index) in productObjList"
                            :key="index"
                            :value="productObj.PRODUCT_CODE"
                        >
                            {{
                                productObj.PRODUCT_CODE +
                                "/" +
                                productObj.PRODUCT_NAME
                            }}
                        </option>
                    </select>
                    <error-message
                        :error="getErrors('PRODUCT_CODE')"
                    ></error-message>
                </td>
                <td>
                    <input
                        type="text"
                        name="PRODUCT_NAME"
                        class="form-control form-control-sm"
                        v-model="modelVal.PRODUCT_NAME"
                    />
                    <error-message
                        :error="getErrors('PRODUCT_NAME')"
                    ></error-message>
                </td>
                <td>
                    <input-hankaku
                        v-model="modelVal.UPRICE"
                        inputType="text"
                        inputName="UPRICE"
                        @blur="setCnt"
                    ></input-hankaku>
                    <error-message :error="getErrors('UPRICE')"></error-message>
                </td>
                <td>
                    <input-hankaku
                        v-model="modelVal.CNT"
                        inputType="text"
                        inputName="CNT"
                    ></input-hankaku>
                    <error-message :error="getErrors('CNT')"></error-message>
                </td>
                <td>
                    <input
                        readonly
                        type="text"
                        name="PRICE"
                        class="form-control form-control-sm text-end"
                        :value="modelVal.PRICE.toLocaleString()"
                    />
                </td>
                <td>
                    <input
                        type="text"
                        name="NOTE1"
                        class="form-control form-control-sm"
                        v-model="modelVal.NOTE1"
                    />
                    <error-message :error="getErrors('NOTE1')"></error-message>
                </td>
                <td>
                    <input
                        type="text"
                        name="NOTE2"
                        class="form-control form-control-sm"
                        v-model="modelVal.NOTE2"
                    />
                    <error-message :error="getErrors('NOTE2')"></error-message>
                </td>
                <td v-if="edit">
                    <button
                        v-if="modelVal.PRODUCT_CODE && product?.STOCK_UMU"
                        type="button"
                        class="btn btn-sm btn-primary"
                        @click="stockForDetail"
                    >
                        仕入
                    </button>
                </td>
            </tr>
        </template>
    </product-select-slot>
</template>

<script>
import InputHankaku from "../inputs/InputHankaku.vue";
import ProductSelectSlot from "../slots/ProductSelectSlot.vue";

export default {
    name: "SalesDetailInput",
    emits: ["update:model-value", "stock-for-detail"],
    components: {
        InputHankaku,
        ProductSelectSlot,
    },
    props: {
        modelValue: Object,
        dg: Object,
        index: Number,
        productObjList: Array,
        edit: Boolean,
    },
    mounted() {
        this.modelVal.RNO = this.index + 1;
    },
    methods: {
        getErrors(name) {
            return (
                this.$parent.errors[`salTSalesDtls.${this.index}.${name}`] ?? []
            );
        },
        stockForDetail() {
            this.$emit("stock-for-detail", this.modelVal.RNO);
        },
    },
    computed: {
        modelVal: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit("update:model-value", value);
            },
        },
        product() {
            return this.productObjList.find(
                (product) => product.PRODUCT_CODE === this.modelVal.PRODUCT_CODE
            );
        },
    },
};
</script>
