<template>
    <button type="button" class="btn btn-primary btn-sm" @click="clicked"><slot></slot></button>
</template>
<script>

export default {
    name: 'LinkButton',
    props: {
        url: String
    },
    methods: {
        clicked() {
            location.href = this.url;
        },
    },
}
</script>
