export default {
    methods: {
        strLimit(text, limit) {
            if (!text) return;
            let len = 0;
            
            return [...text]?.reduce((acc, cur) => {
                len += (cur.match(/[\x01-\x7E\uFF61-\uFF9F]+/) !== null) ? 1 : 2;
                return (len <= limit) ? acc + cur : acc
            }, '');
        },
        setSession(sessionName, newData) {
            const sessionData = this.getSession(sessionName);
            const newSessionData = { ...sessionData, ...newData}; // newDataを追加(上書き)
            const json = JSON.stringify(newSessionData);

            sessionStorage.setItem(sessionName, json);
        },
        getSession(name) {
            let data = sessionStorage.getItem(name);
            return JSON.parse(data) ?? {};
        },
        errorHandling(error) {
            if (error?.response?.status === 422) {
                this.errors = error.response.data.errors;
            } else if (error?.response?.status === 500) {
                const status = error.response.status;
                window.alert('システムエラー');
            }
        },
        _isChanged(isChanged) {
            if (isChanged) {
                window.alert('変更されています。先に保存してください。');

                return true;
            } else {
                return false;
            }
        },
        _isAtLeastOneSelected(array) {
            if (array.length === 0) {
                window.alert('1つ以上選択してください');

                return false;
            } else {
                return true;
            }
        },
    }
}