<template>
    <div
        class="table-responsive"
        style="height: 700px; resize: both"
        ref="scrollDiv"
        @scroll="handleTableScroll"
    >
        <table
            class="table table-hover table-sm text-nowrap"
            style="table-layout: fixed"
        >
            <thead class="sticky-top bg-body">
                <tr>
                    <th style="width: 56px">{{ dg.SequentialNumber }}</th>
                    <th style="width: 80px">{{ dg.KOKYK_ID }}</th>
                    <th style="width: 50px">{{ dg.MKokykLic.DEL_FLG }}</th>
                    <th style="width: 200px">{{ dg.MKokykLic.CLNIC_NM }}</th>
                    <th style="width: 140px">{{ dg.MKokykLic.INCHO_NM }}</th>
                    <th style="width: 120px">{{ dg.MsSyzk.SYZK_NM }}</th>
                    <th style="width: 130px">{{ dg.MKokykLic.TEL }}</th>
                    <th style="width: 90px">{{ dg.MKokykLic.ZIP_CD }}</th>
                    <th style="width: 380px">{{ dg.MKokykLic.ADR }}</th>
                    <th style="width: 190px">
                        {{ dg.MKokykAccount.ACCOUNT_NM }}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, index) in items" :key="index">
                    <td>{{ index + 1 }}</td>
                    <td>
                        <a href="#" @click.prevent.stop="edit(item.KOKYK_ID)">{{
                            item.KOKYK_ID
                        }}</a>
                    </td>
                    <td>{{ delFlagList[item.DEL_FLG] }}</td>
                    <td
                        class="text-truncate"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        :title="item.CLNIC_NM"
                    >
                        {{ item.CLNIC_NM }}
                    </td>
                    <td
                        class="text-truncate"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        :title="item.INCHO_NM"
                    >
                        {{ item.INCHO_NM }}
                    </td>
                    <td
                        class="text-truncate"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        :title="item.msSyzk.SYZK_NM"
                    >
                        {{ item.msSyzk.SYZK_NM }}
                    </td>
                    <td class="text-truncate" :title="item.TEL">
                        {{ item.TEL }}
                    </td>
                    <td class="text-truncate" :title="item.ZIP_CD">
                        {{ item.ZIP_CD }}
                    </td>
                    <td
                        class="text-truncate"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        :title="item.ADR"
                    >
                        {{ item.ADR }}
                    </td>
                    <td
                        class="text-truncate"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        :title="item.accountNm"
                    >
                        {{ item.accountNm }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import Mixin from "../mixins/mixin";
import { Tooltip } from "bootstrap";

export default {
    name: "CustomerIndexTable",
    mixins: [Mixin],
    emits: ["handleTableScroll", "update:model-value"],
    props: {
        modelValue: Array,
        dg: Object,
        documentRoot: String,
        delFlagList: Object,
    },
    mounted() {
        new Tooltip(this.$el, {
            selector: '[data-bs-toggle="tooltip"]',
        });
    },
    computed: {
        items: {
            get() {
                const items = (this.modelValue || []).map((item) => {
                    return {
                        ...item,
                        accountNm: item.mKokykAccounts?.[0]?.ACCOUNT_NM,
                    };
                });
                return items;
            },
            set(value) {
                this.$emit("update:model-value", value);
            },
        },
    },
    methods: {
        edit(primaryKey) {
            location.href = `${this.documentRoot}/customer/${primaryKey}/edit`;
        },
        scroll(height) {
            this.$refs.scrollDiv.scrollTop = height;
        },
        handleTableScroll(e) {
            this.$emit("handleTableScroll", e);
        },
    },
};
</script>
