<template>
    <slot name="default"
        :setIcon="setIcon"
        :sort="sort"
    ></slot>
</template>
<script>
import Mixin from '../mixins/mixin'

export default {
    name: 'SortableTable2',
    emits: ['update:model-value'],
    mixins: [Mixin],
    props: {
        modelValue: Array,
        defaultSortKey: String,
        defaultSortAsc: Boolean,
        sessionName: {
            type: String,
            default: null,
            required: false,
        },
        initialize: {
            type: Boolean,
            default: false,
            required: false,
        },
    },
    data() {
        return {
            sortKey: '',
            sortAsc: true,
        }
    },
    created() {
        this.init();
    },
    methods: {
        init() {
            this.sortKey = this.defaultSortKey;
            this.sortAsc = this.defaultSortAsc;
            if( this.initialize ) {
                this.sortAsc = !this.sortAsc;
                this.sort(this.defaultSortKey);
            }
        },
        initialSort() {
            this.sortAsc = !this.sortAsc;
            this.sort(this.sortKey);
        },
        setIcon(key) {
            return (key === this.sortKey) 
                ?   (this.sortAsc) ? 'fa-solid fa-sort-up' :'fa-solid fa-sort-down'
                :   'fa-solid fa-sort';
        },
        sort(sortKey) {
            this.sortAsc = (this.sortKey === sortKey)
                ?   !this.sortAsc
                :   true;

            this.sortKey = sortKey;

            if(this.sessionName) {
                const data = { key: this.sortKey, asc: this.sortAsc };
                this.setSession(this.sessionName, { sort: data });
            }

            this.items?.sort(this.compare);
        },
        compare( a, b ) {
            const set = this.sortAsc ? 1 : -1;
            const key = this.sortKey.split('.');

            const A = key.reduce((acc, cur) => {
                    return acc?.[cur]
                }, a);

            const B = key.reduce((acc, cur) => {
                    return acc?.[cur]
                }, b);

            if (A < B) return -1 * set;
            if (A > B) return 1 * set;
            if (A === B) {
                if (a.UPD_DATE < b.UPD_DATE) return -1 * set;
                if (a.UPD_DATE > b.UPD_DATE) return 1 * set;
            }

            if (typeof A === 'undefined' && typeof B !== 'undefined') return 1 * set;
            if (typeof B === 'undefined' && typeof A !== 'undefined') return -1 * set;

            if (A === null && B !== null) return 1 * set;
            if (B === null && A !== null) return -1 * set;

            return 0; 
        }
    },
    computed: {
        items: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:model-value', value)
            }
        },
    },
}
</script>