<template>
    <form ref="form">
        <div class="card mb-2">
            <div class="card-body row mb-2">
                <div class="row mb-2">
                    <!-- 顧客番号 -->
                    <div class="col-1">
                        <label
                            for="KOKYK_ID"
                            class="form-label form-label-sm"
                            >{{ dg.KOKYK_ID }}</label
                        >
                        <input
                            readonly
                            name="KOKYK_ID"
                            type="text"
                            class="form-control form-control-sm border-0"
                            id="KOKYK_ID"
                            :value="form.KOKYK_ID"
                        />
                        <error-message :error="errors.KOKYK_ID"></error-message>
                    </div>
                    <!-- 顧客名 -->
                    <div class="col-3">
                        <label for="CLNIC_NM" class="form-label">{{
                            dg.MKokykLic.CLNIC_NM
                        }}</label>
                        <input
                            readonly
                            type="text"
                            name="CLNIC_NM"
                            class="form-control form-control-sm border-0"
                            id="CLNIC_NM"
                            :value="form.CLNIC_NM"
                        />
                        <error-message :error="errors.CLNIC_NM"></error-message>
                    </div>
                </div>
                <div class="row mb-2">
                    <!-- 備考 -->
                    <div class="col-12">
                        <label for="NOTE" class="form-label">{{
                            dg.SalTKokykLic.NOTE
                        }}</label>
                        <textarea
                            rows="4"
                            name="NOTE"
                            :placeholder="dg.SalTKokykLic.NOTE"
                            class="form-control form-control-sm"
                            id="NOTE"
                            :value="form.NOTE"
                            maxlength="4000"
                        />
                    </div>
                </div>
                <div class="row">
                    <!-- 添付ファイル -->
                    <div class="col-12">
                        <!-- ファイル -->
                        <file-upload
                            v-model="form"
                            v-model:filesModelValue="files"
                            :dg="dg"
                            :max-file-count="5"
                            :slice-num="31"
                            :document-root="documentRoot"
                        ></file-upload>
                    </div>
                </div>
            </div>
        </div>

        <div class="card mb-2">
            <div class="card-body">
                <h6 class="card-title">
                    {{ dg.MKokykAccount.Account }}
                </h6>
                <customer-account-input
                    ref="detail"
                    v-for="(detail, index) in form.mKokykAccounts"
                    :key="detail.ENO"
                    v-model="form.mKokykAccounts[index]"
                    :dg="dg"
                    :documentRoot="documentRoot"
                    :index="index"
                    :bankAccountType="bankAccountType"
                    :deleteAccount="
                        function () {
                            deleteAccount(index);
                        }
                    "
                ></customer-account-input>
                <div class="my-2">
                    <!-- 追加ボタン -->
                    <button
                        type="button"
                        class="btn btn-primary"
                        @click="addAccount"
                    >
                        {{ dg.add }}
                    </button>
                </div>
            </div>
        </div>

        <div class="d-grid gap-2 d-md-flex justify-content-md-end">
            <!-- 中止ボタン -->
            <cancel-button
                :confirm-flag="changed"
                :confirm-message="dg.cancelMessage"
                :url="`${documentRoot}/customer`"
                >{{ dg.cancel }}</cancel-button
            >
            <!-- 登録ボタン -->
            <button type="button" class="btn btn-primary" @click="register">
                {{ dg.register }}
            </button>
        </div>
    </form>

    <modal-dialog ref="dialog" :dg="dg" @update="update"></modal-dialog>
</template>

<script>
import CancelButton from "../buttons/CancelButton.vue";
import FileUpload from "../inputs/FileUpload.vue";
import InputHankaku from "../inputs/InputHankaku.vue";
import ToggleSwitch from "../inputs/ToggleSwitch.vue";
import ModalDialog from "../modals/ModalDialog.vue";
import CustomerAccountInput from "./CustomerAccountInput.vue";

export default {
    name: "CustomerInput",
    components: {
        CancelButton,
        InputHankaku,
        FileUpload,
        ModalDialog,
        ToggleSwitch,
        CustomerAccountInput,
    },
    props: {
        bankAccountType: Object,
        dg: Object,
        data: Object,
        documentRoot: String,
    },
    data() {
        return {
            changed: false,
            errors: [],
            form: {},
            files: [],
            isPost: false,
        };
    },
    created() {
        this.form = this.data;
    },
    mounted() {
        if ((this.form?.mKokykAccounts ?? []).length === 0) {
            this.form.mKokykAccounts = [this.getEmptyAccount()];
        }
        const unwatch = this.$watch(
            "form",
            () => {
                this.changed = true;
                unwatch();
            },
            { deep: true }
        );
    },
    methods: {
        register() {
            this.$refs.dialog.open();
        },
        getFormData() {
            const formData = new FormData(this.$refs.form);
            formData.set("FILE1", "");
            formData.set("FILE2", "");
            formData.set("FILE3", "");
            formData.set("FILE4", "");
            formData.set("FILE5", "");
            for (let i = 0; i < this.files.length; i++) {
                if (this.files[i].file) {
                    formData.set("FILE" + (i + 1), this.files[i].file);
                } else {
                    formData.set("FILE" + (i + 1), this.files[i].name);
                }
            }

            return formData;
        },
        update() {
            this.updateAxios()
                .then((res) => {
                    location.href = res.data;
                })
                .catch((error) => {
                    if (error.response.status === 422) {
                        this.errors = error.response.data.errors;
                    } else if (error.response.status === 500) {
                        const status = error.response.status;
                        window.alert(this.dg.internal_error);
                    }
                });
        },
        updateAxios() {
            return axios.post(
                `/customer/${this.data.KOKYK_ID}`,
                this.getFormData(),
                {
                    headers: {
                        "X-HTTP-Method-Override": "PUT",
                    },
                }
            );
        },
        getEmptyAccount(no = 1) {
            return {
                ENO: no,
                KOKYK_ID: this.form.KOKYK_ID,
                BANK_CODE: "",
                BANK_NM: "",
                BRANCH_CODE: "",
                BRANCH_NM: "",
                ACCOUNT_TYPE: 0,
                ACCOUNT_NO: "",
                ACCOUNT_HOLDER: "",
                DEL_FLG: "0",
            };
        },
        addAccount() {
            let maxNo = 0;
            this.form.mKokykAccounts.forEach((account) => {
                if (account.ENO > maxNo) {
                    maxNo = account.ENO;
                }
            });
            this.form.mKokykAccounts.push(this.getEmptyAccount(maxNo + 1));
        },
        deleteAccount(index) {
            this.form.mKokykAccounts.splice(index, 1);
            if (this.form.mKokykAccounts.length === 0) {
                setTimeout(() => {
                    this.addAccount();
                }, 500);
            }
        },
    },
};
</script>
