<template>
    <form ref="form" @submit.prevent="getItems()" class="mb-2">
        <div class="row">
            <div class="col-1">
                <div class="row mb-2">
                    <div class="d-grid gap-2 d-md-flex justify-content-md-start">
                        <link-button
                            :url='`${documentRoot}/pc/create`'
                        >{{ dg.pc.create }}</link-button>
                    </div>
                </div>
            </div>
            <div class="col-11">
                <div class="card">
                    <div class="card-body">
                        <div class="card-text row">
                             <!-- 顧客番号と検索ボタン -->
                             <input-kokyk-id-with-button
                                v-model="form.KOKYK_ID"
                                @set-customer="setCustomerInfo"
                                :dg="dg"
                                :del-flag="delFlag"
                                :errors="errors"
                                :syzk-list="syzkList"
                            ></input-kokyk-id-with-button>
                            <!-- 顧客名称 -->
                            <div class="col-2">
                                <label for="CLNIC_NM" class="form-label">{{ dg.CLNIC_NM }}</label>
                                <input type="text" class="form-control form-control-sm" v-model="form.CLNIC_NM">
                                <error-message :error="errors.CLNIC_NM"></error-message>
                            </div>
                            <!-- 商品コード -->
                            <div class="col-2">
                                <label for="PRODUCT_CODE" class="form-label">{{ dg.SalTPc.PRODUCT_CODE }}</label>
                                <select class="form-select form-select-sm" id="PRODUCT_CODE" v-model="form.PRODUCT_CODE" aria-label=".form-select-sm example">
                                    <option value="">{{ dg.pleaseSelect }}</option>
                                    <option v-for="(PRODUCT_NAME, PRODUCT_CODE) in pcDeviceList" :key="PRODUCT_CODE" :value="PRODUCT_CODE">{{ PRODUCT_CODE + '/' + PRODUCT_NAME }}</option>
                                </select>
                                <error-message :error="errors.PRODUCT_CODE"></error-message>
                            </div>
                            <!-- CPUTYPE1-->
                            <div class="col-2">
                                <label for="CPUTYPE1" class="form-label">{{ dg.SalTPc.CPUTYPE1 }}</label>
                                <select class="form-select form-select-sm" id="CPUTYPE1" v-model="form.CPUTYPE1" aria-label=".form-select-sm example">
                                    <option value="">{{ dg.pleaseSelect }}</option>
                                    <option v-for="(value, key) in pcCpuList" :key="key" :value="key">{{ value }}</option>
                                </select>
                                <error-message :error="errors.CPUTYPE1"></error-message>
                            </div>
                            <!-- OS-->
                            <div class="col-2">
                                <label for="OS" class="form-label">{{ dg.SalTPc.OS }}</label>
                                <select class="form-select form-select-sm" id="OS" v-model="form.OS" aria-label=".form-select-sm example">
                                    <option value="">{{ dg.pleaseSelect }}</option>
                                    <option v-for="(value, key) in pcOsList" :key="key" :value="key">{{ value }}</option>
                                </select>
                                <error-message :error="errors.OS"></error-message>
                            </div>
                            <div class="col-2 d-flex gap-1 align-items-start" style="margin-top: 23px">
                                <button type="button" class="btn btn-secondary btn-sm" @click="reset()">{{ dg.Reset }}</button>
                                <button type="submit" class="btn btn-primary btn-sm">{{ dg.Search }}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>

    <pc-index-table ref="table"
        v-model="items"
        :dg="dg"
        :pc-cpu-list="pcCpuList"
        :pc-device-list="pcDeviceList"
        :pc-os-list="pcOsList"
        :pc-purchase-list="pcPurchaseList"
        :document-root="documentRoot"
        :session-name="sessionName"
        :sort="sort"
        @handleTableScroll="handleTableScroll"
    ></pc-index-table>

    <div class="mt-2">
        <csv-button
            :button-name="dg.csv"
            file-name='PC一覧.csv'
            :ids="ids"
            url="/pc/csv"
        ></csv-button>
    </div>

</template>

<script>
import CsvButton from '../CsvButton.vue';
import Mixin from '../mixins/mixin'
import InputKokykIdWithButton from '../inputs/InputKokykIdWithButton.vue';
import LinkButton from '../buttons/LinkButton.vue';
import PcIndexTable from './PcIndexTable.vue';

export default {
    name: 'PcIndex',
    mixins: [Mixin],
    components: {
        CsvButton,
        InputKokykIdWithButton,
        LinkButton,
        PcIndexTable,
    },
    props: {
        delFlag: Object,
        dg: Object,
        documentRoot: String,
        pcCpuList: Object,
        pcDeviceList: Object,
        pcPurchaseList: Object,
        pcOsList: Object,
        syzkList: Object,
    },
    data() {
        return {
            form: {},
            errors: {},
            items: [],
            scroll: 0,
            sort: {},
            sessionName: 'pcIndex',
        }
    },
    async created() {       
        // プロパティ初期化
        this.init();
        // セッションの操作
        this.sessionHandler();
        // 一覧の取得
        await this.getItems();
        // 指定値までスクロール
        this.$refs.table.scroll(this.scroll);
    },
    methods: {
        init() {
            // 検索条件
            this.form = {
                KOKYK_ID    : '',
                CLNIC_NM    : '',
                PRODUCT_CODE: '',
                CPUTYPE1    : '',
                OS          : '',
            }
            // スクロール量
            this.scroll = 0;
            // ソート
            this.sort = {
                key: 'KOKYK_ID',
                asc: true,
            }
        },
        getItems() {
            this.errors = {};
            return axios.get(`/pc/search`, { params: this.form })
                .then(res => {
                    this.items = res.data.data;
                    // 検索条件をセッション保存
                    this.setSession(this.sessionName, { form: this.form } );
                })
                .then(() => {
                    this.$refs.table.init();
                })
                .catch((error) => {
                    if (error.response.status === 422) {
                        this.errors = error.response.data.errors;
                    } else if (error.response.status === 500) {
                        const status = error.response.status;
                        window.alert(this.dg.internal_error);
                    }
                });
        },
        reset() {
            this.init();
            this.getItems();
        },
        setCustomerInfo(mKokykLic) {
            const keys = ['KOKYK_ID', 'CLNIC_NM'];

            for (const key of keys) {
                this.form[key] = mKokykLic[key];
            }
        },
        sessionHandler() {
            const sessionData = this.getSession(this.sessionName);
            const sessionDataKeys = ['form', 'scroll', 'sort'];
            
            // セッションのデータキーを順に確認し、
            sessionDataKeys.forEach(key => {
                // キーが存在する場合、セッションから代入
                if (key in sessionData) {
                    this[key] = sessionData[key]
                // キーが存在しない場合、セッションに値を保存
                } else {
                    this.setSession(this.sessionName, { [key]: this[key] })
                }
            });
        },
        handleTableScroll (e) {
            this.setSession(this.sessionName, { scroll: e.target.scrollTop } );
        },
    },    
    computed: {
        ids() {
            return this.items.map(item => item.ID);
        },
    }
}
</script>